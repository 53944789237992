// React
import React from "react";
// MUI
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import ChangeCircleTwoToneIcon from '@mui/icons-material/ChangeCircleTwoTone';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import SlideshowTwoToneIcon from '@mui/icons-material/SlideshowTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
// Interfaces
import { StoreInterface } from "livecrew-interfaces";
// Contexts
import { useAdminContext } from "../../../contexts/admin";
// CSS
import "./index.css";
import appConfig from '../appConfig';

export default function StoresList(
  {stores}:
  {stores: StoreInterface[]}
) {
  const adminContext = useAdminContext();

	return (<div className="lc-itemslist">
    <div className="lc-itemslist-header" style={{backgroundColor: appConfig.color}}>
      <div className="lc-itemrow">
        <div className="lc-itemcell">
          <p>Nom</p>
        </div>
        <div className="lc-itemcell">
          <p>Catalogue</p>
        </div>
        <div className="lc-itemcell">
          <p>Questionnaire</p>
        </div>
        <div className="lc-itemcell">
          <p>Config</p>
        </div>
        <div className="lc-itemcell">
          <p>Sondage</p>
        </div>
        <div className="lc-itemcell">
          <p>Config</p>
        </div>
        <div className="lc-itemcell" style={{flex: "0.10 1 60px", color: "var(--dark)"}}>
          <SlideshowTwoToneIcon />
        </div>
        <div className="lc-itemcell" style={{flex: "0.10 1 60px", color: "var(--dark)"}}>
          <DeleteTwoToneIcon />
        </div>
      </div>
    </div>
    <div className="lc-itemslist-container">
      {stores.length > 0 ? stores.filter((s) => !s.CSID.includes("[lc]")).concat(stores.filter((s) => s.CSID.includes("[lc]"))).map((store) => <div
        className="lc-itemrow"
        style={{backgroundColor: adminContext.needsUpdate("stores", store.CSID) ? appConfig.colorDark + "44" : undefined}}
        key={store.CSID+"-row"}
      >
        <div className="lc-itemcell">
          <input
            type="text"
            id={store.CSID+"-name"}
            value={store.name}
            disabled={adminContext.isLoading}
            onChange={(e) => adminContext.set({state: "stores", id: store.CSID, data: {...store, name: e.currentTarget.value}})}
            style={{width: "100%", margin: 0}}
          />
        </div>
        <div className="lc-itemcell">
          <select
            id={store.CSID+"-catalog"}
            placeholder=" "
            value={store.PID || ""}
            disabled={adminContext.isLoading}
            onChange={(e) => adminContext.set({state: "stores", id: store.CSID, data: {...store, PID: e.currentTarget.value}})}
            style={{width: "100%", margin: 0}}
          >
            {adminContext.getCatalogs().map((catalog) => <option
              value={catalog.PID}
            >{catalog.name}</option>)}
            <option
              value=""
            >aucun</option>
          </select>
        </div>
        <div className="lc-itemcell">
          <select
            id={store.CSID+"-questTemplate"}
            placeholder=" "
            value={store.questTID || ""}
            disabled={adminContext.isLoading}
            onChange={(e) => adminContext.set({state: "stores", id: store.CSID, data: {...store, questTID: e.currentTarget.value}})}
            style={{width: "100%", margin: 0}}
          >
            {adminContext.getQuestionnaires().map((questionnaire) => <option
              value={questionnaire.TID}
            >{questionnaire.name}</option>)}
            <option
              value=""
            >aucun</option>
          </select>
        </div>
        <div className="lc-itemcell">
          <select
            id={store.CSID+"-questConfig"}
            placeholder=" "
            value={store.questTCID || ""}
            disabled={adminContext.isLoading}
            onChange={(e) => adminContext.set({state: "stores", id: store.CSID, data: {...store, questTCID: e.currentTarget.value}})}
            style={{width: "100%", margin: 0}}
          >
            {adminContext.getQuestionnairesConfigs().map((qc) => <option
              value={qc.TCID}
            >{qc.name}</option>)}
            <option
              value=""
            >aucun</option>
          </select>
        </div>
        <div className="lc-itemcell">
          <select
            id={store.CSID+"-surveyTemplate"}
            placeholder=" "
            value={store.surveyTID || ""}
            disabled={adminContext.isLoading}
            onChange={(e) => adminContext.set({state: "stores", id: store.CSID, data: {...store, surveyTID: e.currentTarget.value}})}
            style={{width: "100%", margin: 0}}
          >
            {adminContext.getSurveys().map((survey) => <option
              value={survey.TID}
            >{survey.name}</option>)}
            <option
              value=""
            >aucun</option>
          </select>
        </div>
        <div className="lc-itemcell">
          <select
            id={store.CSID+"-surveyConfig"}
            placeholder=" "
            value={store.surveyTCID || ""}
            disabled={adminContext.isLoading}
            onChange={(e) => adminContext.set({state: "stores", id: store.CSID, data: {...store, surveyTCID: e.currentTarget.value}})}
            style={{width: "100%", margin: 0}}
          >
          {adminContext.getSurveysConfigs().map((sc) => <option
            value={sc.TCID}
          >{sc.name}</option>)}
            <option
              value=""
            >aucun</option>
          </select>
        </div>
        <div className="lc-itemcell" style={{flex: "0.10 1 60px"}}>
          <input
            type="checkbox"
            id={store.CSID+"-dashboard"}
            style={{height: "22px", width: "22px", margin: 0}}
            checked={store.demo}
            onChange={() => adminContext.set({
              state: "stores", id: store.CSID,
              data: {...store, demo: !store.demo}
            })}
            disabled={adminContext.isLoading}
          />
        </div>
        <div className="lc-itemcell" style={{flex: "0.10 1 60px"}}>
          <button
            className="lc-buttonicon"
            style={{color: "red"}}
            onClick={() => console.log("Delete : " + store.CSID)}
            disabled={adminContext.isLoading || true}
          ><ClearTwoToneIcon /></button>
        </div>
      </div>) : <div className="lc-itemrow">
        <div className="lc-itemcell" style={{flex: "1 1 100%", maxWidth: "unset"}}>
          <p>Nothing in there</p>
        </div>
      </div>}
    </div>
    <div className="lc-itemslist-footer">
      <div style={{flex: "1 0 33%", display: "flex", justifyContent: "left", alignItems: "start"}}><button
        className="lc-buttonicon"
        style={{color: adminContext.needsUpdate("stores") ? "var(--dark)" : "green"}}
        onClick={() => adminContext.cancel("stores")}
        disabled={!adminContext.needsUpdate("stores") || adminContext.isLoading}
      >{adminContext.needsUpdate("stores")
        ? <><ChangeCircleTwoToneIcon /><p style={{padding: 2}}>{"Annuler les modifications"}</p></>
        : <><CheckCircleTwoToneIcon /><small style={{padding: 2}}>{"Données à jour"}</small></>
      }</button></div>
      <div style={{flex: "1 0 33%", display: "flex", justifyContent: "center", alignItems: "start"}}><button
        className="lc-buttonicon"
        style={{color: appConfig.colorDark}}
        onClick={() => adminContext.set({state: "stores", id: "", data: {
          CSID: "[lc]" + Date.now(),
          name: "",
          address: "",
          zip: "",
          city: "",
          demo: false,
          PID: "",
          questTID: "",
          questTCID: "",
          surveyTID: "",
          surveyTCID: "",
        }})}
        disabled={adminContext.getStores().filter((s) => s.name === "").length > 0 || adminContext.isLoading}
      >
        <AddCircleTwoToneIcon />
        <p style={{padding: 2}}>{"Nouvelle boutique"}</p>
      </button></div>
      <div style={{flex: "1 0 33%", display: "flex", justifyContent: "right", alignItems: "start"}}><button
        className="lc-buttonicon"
        style={{color: adminContext.needsUpdate("stores") ? "var(--dark)" : "green"}}
        onClick={() => adminContext.update("stores", "", (errorCode) => console.log(errorCode))}
        disabled={!adminContext.needsUpdate("stores") || adminContext.isLoading}
      >{adminContext.needsUpdate("stores")
        ? <><p style={{padding: 2}}>{"Enregistrer les modifications"}</p><SaveAltRoundedIcon /></>
        : <><small style={{padding: 2}}>{"Données à jour"}</small><CheckCircleTwoToneIcon /></>
      }</button></div>
    </div>
  </div>)
}