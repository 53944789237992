// React
import React, { useState } from "react";
// Contexts
import { useDeviceContext } from "../../contexts/device";

export default function NavButton(
  {isLoading, isOpen, onClick, text, desc, icon, isDisabled, isMirrored}:
  {isLoading: boolean, isOpen: boolean, onClick: () => void, text: string, desc: string, icon: JSX.Element, isDisabled?: boolean, isMirrored?: boolean}
) {
  const deviceContext = useDeviceContext();
  const [displayDesc, setDisplayDesc] = useState(false);

	return (
    <button className={"lc-navbar-button" + (isMirrored ? " left" : " right")}
      tabIndex={isMirrored ? 2 : 3}
      style={{
        justifyContent: isMirrored ? "start" : "end",
        // background: "linear-gradient(" + (isMirrored ? "" : "-") + "90deg, var(--light) 0%, #00000000 100%)"
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
      onMouseOver={() => setDisplayDesc(true)}
      onMouseLeave={() => setDisplayDesc(false)}
      onFocus={() => setDisplayDesc(true)}
      onBlur={() => setDisplayDesc(false)}
      disabled={isDisabled}
    >
      <div className="lc-navbar-button-container">
        {isMirrored && <div className="lc-navbar-button-icon"
          style={{transform: isOpen ? "rotate(-360deg)" : "rotate(0deg)"}}
        >
          {icon}
        </div>}
        {!deviceContext.isMobile() && (text || desc) && <div className="lc-navbar-button-text"
          style={{transform: isOpen && isLoading ? "translate(0px, -50px)" : "translate(0px, 0px)"}}
        >
          {displayDesc ? desc : text}
        </div>}
        {!isMirrored && <div className="lc-navbar-button-icon"
          style={{transform: isOpen ? "rotate(360deg)" : "rotate(0deg)"}}
        >
          {icon}
        </div>}
      </div>
    </button>
	)
}