// React
import React from "react";
// CSS
import "./index.css";

export default function Widget(
  {children, title, flex, isFocused, isDisabled, onClick, minHeight, maxHeight, minWidth, maxWidth, width, opacity, display}:
  {children?: JSX.Element, title?: string, flex?: string, isFocused?: boolean, isDisabled?: boolean, onClick? : () => void, minHeight?: string, maxHeight?: string, minWidth?: string, maxWidth?: string, width?: string, opacity?: number, display?: string}
) {

  const widgetStyle = {
    backgroundColor: isFocused ? "var(--lightEE)" : undefined,
    border: isFocused ? "1px solid var(--interaction)" : undefined,
    justifyContent: title && children ? "space-between" : "center",
    flex: flex,
    minHeight: minHeight,
    maxHeight: maxHeight,
    minWidth: minWidth,
    maxWidth: maxWidth,
    width: width,
    opacity: opacity,
    display: display,
  };

  const widgetContent = <>
    {title && <h5 className="lc-widget-title">
      {title}
    </h5>}
    {children && <div className="lc-widget-container" style={{padding: title ? "8px 2px" : "2px 2px"}}>
      {children}
    </div>}
  </>;

	return (<>
    {onClick ? <button className="lc-widget hoverable" style={widgetStyle}
      onClick={onClick}
      disabled={isDisabled}
    >
      {widgetContent}
    </button> : <div className="lc-widget" style={widgetStyle}
    >
      {widgetContent}
    </div>}
  </>)
}