// React
import React, { useEffect, useState } from "react";
// MUI
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
// Interfaces
import { QAInterface } from "livecrew-interfaces";
// Contexts
import { useStoreContext } from "../../../contexts/store";
import { useCompanyContext } from "../../../contexts/company";
import SingleSelect from "../../../components/SingleSelect";

export default function ProductSelect(
  {key, answers, setAnswer, full}:
  {key: string, answers: QAInterface["answers"], setAnswer: (answer: [string, string]) => void, full?: boolean}
) {
  const companyContext = useCompanyContext();
  const storeContext = useStoreContext();
  const [PCID, setPCID] = useState("");
  const [PBID, setPBID] = useState("");

  useEffect(() => {
    const oldPCID = answers.find((a) => a.label === "PCID")?.value as string || "";
    const oldPBID = answers.find((a) => a.label === "PBID")?.value as string || "";
    if (PCID && PBID && (PCID !== oldPCID || PBID !== oldPBID)) {
      setAnswer([PCID, PBID]);
    }
  }, [PCID, PBID]);

  useEffect(() => {
    const oldPCID = answers.find((a) => a.label === "PCID")?.value as string || "";
    const oldPBID = answers.find((a) => a.label === "PBID")?.value as string || "";
    setPCID(oldPCID);
    setPBID(oldPBID);
  }, [answers]);

	return (<>
    <SingleSelect
      id="category"
      placeholder="Type de produit"
      options={!full
        ? storeContext.getStoreList("PCID", PBID && PBID !== "[lc]other" ? {filtersPBID: [PBID]} : undefined).concat({label: "Autre", value: "[lc]other"})
        : companyContext.getCategories().concat({label: "Autre", value: "[lc]other"})
      }
      selected={PCID}
      setSelected={(s) => setPCID(s.value)}
    />
    <SingleSelect
      id="brand"
      placeholder="Marque"
      options={!full
        ? storeContext.getStoreList("PBID", PCID && PCID !== "[lc]other" ? {filtersPCID: [PCID]} : undefined).concat({label: "Autre", value: "[lc]other"})
        : companyContext.getBrands().concat({label: "Autre", value: "[lc]other"})
      }
      selected={PBID}
      setSelected={(s) => setPBID(s.value)}
    />
    {/* <div className="lc-productselect-container">
      {storeContext.getStoreList("PCID", PBID && PBID !== "[lc]other" ? {filtersPBID: [PBID]} : undefined).map((c) => <input
        key={key+"/"+c.value}
        className="lc-questionnaire-answer"
        type="button"
        value={c.label}
        onClick={() => setPCID(c.value)}
        disabled={c.value === PCID}
      />)}
      <input
        key={key+"/[lc]otherC"}
        className="lc-questionnaire-answer"
        style={{fontStyle: "italic"}}
        type="button"
        value={"Autre"}
        onClick={() => setPCID("[lc]other")}
        disabled={"[lc]other" === PCID}
      />
    </div>
    <div style={{boxSizing: "border-box", width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
      <div style={{display: "flex"}}>
        <ArrowDropUpRoundedIcon />
        <small>Catégorie</small>
        <ArrowDropUpRoundedIcon />
      </div>
      <button
        className="lc-button"
        style={{display: "flex", justifyContent: "space-evenly", alignItems: "center", padding: "4px"}}
        onClick={() => {setPCID(""); setPBID("")}}
        disabled={!PCID && !PBID}
      ><ClearRoundedIcon /></button>
      <div style={{display: "flex"}}>
        <ArrowDropDownRoundedIcon />
      <small>Marque</small>
        <ArrowDropDownRoundedIcon />
      </div>
    </div>
    <div className="lc-productselect-container">
      {storeContext.getStoreList("PBID", PCID && PCID !== "[lc]other" ? {filtersPCID: [PCID]} : undefined).map((b) => <input
        key={key+"/"+b.value}
        className="lc-questionnaire-answer"
        type="button"
        value={b.label}
        onClick={() => setPBID(b.value)}
        disabled={b.value === PBID}
      />)}
      <input
        key={key+"/[lc]otherB"}
        className="lc-questionnaire-answer"
        style={{fontStyle: "italic"}}
        type="button"
        value={"Autre"}
        onClick={() => setPBID("[lc]other")}
        disabled={"[lc]other" === PBID}
      />
    </div> */}
  </>)
}