// React
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// MUI
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import TipsAndUpdatesTwoToneIcon from '@mui/icons-material/TipsAndUpdatesTwoTone';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
// Interfaces
import { AppInterface, ViewInterface } from "../../interfaces";
// Contexts
import { useDeviceContext } from '../../contexts/device';
import { useI18nContext } from '../../contexts/i18n';
import { useAppsContext } from '../../contexts/apps';

export default function NavLeft(
  {headerHeight, headerPadding, isLoading, isOpen, setIsOpen, currentApp, selectedApp, setSelectedApp, setSelectedView}:
  {headerHeight: number, headerPadding: number, isLoading: boolean, isOpen: boolean, setIsOpen: (value: boolean) => void, currentApp: AppInterface, selectedApp: AppInterface, setSelectedApp: (app: AppInterface) => void, setSelectedView: (view: ViewInterface) => void}
) {
  const device = useDeviceContext();
  const i18n = useI18nContext();
  const appsContext = useAppsContext();

  const [tips, setTips] = useState("");
  const apps = appsContext.getApps();

  useEffect(() => setTips((oldV) => isOpen ? i18n.getRandomTips() : oldV), [isOpen, i18n]);

	return (
    <div className="lc-navpanel lc-navpanel-left"
      style={{
        height: device.innerHeight - (headerHeight + headerPadding * 2) * 2 - headerHeight,
        top: headerHeight + headerPadding * 2, left: isOpen ? "0%" : "-55%", padding: headerPadding,
        // backgroundColor: isOpen ? "var(--light)" : "#FFFFFF88",
      }}
    >
      {!isLoading ? <>
        <div className="lc-navpanel-linkslist">
          {apps.map((app) => <Link
            tabIndex={isOpen ? undefined : -1}
            key={"link-"+app.AID}
            className={"lc-navpanel-link"
              + (app.AID === selectedApp.AID ? " lc-navpanel-link-selected" : "")
              + (app.AID === currentApp.AID ? " lc-navpanel-link-current" : "")}
            to={app.url}
            onClick={() => setIsOpen(false)}
            onMouseOver={() => {setSelectedApp(app); setSelectedView({} as ViewInterface)}}
            onTouchStart={() => {setSelectedApp(app); setSelectedView({} as ViewInterface)}}
          >
            {i18n.t(app.AID + ".name", {defaultValue: "No App name !"})}
            <DoubleArrowIcon className="lc-navpanel-arrow" style={{right: -4, color: app.AID === selectedApp.AID ? "var(--interactive)" : "var(--light)"}} />
          </Link>)}
        </div>
        <small className="lc-navpanel-desc">
          <TipsAndUpdatesTwoToneIcon className="lc-navpanel-descicon" />
          {tips || "You'll always find a random tips here"}
        </small>
      </> : <div style={{height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <AutorenewRoundedIcon />
      </div>}
      {/* {isLoading
        ? <h2></h2>
        : <div className="lc-navpanel-linkslist">
          {apps.map((app) => <Link
            tabIndex={isOpen ? undefined : -1}
            key={"link-"+app.AID}
            className={"lc-navpanel-link"
              + (app.AID === selectedApp.AID ? " lc-navpanel-link-selected" : "")
              + (app.AID === currentApp.AID ? " lc-navpanel-link-current" : "")}
            to={app.url}
            onClick={() => setIsOpen(false)}
            onMouseOver={() => {setSelectedApp(app); setSelectedView({} as ViewInterface)}}
            onTouchStart={() => {setSelectedApp(app); setSelectedView({} as ViewInterface)}}
          >
            {i18n.t(app.AID + ".name", {defaultValue: "No App name !"})}
            <DoubleArrowIcon className="lc-navpanel-arrow" style={{right: -4, color: app.AID === selectedApp.AID ? "var(--interactive)" : "var(--light)"}} />
          </Link>)}
        </div>
      }
      <small className="lc-navpanel-desc">
        <TipsAndUpdatesTwoToneIcon className="lc-navpanel-descicon" />
        {tips || "You'll always find a random tips here"}
      </small> */}
    </div>
	)
}