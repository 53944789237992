// React
import React, { useState } from 'react';
// MUI
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// Interfaces
import { ViewInterface } from '../../../interfaces';
// Contexts
import { useI18nContext } from '../../../contexts/i18n';
import { useUserContext } from '../../../contexts/user';
// AppConfig
import appConfig from '../appConfig';
// Components
import Widget from '../../../components/Widget';

const Logout = () => {
	const i18n = useI18nContext();
	const userContext = useUserContext();
	const [message, setMessage] = useState("");
	const [isDisabled, setIsDisabled] = useState(false);

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsDisabled(true);
		userContext.logout((message) => {
			setMessage(message);
			setIsDisabled(false);
		})
	};

	return (<>
		<Widget
			isFocused={true}
			width="100%"
		>
			<form
				onSubmit={handleSubmit}
			>
				<label style={{textAlign: "center"}}>{i18n.t(appConfig.AID + ".views." + LogoutView.AVID + ".warning")}</label>
				<input
					type="submit"
					value={i18n.t(appConfig.AID + ".views." + LogoutView.AVID + ".name")}
					disabled={isDisabled}
				/>
				<small style={{textAlign: "center"}}>{message}</small>
			</form>
		</Widget>
	</>)
}

const LogoutView : ViewInterface = {
  AVID: "logout",
  component: (props?: {dual?: boolean}) => <Logout />,
  url: "/logout",
  icon: <AccountCircleIcon />,
}

export default LogoutView;