// MUI
// import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';

const appConfig = {
  AID: "dashboard",
  version: "0.1.0",
  url: "/dashboard",
  color: "#F4F0F0",
  // color: "var(--light)",
  colorDark: "#804040",
  icon: <AssessmentTwoToneIcon />,
};

export default appConfig;