import { onDocUpdate, getColRef, getDocs, getDocRef, getDoc, setDoc, doc, query, newBatch } from "../../firestore";
import { ProductCatalogInterface, catalogsColPath, catalogDocPath, CID, PID } from "livecrew-interfaces";

const listenAll = (CID: CID, onChange: (stores: ProductCatalogInterface[]) => void) => onDocUpdate(
  query(getColRef(catalogsColPath(CID))),
  (querySnap) => {
    const catalogs = [] as ProductCatalogInterface[];
    querySnap.forEach((doc) => catalogs.push(doc.data() as ProductCatalogInterface));
    onChange(catalogs);
  },
  (error) => onChange([]),
);

const read = (CID: CID, PID: PID, onSuccess: (catalog: ProductCatalogInterface) => void, onError: (errorCode: string) => void) => getDoc(getDocRef(catalogDocPath(CID, PID)))
  .then((docSnap) => {
    if (docSnap.exists()) {
      onSuccess({...docSnap.data(), PID: docSnap.id} as ProductCatalogInterface);
    } else {
      onError("db.doc.01");
    }
  })
  .catch((error) => {
    console.log(error);
    onError(error.code);
  });

const update = (CID: CID, catalogs: ProductCatalogInterface[], onSuccess: () => void, onError: (errorCode: string) => void) => {
  if (catalogs.length > 0) {
    const batch = newBatch();
    catalogs.forEach((catalog) => {
      const storeDoc = catalog.PID.includes("[lc]") ? doc(getColRef(catalogsColPath(CID))) : getDocRef(catalogDocPath(CID, catalog.PID));
      batch.set(storeDoc, {...catalog, PID: storeDoc.id});
    });
    batch.commit()
      .then(() => onSuccess())
      .catch((error) => onError(error.code));
  } else {
    onError("No catalog to update");
  }
};
  
const catalogs = {
  listenAll,
  read,
  update,
};

export default catalogs;