/** Dev */
// const firebaseConfig = {
//   apiKey: "AIzaSyAFDqMAj6dHkR6pyzAqtEKEdQh_feoghXM",
//   authDomain: "lc-devtest.firebaseapp.com",
//   projectId: "lc-devtest",
//   storageBucket: "lc-devtest.appspot.com",
//   messagingSenderId: "1085831438473",
//   appId: "1:1085831438473:web:ca142b86db5b4d0ff86658",
//   measurementId: "G-30M700TR3F"
// };

/** Prod */
const firebaseConfig = {
  apiKey: "AIzaSyAPhQR8RltcubO4r2Ls9P9v0wdae_-sjv8",
  authDomain: "livecrew-52bff.firebaseapp.com",
  projectId: "livecrew-52bff",
  storageBucket: "livecrew-52bff.appspot.com",
  messagingSenderId: "1069138741230",
  appId: "1:1069138741230:web:65098387babe32af7bf0ff",
  measurementId: "G-9LR18EJYZ9"
};

/** PoC 2 : Samsonite */
// const firebaseConfig = {
//   apiKey: "AIzaSyCnPjb4_kyo8I2GSgEoBcEYJl6ScMCjNZ8",
//   authDomain: "lc-poc2.firebaseapp.com",
//   projectId: "lc-poc2",
//   storageBucket: "lc-poc2.appspot.com",
//   messagingSenderId: "292807213037",
//   appId: "1:292807213037:web:631ab56dbf621129d971e4",
//   measurementId: "G-4DTB021P2H"
// };

export default firebaseConfig;