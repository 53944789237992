// React
import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// MUI
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
// Interfaces
import { TDID, DataValueType, QaViewInterface, QaDataInterface, QAInterface, functions, ProductCatalogInterface } from "livecrew-interfaces";
import { ViewInterface } from "../../../interfaces";
// Contexts
import { useI18nContext } from "../../../contexts/i18n";
import { useCompanyContext } from "../../../contexts/company";
import { useStoreContext } from "../../../contexts/store";
import { useCrewappContext } from "../../../contexts/crewapp";
// import { useUserContext } from "../../../contexts/user";
// AppConfig
import appConfig from "../appConfig";
// Components
import Widget from "../../../components/Widget";
import ProductSelect from "../components/ProductSelect";
import FreeText from "../components/FreeText";
import SingleSelect from "../../../components/SingleSelect";

const Questionnaire = () => {
	const i18n = useI18nContext();
	const companyContext = useCompanyContext();
	const storeContext = useStoreContext();
	const crewappContext = useCrewappContext();
	const navigate = useNavigate();
  const topRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLSpanElement>(null);

	// const getAgeLabel = (a: number | string, b: number | string) => {
	// 	if (a === b) {
	// 		return a + " " + i18n.t("shared.dateDict.years");
	// 	} else if (a === 0 || a === "0") {
	// 		return b + " " + i18n.t("shared.dateDict.years") + " " + i18n.t("shared.and") + " " + i18n.t("shared.less");
	// 	} else if (b === 0 || b === "0") {
	// 		return a + " " + i18n.t("shared.dateDict.years") + " " + i18n.t("shared.and") + " " + i18n.t("shared.more");
	// 	} else {
	// 		return a + " - " + b + " " + i18n.t("shared.dateDict.years");
	// 	}
	// };

	const getAgeLabel = (a: number | string, b: number | string) => {
		if (a === b) {
			return a + " ";
		} else if (a === 0 || a === "0") {
			return b + " " + i18n.t("shared.and") + " " + i18n.t("shared.less");
		} else if (b === 0 || b === "0") {
			return a + " " + i18n.t("shared.and") + " " + i18n.t("shared.more");
		} else {
			return a + " - " + b;
		}
	};

	const mapQA = (currentTDID: TDID, data: QaDataInterface[], views: QaViewInterface[]) => {
		const qa = [] as QAInterface[];
		const currentCD = views.find((v) => v.TDID === currentTDID);
		if (currentCD) {
			currentCD.order.forEach((TDKID) => {
				const key = currentCD.keys[TDKID];
				const question = currentCD.questions.find((q) => q.TDKID === TDKID);
				const result = data.find((d) => d.TDID === currentCD.TDID && d.TDKID === TDKID);
				const show = result && result.value !== null ? true : false;
				const PCID = result && Array.isArray(result.value) && result.value.length === 2 ? result.value[0] : "";
				const PBID = result && Array.isArray(result.value) && result.value.length === 2 ? result.value[1] : "";
				if (question) {
					switch (key.type) {
						case "boolean":
							qa.push({TDID: currentCD.TDID, TDKID: TDKID, show: show, type: key.type, question: functions.getLabel(views, i18n.getLocale(), currentCD.TDID, TDKID), answers: [{label: "Oui", value: "[lc]true", disabled: functions.isAnswered(data, currentCD.TDID, TDKID, "[lc]true")},{label: "Non", value: "[lc]false", disabled: functions.isAnswered(data, currentCD.TDID, TDKID, "[lc]false")}]});
							break;
						case "number":
							qa.push({TDID: currentCD.TDID, TDKID: TDKID, show: show, type: key.type, question: functions.getLabel(views, i18n.getLocale(), currentCD.TDID, TDKID), answers: [{label: "Aucun", value: 0, disabled: functions.isAnswered(data, currentCD.TDID, TDKID, 0)},{label: "Un", value: 1, disabled: functions.isAnswered(data, currentCD.TDID, TDKID, 1)},{label: "Deux", value: 2, disabled: functions.isAnswered(data, currentCD.TDID, TDKID, 2)},{label: "Plusieurs", value: -2, disabled: functions.isAnswered(data, currentCD.TDID, TDKID, -2)}]});
							break;
						case "custom":
							const answers = [] as {label: string, value: DataValueType, disabled?: boolean}[];
							key.order.map((TDKOID) => {
								const answer = question.answers?.find((a) => a.value === TDKOID);
								(answer) && answers.push({label: functions.getLabel(views, i18n.getLocale(), currentCD.TDID, TDKID, TDKOID), value: answer.value, disabled: functions.isAnswered(data, currentCD.TDID, TDKID, answer.value)});
							});
							qa.push({TDID: currentCD.TDID, TDKID: TDKID, show: show, type: key.type, question: functions.getLabel(views, i18n.getLocale(), currentCD.TDID, TDKID), answers: answers});
							break;
						case "range":
							qa.push({TDID: currentCD.TDID, TDKID: TDKID, show: show, type: key.type, question: functions.getLabel(views, i18n.getLocale(), currentCD.TDID, TDKID), answers: question.answers?.map((a) => {return {label: Array.isArray(a.value) ? getAgeLabel(a.value[0], a.value[1]) : a.value.toString(), value: Array.isArray(a.value) ? a.value[0]+"-"+a.value[1] : a.value, disabled: functions.isAnswered(data, currentCD.TDID, TDKID, Array.isArray(a.value) ? a.value[0]+"-"+a.value[1] : a.value)}}) || []});
							break;
						case "category":
							qa.push({TDID: currentCD.TDID, TDKID: TDKID, show: show, type: key.type, question: functions.getLabel(views, i18n.getLocale(), currentCD.TDID, TDKID), answers: storeContext.getStoreList("PCID").map((c) => {return {...c, disabled: functions.isAnswered(data, currentCD.TDID, TDKID, c.value)}})});
							break;
						case "category-full":
							qa.push({TDID: currentCD.TDID, TDKID: TDKID, show: show, type: key.type, question: functions.getLabel(views, i18n.getLocale(), currentCD.TDID, TDKID), answers: companyContext.getCategories().map((c) => {return {...c, disabled: functions.isAnswered(data, currentCD.TDID, TDKID, c.value)}})});
							break;
						case "brand":
							qa.push({TDID: currentCD.TDID, TDKID: TDKID, show: show, type: key.type, question: functions.getLabel(views, i18n.getLocale(), currentCD.TDID, TDKID), answers: storeContext.getStoreList("PBID").map((b) => {return {...b, disabled: functions.isAnswered(data, currentCD.TDID, TDKID, b.value)}})});
							break;
						case "brand-full":
							qa.push({TDID: currentCD.TDID, TDKID: TDKID, show: show, type: key.type, question: functions.getLabel(views, i18n.getLocale(), currentCD.TDID, TDKID), answers: companyContext.getBrands().map((b) => {return {...b, disabled: functions.isAnswered(data, currentCD.TDID, TDKID, b.value)}})});
							break;
						case "product":
							// const PCID = result && Array.isArray(result.value) && result.value.length === 2 ? result.value[0] : "";
							// const PBID = result && Array.isArray(result.value) && result.value.length === 2 ? result.value[1] : "";
							qa.push({TDID: currentCD.TDID, TDKID: TDKID, show: show, type: key.type, question: functions.getLabel(views, i18n.getLocale(), currentCD.TDID, TDKID), answers: [{label: "PCID", value: PCID},{label: "PBID", value: PBID}]});
							break;
						case "free":
							qa.push({TDID: currentCD.TDID, TDKID: TDKID, show: show, type: key.type, question: functions.getLabel(views, i18n.getLocale(), currentCD.TDID, TDKID), answers: [{label: "freetext", value: result?.value as string || ""}]});
							break;
						case "catalog":
							qa.push({TDID: currentCD.TDID, TDKID: TDKID, show: show, type: key.type, question: functions.getLabel(views, i18n.getLocale(), currentCD.TDID, TDKID), answers: [{label: "PCID", value: PCID},{label: "PBID", value: PBID}]});
							// qa.push({TDID: currentCD.TDID, TDKID: TDKID, show: show, type: key.type, question: functions.getLabel(views, i18n.getLocale(), currentCD.TDID, TDKID), answers: [
							// 	{label: key.type+" 1", value: "1", disabled: functions.isAnswered(data, currentCD.TDID, TDKID, "1")},{label: key.type+" 2", value: "2", disabled: functions.isAnswered(data, currentCD.TDID, TDKID, "2")},{label: key.type+" 3", value: "3", disabled: functions.isAnswered(data, currentCD.TDID, TDKID, "3")},{label: key.type+" 4", value: "4", disabled: functions.isAnswered(crewappContext.questData, currentCD.TDID, TDKID, "4")},{label: key.type+" 5", value: "5", disabled: functions.isAnswered(crewappContext.questData, currentCD.TDID, TDKID, "5")},
							// 	{label: key.type+" 6", value: "6", disabled: functions.isAnswered(data, currentCD.TDID, TDKID, "6")},{label: key.type+" 7", value: "7", disabled: functions.isAnswered(data, currentCD.TDID, TDKID, "7")},{label: key.type+" 8", value: "8", disabled: functions.isAnswered(data, currentCD.TDID, TDKID, "8")},{label: key.type+" 9", value: "9", disabled: functions.isAnswered(crewappContext.questData, currentCD.TDID, TDKID, "9")},{label: key.type+" 10", value: "10", disabled: functions.isAnswered(crewappContext.questData, currentCD.TDID, TDKID, "10")}
							// ]});
							break;
						default:
							qa.push({TDID: currentCD.TDID, TDKID: TDKID, show: show, type: key.type, question: functions.getLabel(views, i18n.getLocale(), currentCD.TDID, TDKID), answers: []})
					};
					if (key.oth === true) {qa[qa.length-1].answers.push({label: "Autre", value: "[lc]other", disabled: functions.isAnswered(data, currentCD.TDID, TDKID, "[lc]other")})};
					if (key.idk === true) {qa[qa.length-1].answers.push({label: "Je ne sais pas", value: "[lc]idk", disabled: functions.isAnswered(data, currentCD.TDID, TDKID, "[lc]idk")})};
				}
			});
		}
		return qa;
	};

	useEffect(() => bottomRef?.current?.scrollIntoView({behavior: "smooth", block: "end"}),[crewappContext.questData]);
	
	return (<>
		<div className="lc-section-toolbar" style={{justifyContent: "space-between"}}>
			<h2 style={{padding: "0px 8px"}}>{storeContext.questConfig?.name || ""}</h2>
			<p style={{padding: "0px 8px"}}>{(crewappContext.questData.length || 0) + " / " + (crewappContext.questItems.length || 0)}</p>
			<div ref={topRef} style={{boxSizing: "border-box", width: "100%", display: "flex", flexWrap: "wrap", gap: 2, justifyContent: "space-evenly", alignItems: "stretch", padding: 8, textAlign: "center"}}>
				{crewappContext.questViews.map((view) => <Widget
					title={functions.getLabel(crewappContext.questViews, i18n.getLocale(), view.TDID)}
					onClick={() => (crewappContext.questData.find((d) => d.TDID === view.TDID) && crewappContext.currentQuest !== view.TDID)
						? crewappContext.switchView("quest", view.TDID)
						: {}}
					isDisabled={!crewappContext.questData.find((d) => d.TDID === view.TDID) || crewappContext.currentQuest === view.TDID || crewappContext.isLoading}
					isFocused={crewappContext.currentQuest === view.TDID}
					opacity={(crewappContext.questData.find((d) => d.TDID === view.TDID))
						? (crewappContext.currentQuest === view.TDID)
							? 1
							: 0.6
						: 0.2}
					flex="1 0 18%"
					minHeight="unset"
					minWidth="140px"
					maxWidth="300px"
				></Widget>)}
				{crewappContext.questViews.length > 0 && <Widget
					title={i18n.t("shared.validate", {case: "first"})}
					onClick={() => crewappContext.switchView("quest", "")}
					isDisabled={crewappContext.questData.length !== crewappContext.questItems.length || (crewappContext.questData.length === crewappContext.questItems.length && crewappContext.questData[crewappContext.questData.length-1].value === undefined) || crewappContext.isLoading}
					isFocused={crewappContext.currentQuest === ""}
					opacity={(crewappContext.questData.length === crewappContext.questItems.length)
						? (crewappContext.currentQuest === "")
							? 1
							: 0.6
						: 0.2}
					flex="1 0 18%"
					minHeight="unset"
					minWidth="140px"
					maxWidth="300px"
				></Widget>}
			</div>
		</div>
		{crewappContext.questData.length > 0 ? 
			<div className="lc-questionnaire-container">{crewappContext.currentQuest
				? mapQA(crewappContext.currentQuest, crewappContext.questData, crewappContext.questViews).map((qa) => <Widget
						key={qa.TDID+"/"+qa.TDKID}
						title={qa.question}
						isFocused={qa.TDID === crewappContext.questData[crewappContext.questData.length-1].TDID && qa.TDKID === crewappContext.questData[crewappContext.questData.length-1].TDKID}
						flex="1 1 50%"
						// flex={qa.type === "category" || qa.type === "brand" ? "2 1 100%" : "1 1 50%"}
						// flex={qa.type === "category" || qa.type === "category-full" || qa.type === "brand" || qa.type === "brand-full" || qa.type === "product" ? "2 1 100%" : "1 1 50%"}
						minHeight="240px"
						maxHeight="360px"
						// maxHeight={qa.type === "boolean" || qa.type === "number" || qa.type === "free" || qa.type === "custom" ? "360px" : "480px"}
						maxWidth="360px"
						// maxWidth={qa.type === "category" || qa.type === "category-full" || qa.type === "brand" || qa.type === "brand-full" || qa.type === "product" ? "732px" : "360px"}
						// maxWidth="360px"
						opacity={qa.show ? 1 : 0}
						display={qa.show ? "flex" : "none"}
					><>
						{qa.type === "product" &&	<ProductSelect
							key={qa.TDID+"/"+qa.TDKID}
							// products={[...storeContext.products]}
							// categories={companyContext.getCategories()}
							// brands={companyContext.getBrands()}
							answers={qa.answers}
							setAnswer={(a) => crewappContext.setAnswer("quest", qa.TDID, qa.TDKID, a)}
						/>}
						{qa.type === "catalog" && <ProductSelect
							key={qa.TDID+"/"+qa.TDKID}
							// products={[...storeContext.products]}
							// categories={companyContext.getCategories()}
							// brands={companyContext.getBrands()}
							answers={qa.answers}
							setAnswer={(a) => crewappContext.setAnswer("quest", qa.TDID, qa.TDKID, a)}
							full={true}
						/>}
						{(qa.type === "category-full" || qa.type === "brand-full" || qa.type === "category" || qa.type === "brand") && <>
							<SingleSelect
							key={qa.TDID+"/"+qa.TDKID}
							id={qa.TDID+"/"+qa.TDKID}
							placeholder=""
							// products={[...storeContext.products]}
							// categories={companyContext.getCategories()}
							// brands={companyContext.getBrands()}
							options={qa.answers.filter((a) => (a.value !== "[lc]idk" && a.value !== "[lc]other")) as {label: string, value: string}[]}
							selected={qa.answers.find((a) => a.disabled)?.value}
							setSelected={(s) => crewappContext.setAnswer("quest", qa.TDID, qa.TDKID, s.value)}
							/>
							{qa.answers.filter((a) => (a.value === "[lc]idk" || a.value === "[lc]other")).map((a) => <input
								key={qa.TDID+"/"+qa.TDKID+"/"+a.value}
								className="lc-questionnaire-answer"
								style={{fontStyle: a.value === "[lc]idk" || a.value === "[lc]other" ? "italic" : "unset"}}
								type="button"
								value={a.label}
								onClick={() => crewappContext.setAnswer("quest", qa.TDID, qa.TDKID, a.value)}
								disabled={!qa.show || a.disabled}
							/>)}
						</>}
						{qa.type === "free" &&	<FreeText
							key={qa.TDID+"/"+qa.TDKID}
							answers={qa.answers}
							setAnswer={(a) => crewappContext.setAnswer("quest", qa.TDID, qa.TDKID, a)}
						/>}
						{qa.type !== "free" && qa.type !== "product" && qa.type !== "catalog" && qa.type !== "category" && qa.type !== "brand" && qa.type !== "category-full" && qa.type !== "brand-full" && qa.answers.map((a) => <input
							key={qa.TDID+"/"+qa.TDKID+"/"+a.value}
							className="lc-questionnaire-answer"
							style={{fontStyle: a.value === "[lc]idk" || a.value === "[lc]other" ? "italic" : "unset"}}
							type="button"
							value={a.label}
							onClick={() => crewappContext.setAnswer("quest", qa.TDID, qa.TDKID, a.value)}
							disabled={!qa.show || a.disabled}
						/>)}
					</></Widget>)
				: <Widget
					key={"submit-feedback"}
					title={i18n.t(appConfig.AID + ".views." + QuestionnaireView.AVID + ".submitConfirm", {case: "first"})}
					isFocused={true}
					flex="1 1 100%"
					maxHeight="180px"
					maxWidth="360px"
				><input
					className="lc-questionnaire-answer"
					type="button"
					value={i18n.t("shared.submit", {case: "first"})}
					onClick={() => crewappContext.submitFeedback("quest", () => navigate(appConfig.url), () => {})}
					disabled={crewappContext.isLoading}
					// style={{minWidth: "160px", flex: "1 0 44%", padding: 4}}
				/></Widget>
			} </div>
			: <div className="lc-questionnaire-container">
				<Widget
					key={"no-feedback"}
					title={"Pas de questionnaire en cours"}
					isFocused={true}
					flex="1 1 100%"
					maxHeight="180px"
					maxWidth="360px"
				><input
					className="lc-questionnaire-answer"
					type="button"
					value={"Retour à la CrewApp"}
					onClick={() => navigate(appConfig.url)}
				/></Widget>
			</div>
		}
		<span id="bottom-questionnaire" ref={bottomRef} style={{height: 0, width: "100%"}} />
	</>)
}

const QuestionnaireView : ViewInterface = {
  AVID: "questionnaire",
  component: (props?: {dual?: boolean}) => <Questionnaire />,
  url: "/questionnaire",
  icon: <CommentRoundedIcon fontSize="large"/>,
}

export default QuestionnaireView;