// React
import React from 'react';
// MUI
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// Interfaces
import { ViewInterface } from '../../../interfaces';
// Contexts
import { useI18nContext } from '../../../contexts/i18n';
import { useCompanyContext } from '../../../contexts/company';
import { useAdminContext } from '../../../contexts/admin';
// import { useUserContext } from '../../../contexts/user';
// AppConfig
import appConfig from '../appConfig';
import CatalogsList from '../components/CatalogsList';
// Components
// import Widget from '../../../components/Widget';

const Catalogs = () => {
	const i18n = useI18nContext();
	const companyContext = useCompanyContext();
	const adminContext = useAdminContext();
	// const userContext = useUserContext();
	// const [name, setName] = useState("");
	// const [creaCode, setCreaCode] = useState("");
	// const [message, setMessage] = useState("");
	// const [isDisabled, setIsDisabled] = useState(false);

	// const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
	// 	e.preventDefault();
	// 	setIsDisabled(true);
	// 	userContext.Catalogs(name, creaCode, (errorCode) => {
	// 		setMessage(i18n.t(errorCode));
	// 		setIsDisabled(false);
	// 	})
	// };

	return (<>
    <section className="lc-section" style={{flexDirection: "column"}}>
			<div className="lc-section-toolbar">
				<h2 style={{width: "100%", textAlign: "center"}}>{i18n.t(appConfig.AID + ".views." + CatalogsView.AVID + ".name", {case: "first"})}</h2>
			</div>
			{/* <div style={{display: "flex", flexDirection: "column"}}>
				<div style={{width: "100%", display: "flex", alignItems: "center", color: "var(--dark)"}}>
					<SlideshowTwoToneIcon />
					<small style={{textAlign: "left", padding: 8, color: "var(--dark)"}}>
						{"Démo : les feedbacks soumis par les utilisateurs de cette boutique ne seront enregistrés afin de leur permettre de tester la CrewApp"}
					</small>
				</div>
			</div> */}
			<CatalogsList
				catalogs={adminContext.getCatalogs().sort((a, b) => a.name.localeCompare(b.name)) || []}
				categories={companyContext.getCategories()}
				brands={companyContext.getBrands()}
			/>
			{/* <StoresList
				stores={adminContext.getStores().sort((a, b) => a.name.localeCompare(b.name)) || []}
			/> */}
		</section>
	</>)
}

const CatalogsView : ViewInterface = {
  AVID: "catalogs",
  component: (props?: {dual?: boolean}) => <Catalogs />,
  url: "/catalogs",
  icon: <AccountCircleIcon />,
}

export default CatalogsView;