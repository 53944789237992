// React
import React, { useState } from 'react';
// Interfaces
import { AppInterface, ViewInterface } from "../../interfaces";
// CSS
import "./index.css";
// Components
import Footer from './Footer';
import NavLeft from './NavLeft';
import NavRight from './NavRight';

export default function Overlay(
  {headerHeight, headerPadding, isLoading, isOpen, setIsOpen, currentApp, currentView}:
  {headerHeight: number, headerPadding: number, isLoading: boolean, isOpen: boolean, setIsOpen: (value: boolean) => void, currentApp: AppInterface, currentView: ViewInterface}
) {

  const [selectedApp, setSelectedApp] = useState({} as AppInterface);
  const [selectedView, setSelectedView] = useState({} as ViewInterface);
  
	return (<>
    <NavLeft
      headerHeight={headerHeight}
      headerPadding={headerPadding}
      isLoading={isLoading}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      currentApp={currentApp}
      selectedApp={selectedApp}
      setSelectedApp={setSelectedApp}
      setSelectedView={setSelectedView}
    />
    <NavRight
      headerHeight={headerHeight}
      headerPadding={headerPadding}
      isLoading={isLoading}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      currentApp={currentApp}
      selectedApp={selectedApp}
      currentView={currentView}
      selectedView={selectedView}
      setSelectedView={setSelectedView}
    />
    <Footer
      headerHeight={headerHeight}
      headerPadding={headerPadding}
      isLoading={isLoading}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  </>)
}