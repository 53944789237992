// React
import React from "react";
// MUI
// import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
// import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
// import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
// import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
// import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
// import BallotRoundedIcon from '@mui/icons-material/BallotRounded';
// import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
// Interfaces
import { AuthInterface } from "livecrew-interfaces";
// Contexts
import { useAdminContext } from "../../../contexts/admin";
// CSS
import "./index.css";
import appConfig from '../appConfig';
import * as crewappAppConfig from "../../crewapp/appConfig";
import * as dashboardAppConfig from "../../dashboard/appConfig";

export default function UsersList(
  {users}:
  {users: AuthInterface[]}
) {
  const adminContext = useAdminContext();

	return (<div className="lc-itemslist">
    <div className="lc-itemslist-header" style={{backgroundColor: appConfig.color}}>
      <div className="lc-itemrow">
        <div className="lc-itemcell">
          <p>Email</p>
        </div>
        <div className="lc-itemcell">
          <p>Boutique</p>
        </div>
        <div className="lc-itemcell" style={{flex: "0.10 1 60px", color: appConfig.colorDark}}>
          {appConfig.icon}
        </div>
        <div className="lc-itemcell" style={{flex: "0.10 1 60px", color: crewappAppConfig.default.colorDark}}>
          {crewappAppConfig.default.icon}
        </div>
        <div className="lc-itemcell" style={{flex: "0.10 1 60px", color: dashboardAppConfig.default.colorDark}}>
          {dashboardAppConfig.default.icon}
        </div>
        <div className="lc-itemcell" style={{flex: "0.10 1 60px", color: "var(--dark)"}}>
          <LockOpenRoundedIcon />
        </div>
        <div className="lc-itemcell" style={{flex: "0.20 1 120px", color: "var(--dark)"}}>
          
        </div>
      </div>
    </div>
    <div className="lc-itemslist-container">
      {users.length > 0 ? users.map((user) => <div
        className="lc-itemrow"
        style={{backgroundColor: adminContext.needsUpdate("users", user.UID) ? appConfig.colorDark + "44" : undefined}}
        key={user.UID+"-row"}
      >
        <div className="lc-itemcell">
          <input
            type="text"
            id={user.UID+"-email"}
            value={user.email}
            disabled={true}
            style={{width: "100%", margin: 0}}
          />
        </div>
        <div className="lc-itemcell">
          <select
            id={user.UID+"-store"}
            placeholder=" "
            value={user.CSID || ""}
            disabled={adminContext.isLoading}
            onChange={(e) => adminContext.set({state: "users", id: user.UID, data: {...user, CSID: e.currentTarget.value}})}
            style={{width: "100%", margin: 0}}
          >
            {adminContext.getStores().map((store) => <option
              value={store.CSID}
            >{store.name}</option>)}
            <option
              value=""
            >aucune</option>
          </select>
        </div>
        <div className="lc-itemcell" style={{flex: "0.10 1 60px"}}>
          <input
            type="checkbox"
            id={user.UID+"-admin"}
            style={{height: "22px", width: "22px", margin: 0}}
            checked={user.permissions.includes("admin")}
            onChange={() => adminContext.set({
              state: "users", id: user.UID,
              data: {...user, permissions: user.permissions.includes("admin") ? user.permissions.filter((p) => p !== "admin") : user.permissions.concat("admin")}
            })}
            disabled={adminContext.isLoading}
          />
        </div>
        <div className="lc-itemcell" style={{flex: "0.10 1 60px"}}>
          <input
            type="checkbox"
            id={user.UID+"-crewapp"}
            style={{height: "22px", width: "22px", margin: 0}}
            checked={user.permissions.includes("crewapp")}
            onChange={() => adminContext.set({
              state: "users", id: user.UID,
              data: {...user, permissions: user.permissions.includes("crewapp") ? user.permissions.filter((p) => p !== "crewapp") : user.permissions.concat("crewapp")}
            })}
            disabled={adminContext.isLoading}
          />
        </div>
        <div className="lc-itemcell" style={{flex: "0.10 1 60px"}}>
          <input
            type="checkbox"
            id={user.UID+"-dashboard"}
            style={{height: "22px", width: "22px", margin: 0}}
            checked={user.permissions.includes("dashboard")}
            onChange={() => adminContext.set({
              state: "users", id: user.UID,
              data: {...user, permissions: user.permissions.includes("dashboard") ? user.permissions.filter((p) => p !== "dashboard") : user.permissions.concat("dashboard")}
            })}
            disabled={adminContext.isLoading}
          />
        </div>
        <div className="lc-itemcell" style={{flex: "0.10 1 60px"}}>
          <input
            type="checkbox"
            id={user.UID+"-data"}
            style={{height: "22px", width: "22px", margin: 0}}
            checked={user.permissions.includes("data")}
            onChange={() => adminContext.set({
              state: "users", id: user.UID,
              data: {...user, permissions: user.permissions.includes("data") ? user.permissions.filter((p) => p !== "data") : user.permissions.concat("data")}
            })}
            disabled={adminContext.isLoading}
          />
        </div>
        <div className="lc-itemcell" style={{flex: "0.20 1 120px"}}>
          <button
            className="lc-buttonicon"
            style={{
              // backgroundColor: "#00000000",
              color: adminContext.needsUpdate("users", user.UID) ? "var(--dark)" : "green"
            }}
            onClick={() => adminContext.update("users", user.UID, (errorCode) => console.log(errorCode))}
            disabled={!adminContext.needsUpdate("users", user.UID) || adminContext.isLoading}
          >{adminContext.needsUpdate("users", user.UID)
            ? <SaveAltRoundedIcon />
            : <CheckRoundedIcon />
          }</button>
        </div>
      </div>) : <div className="lc-itemrow">
        <div className="lc-itemcell" style={{flex: "1 1 100%", maxWidth: "unset"}}>
          <p>Nothing in there</p>
        </div>
      </div>}
    </div>
    <div className="lc-itemslist-footer" style={{backgroundColor: appConfig.color}}>
      {(users.length > 4) && <div className="lc-itemrow">
        <div className="lc-itemcell">
          <p>Email</p>
        </div>
        <div className="lc-itemcell">
          <p>Boutique</p>
        </div>
        <div className="lc-itemcell" style={{flex: "0.10 1 60px", color: appConfig.colorDark}}>
          {appConfig.icon}
        </div>
        <div className="lc-itemcell" style={{flex: "0.10 1 60px", color: crewappAppConfig.default.colorDark}}>
          {crewappAppConfig.default.icon}
        </div>
        <div className="lc-itemcell" style={{flex: "0.10 1 60px", color: dashboardAppConfig.default.colorDark}}>
          {dashboardAppConfig.default.icon}
        </div>
        <div className="lc-itemcell" style={{flex: "0.10 1 60px", color: "var(--dark)"}}>
          <LockOpenRoundedIcon />
        </div>
        <div className="lc-itemcell" style={{flex: "0.20 1 120px", color: "var(--dark)"}}>
          
        </div>
      </div>}
    </div>
  </div>)
}