// MUI
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';

const appConfig = {
  AID: "website",
  version: "0.1.0",
  url: "/website",
  color: "#00000000",
  colorDark: "#00000000",
  icon: <AccountBoxTwoToneIcon />,
};

export default appConfig;