// React
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// MUI
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CasinoTwoToneIcon from '@mui/icons-material/CasinoTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
// Interfaces
import { ViewInterface } from '../../../interfaces';
// Contexts
import { useI18nContext } from '../../../contexts/i18n';
// import { useUserContext } from '../../../contexts/user';
import { useCompanyContext } from '../../../contexts/company';
import { useAdminContext } from '../../../contexts/admin';
import { useDashboardContext } from '../../../contexts/dashboard';
// AppConfig
import appConfig from '../appConfig';
// Views
import UsersView from './Users';
import StoresView from './Stores';
// import ProductsView from './Products';
import CatalogsView from './Catalogs';
import BrandsView from './Brands';
import CategoriesView from './Categories';
// Components
import Widget from '../../../components/Widget';

const HomeAdmin = () => {
	const i18n = useI18nContext();
	// const userContext = useUserContext();
  const companyContext = useCompanyContext();
  const adminContext = useAdminContext();
  const dashboardContext = useDashboardContext();
  const navigate = useNavigate();
	const [message, setMessage] = useState("");
  const [file, setFile] = useState({} as File);
	// const [isDisabled, setIsDisabled] = useState(false);

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		// setIsDisabled(true);
		// userContext.HomeAdmin(name, creaCode, (errorCode) => {
		// 	setMessage(i18n.t(errorCode));
		// 	setIsDisabled(false);
		// })
	};

  // const onFileChange = (e) => {
  //   e.target && e.target.files && e.target.files[0] && e.target.files[0].name
  //     && setFile(e.target.files[0])
  // }

  // const onFileSubmit = (e) => {
  //   e.preventDefault();
  //   const reader = new FileReader();
  //   if (file && file.name) {
  //     reader.onload = (event) => { catalogs.importCatalog(JSON.parse(reader.result)) };
  //     reader.readAsText(file);
  //     setFile('');
  //   }
  //   e.target.reset();
  // };


	return (<>
		<Widget
      title={i18n.t("shared.companyDict.invitations", {case: "first"})}
			isFocused={true}
      flex="1 0 48%"
		><>
			<form
				onSubmit={handleSubmit}
			>
        <label htmlFor="IID">{i18n.t("shared.companyDict.IID", {case: "first"})}</label>
        <input
          id="IID"
          type="text"
          value={adminContext.configAdmin?.IID || ""}
          disabled={true}
        />
        <small style={{width: "100%", textAlign: "center"}}>
          {adminContext.configAdmin?.IID
            ? i18n.t(appConfig.AID + ".views." + HomeAdminView.AVID + ".descIID.share", {case: "first"})
            : i18n.t(appConfig.AID + ".views." + HomeAdminView.AVID + ".descIID.private", {case: "first"})}
        </small>
        <hr style={{width: "100%", margin: "20px 0px"}}/>
				<small style={{textAlign: "center"}}>{message}</small>
      </form>
      <button
        className="lc-buttonicon"
        style={{maxWidth: "220px"}}
        aria-label="Generate new invitation code"
        onClick={() => adminContext.updateInvitation("generate", (errorCode) => setMessage(errorCode))}
        disabled={adminContext.isLoading}
      >
        <CasinoTwoToneIcon />
        <small style={{padding: "0x 4px"}}>
          {!adminContext.configAdmin?.IID
            ? i18n.t(appConfig.AID + ".views." + HomeAdminView.AVID + ".descIID.01", {case: "first"})
            : i18n.t(appConfig.AID + ".views." + HomeAdminView.AVID + ".descIID.02", {case: "first"})
          }
        </small>
      </button>
      {adminContext.configAdmin?.IID && <button
        className="lc-buttonicon"
        style={{maxWidth: "220px"}}
        aria-label="Delete invitation code"
        onClick={() => adminContext.updateInvitation("delete", (errorCode) => setMessage(errorCode))}
        disabled={adminContext.isLoading}
      >
        <DeleteTwoToneIcon />
        <small style={{padding: "0x 4px"}}>{i18n.t(appConfig.AID + ".views." + HomeAdminView.AVID + ".descIID.03", {case: "first"})}</small>
      </button>}
		</></Widget>
		<Widget
      title={i18n.t(appConfig.AID + ".views." + UsersView.AVID + ".name")}
			isFocused={true}
      flex="1 0 48%"
		><>
			<form
				onSubmit={handleSubmit}
			>
        <label htmlFor="nbActiveUsers">{i18n.t("shared.userDict.activeUsers", {case: "first"})}</label>
        <input
          id="nbActiveUsers"
          type="text"
          value={adminContext.configAdmin?.users?.filter((u) => u.permissions?.length > 0).length || 0}
          disabled={true}
        />
        <label htmlFor="nbPendingUsers">{i18n.t("shared.userDict.pendingUsers", {case: "first"})}</label>
        <input
          id="nbPendingUsers"
          type="text"
          value={adminContext.configAdmin?.users?.filter((u) => u.permissions?.length === 0).length || 0}
          disabled={true}
        />
        <hr style={{width: "100%", margin: "20px 0px"}}/>
        <input
          type="button"
          aria-label={i18n.t(appConfig.AID + ".views." + UsersView.AVID + ".desc", {case: "first"})}
          value={i18n.t(appConfig.AID + ".views." + UsersView.AVID + ".desc", {case: "first"})}
          onClick={() => navigate(appConfig.url+UsersView.url)}
          disabled={adminContext.isLoading}
        />
			</form>
		</></Widget>
		<Widget
      title={i18n.t(appConfig.AID + ".views." + StoresView.AVID + ".name")}
			isFocused={true}
      flex="1 0 48%"
		>
			<form
				onSubmit={handleSubmit}
			>
        <label htmlFor="nbStores">{i18n.t("shared.companyDict.stores", {case: "first"}) + " live"}</label>
        <input
          id="nbStores"
          type="text"
          value={adminContext.getStores().filter((s) => !s.demo).length}
          disabled={true}
        />
        <label htmlFor="nbStoresDemo">{i18n.t("shared.companyDict.stores", {case: "first"}) + " demo"}</label>
        <input
          id="nbStoresDemo"
          type="text"
          value={adminContext.getStores().filter((s) => s.demo).length}
          disabled={true}
        />
        <hr style={{width: "100%", margin: "20px 0px"}}/>
        <input
          type="button"
          aria-label={i18n.t(appConfig.AID + ".views." + StoresView.AVID + ".desc", {case: "first"})}
          value={i18n.t(appConfig.AID + ".views." + StoresView.AVID + ".desc", {case: "first"})}
          onClick={() => navigate(appConfig.url+StoresView.url)}
          disabled={adminContext.isLoading}
        />
			</form>
		</Widget>
		<Widget
      title={i18n.t(appConfig.AID + ".views." + CatalogsView.AVID + ".name")}
			isFocused={true}
      flex="1 0 48%"
		>
			<form
				onSubmit={handleSubmit}
			>
        <label htmlFor="nbCatalogs">{i18n.t(appConfig.AID + ".views." + CatalogsView.AVID + ".name", {case: "first"})}</label>
        <input
          id="nbCatalogs"
          type="text"
          value={adminContext.getCatalogs().length}
          disabled={true}
        />
        <hr style={{width: "100%", margin: "20px 0px"}}/>
        <input
          type="button"
          aria-label={i18n.t(appConfig.AID + ".views." + CatalogsView.AVID + ".desc", {case: "first"})}
          value={i18n.t(appConfig.AID + ".views." + CatalogsView.AVID + ".desc", {case: "first"})}
          onClick={() => navigate(appConfig.url+CatalogsView.url)}
          disabled={adminContext.isLoading}
        />
			</form>
		</Widget>
		<Widget
      title={i18n.t(appConfig.AID + ".views." + BrandsView.AVID + ".name")}
			isFocused={true}
      flex="1 0 48%"
		>
			<form
				onSubmit={handleSubmit}
			>
        <label htmlFor="nbBrands">{i18n.t(appConfig.AID + ".views." + BrandsView.AVID + ".name", {case: "first"})}</label>
        <input
          id="nbBrands"
          type="text"
          value={companyContext?.getBrands()?.length || 0}
          disabled={true}
        />
        <hr style={{width: "100%", margin: "20px 0px"}}/>
        <input
          type="button"
          aria-label={i18n.t(appConfig.AID + ".views." + BrandsView.AVID + ".desc", {case: "first"})}
          value={i18n.t(appConfig.AID + ".views." + BrandsView.AVID + ".desc", {case: "first"})}
          onClick={() => navigate(appConfig.url+BrandsView.url)}
          disabled={true}
        />
			</form>
		</Widget>
		<Widget
      title={i18n.t(appConfig.AID + ".views." + CategoriesView.AVID + ".name")}
			isFocused={true}
      flex="1 0 48%"
		>
			<form
				onSubmit={handleSubmit}
			>
        <label htmlFor="nbCategories">{i18n.t(appConfig.AID + ".views." + CategoriesView.AVID + ".name", {case: "first"})}</label>
        <input
          id="nbCategories"
          type="text"
          value={companyContext?.getCategories()?.length || 0}
          disabled={true}
        />
        <hr style={{width: "100%", margin: "20px 0px"}}/>
        <input
          type="button"
          aria-label={i18n.t(appConfig.AID + ".views." + CategoriesView.AVID + ".desc", {case: "first"})}
          value={i18n.t(appConfig.AID + ".views." + CategoriesView.AVID + ".desc", {case: "first"})}
          onClick={() => navigate(appConfig.url+CategoriesView.url)}
          disabled={true}
        />
			</form>
		</Widget>
		{/* <Widget
      title={"Importer des données"}
			isFocused={true}
      flex="1 0 48%"
		>
			<form
				onSubmit={handleSubmit}
			>
        <label htmlFor="importFile">{"Permet d'importer les données utilisées pour le fonctionnement de LiveCrew"}</label>
        <input
          id="importFile"
          type="file"
          accept=".json"
          onChange={(e) => e.target && e.target.files && e.target.files[0] && e.target.files[0].name
            ? setFile(e.target.files[0] as File)
            : setFile({} as File)}
          disabled={adminContext.isLoading}
        />
        <hr style={{width: "100%", margin: "20px 0px"}}/>
        <input
          type="button"
          aria-label={"Import brands"}
          value={"Importer des marques"}
          onClick={(e) => {
            e.preventDefault();
            const reader = new FileReader();
            if (file && file.name) {
              reader.onload = (event) => {
                if (reader.result) {
                  const brands = JSON.parse(reader.result + "");
                  if (brands && Array.isArray(brands) && brands.length > 0) {
                    const regex = /[a-zA-Z0-9]+/g;
                    adminContext.updateCompany(
                      null,
                      brands.map((b: {label: string, value?: string}) => {return {
                        label: b.label,
                        value: b.value || b.label.toLocaleLowerCase().match(regex)?.join("") || b.label.toLocaleLowerCase()
                      }})
                    )
                  }
                } else {
                  console.log("Nope")
                }
              };
              reader.readAsText(file);
              setFile({} as File);
            }
            // e.target.reset();
          }}
          disabled={adminContext.isLoading}
        />
			</form>
		</Widget> */}
		<Widget
      title={"Exporter les données"}
			isFocused={true}
      flex="1 0 48%"
		>
			<form
				onSubmit={handleSubmit}
			>
        <small style={{width: "100%", textAlign: "center"}}>
          {"Permet d'exporter les données recueillies via LiveCrew au format Excel"}
        </small>
        <hr style={{width: "100%", margin: "20px 0px"}}/>
        <input
          type="button"
          aria-label={"Export feedbacks"}
          value={"Exporter les remontées clients"}
          onClick={() => adminContext.exportFeedbacks(dashboardContext.getFeedbacks())}
          // disabled={true}
        />
			</form>
		</Widget>
	</>)
}

const HomeAdminView : ViewInterface = {
  AVID: "homeadmin",
  component: (props?: {dual?: boolean}) => <HomeAdmin />,
  url: "/home",
  icon: <AccountCircleIcon />,
  color: "#00000000"
}

export default HomeAdminView;