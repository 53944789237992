// React
import React, { useState } from "react";
// MUI
import AspectRatioTwoToneIcon from '@mui/icons-material/AspectRatioTwoTone';
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ChangeCircleTwoToneIcon from '@mui/icons-material/ChangeCircleTwoTone';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import IndeterminateCheckBoxTwoToneIcon from '@mui/icons-material/IndeterminateCheckBoxTwoTone';
// Interfaces
import { ProductCatalogInterface } from "livecrew-interfaces";
// Contexts
import { useAdminContext } from "../../../contexts/admin";
// CSS
import "./index.css";
import appConfig from '../appConfig';
import SingleSelect from "../../../components/SingleSelect";
import Matrix from "../../../components/Matrix";

export default function CatalogsList(
  {catalogs, categories, brands}:
  {catalogs: ProductCatalogInterface[], categories: {label: string, value: string}[], brands: {label: string, value: string}[]}
) {
  const adminContext = useAdminContext();

  const [selected, setSelected] = useState("");
  const [selectedCatalog, setSelectedCatalog] = useState({} as ProductCatalogInterface);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");

	return (<div className="lc-itemslist">
    <div className="lc-itemslist-header" style={{backgroundColor: appConfig.color}}>
      <div className="lc-itemrow">
        <div className="lc-itemcell" style={{flex: "0.10 1 60px", color: "var(--dark)"}}>
          <AspectRatioTwoToneIcon />
        </div>
        <div className="lc-itemcell">
          <p>Nom</p>
        </div>
        <div className="lc-itemcell" style={{flex: "0.10 1 60px", color: "var(--dark)"}}>
          <DeleteTwoToneIcon />
        </div>
      </div>
    </div>
    <div className="lc-itemslist-container">
      {catalogs.length > 0 ? catalogs.filter((c) => !c.PID.includes("[lc]")).concat(catalogs.filter((c) => c.PID.includes("[lc]"))).map((catalog) => <div
        className="lc-itemrow"
        style={{backgroundColor: adminContext.needsUpdate("catalogs", catalog.PID) ? appConfig.colorDark + "44" : undefined,
          minHeight: catalog.PID === selected ? "fit-content" : undefined,
          flexWrap: "wrap"}}
        key={catalog.PID+"-row"}
      >
        <div className="lc-itemcell" style={{flex: "0.10 1 60px"}}>
          <button
            className="lc-buttonicon"
            onClick={() => selected === catalog.PID ? setSelected("") : setSelected(catalog.PID)}
            disabled={adminContext.isLoading}
          >{selected === catalog.PID ? <CloseFullscreenRoundedIcon /> : <OpenInFullRoundedIcon />}</button>
        </div>
        <div className="lc-itemcell">
          <input
            type="text"
            id={catalog.PID+"-name"}
            value={catalog.name}
            disabled={adminContext.isLoading}
            onChange={(e) => adminContext.set({state: "catalogs", id: catalog.PID, data: {...catalog, name: e.currentTarget.value}})}
            style={{width: "100%", margin: 0}}
          />
        </div>
        <div className="lc-itemcell" style={{flex: "0.10 1 60px"}}>
          <button
            className="lc-buttonicon"
            style={{color: "red"}}
            onClick={() => console.log("Delete : " + catalog.PID)}
            disabled={adminContext.isLoading || true}
          ><ClearTwoToneIcon /></button>
        </div>
        {(catalog.PID === selected) && <Matrix
          items={catalog.products.map((p) => {return {colRef: p.PCID, rowRef: p.PBID}})}
          colRefs={categories}
          rowRefs={brands}
          onUpdate={(items) => adminContext.set({state: "catalogs", id: catalog.PID, data: {...catalog, products: items.map((i) => {return {PCID: i.colRef, PBID: i.rowRef}})}})}
          disabled={adminContext.isLoading}
        />}
        {/* {(catalog.PID === selected) && <div className="lc-itemslist" style={{padding: "0px"}}>
          <div className="lc-itemslist-header" style={{backgroundColor: appConfig.color, overflowX: "auto"}}>
            <div className="lc-itemrow" style={{width: "fit-content", justifyContent: "unset"}}>
              <div className="lc-itemcell" style={{minWidth: "220px", width: "220px", flexWrap: "wrap"}}>
                <SingleSelect
                  id="category-select"
                  options={categories}
                  placeholder="Catégorie"
                  selected={selectedCategory}
                  setSelected={(s) => setSelectedCategory(s.value)}
                />
                <SingleSelect
                  id="brand-select"
                  options={brands}
                  placeholder="Marque"
                  selected={selectedBrand}
                  setSelected={(s) => setSelectedBrand(s.value)}
                />
                <button
                  className="lc-buttonicon"
                  style={{width: "100%"}}
                  onClick={() => {
                    const index = catalog.products.findIndex((product) => product.PCID === selectedCategory && product.PBID === selectedBrand);
                    adminContext.set({state: "catalogs", id: catalog.PID, data: (index !== -1)
                      ? {...catalog, products: catalog.products.filter((p) => p.PCID !== selectedCategory || p.PBID !== selectedBrand)}
                      : {...catalog, products: catalog.products.concat([{PCID: selectedCategory, PBID: selectedBrand}])}
                    });
                  }}
                  disabled={selectedCategory === "" || selectedBrand === "" || adminContext.isLoading}
                >{catalog.products.findIndex((product) => product.PCID === selectedCategory && product.PBID === selectedBrand) !== -1
                  ? <><IndeterminateCheckBoxTwoToneIcon /><small>Retirer</small></>
                  : <><AddBoxTwoToneIcon /><small>Ajouter</small></>
                }</button>
              </div>
              {categories.filter((c) => catalog.products.findIndex((p) => p.PCID === c.value) !== -1).map((category) => <div className="lc-itemcell" style={{minHeight: "100px", width: "40px"}}>
                <small style={{minWidth: "100px",transform: "rotate(-90deg)", textAlign: "center"}}>{category.label || " "}</small>
              </div>)}
            </div>
          </div>
          <div className="lc-itemslist-container" style={{padding: "2px", overflowX: "auto"}}>
            {brands.filter((b) => catalog.products.findIndex((p) => p.PBID === b.value) !== -1).map((brand) => <div className="lc-itemrow" style={{width: "fit-content"}}>
              <div className="lc-itemcell" style={{minWidth: "220px", width: "220px"}}><small>{brand.label || " "}</small></div>
              {categories.filter((c) => catalog.products.findIndex((p) => p.PCID === c.value) !== -1).map((category) => <div className="lc-itemcell" style={{width: "40px"}}>
                <button
                  className="lc-buttonicon"
                  style={{color: "var(--dark)", padding: 0, borderRadius: "50%"}}
                  onClick={() => {
                    const index = catalog.products.findIndex((product) => product.PCID === category.value && product.PBID === brand.value);
                    adminContext.set({state: "catalogs", id: catalog.PID, data: (index !== -1)
                      ? {...catalog, products: catalog.products.filter((p) => p.PCID !== category.value || p.PBID !== brand.value)}
                      : {...catalog, products: catalog.products.concat([{PCID: category.value, PBID: brand.value}])}
                    });
                  }}
                  disabled={adminContext.isLoading}
                >{(catalog.products.findIndex((product) => product.PCID === category.value && product.PBID === brand.value) !== -1)
                  ? <CheckCircleOutlineRoundedIcon />
                  : <RadioButtonUncheckedRoundedIcon />
                }</button>
              </div>)}
            </div>)}
          </div>
        </div>} */}
      </div>) : <div className="lc-itemrow">
        <div className="lc-itemcell" style={{flex: "1 1 100%", maxWidth: "unset"}}>
          <p>Nothing in there</p>
        </div>
      </div>}
    </div>
    <div className="lc-itemslist-footer">
      <div style={{flex: "1 0 33%", display: "flex", justifyContent: "left", alignItems: "start"}}><button
        className="lc-buttonicon"
        style={{color: adminContext.needsUpdate("catalogs") ? "var(--dark)" : "green"}}
        onClick={() => adminContext.cancel("catalogs")}
        disabled={!adminContext.needsUpdate("catalogs") || adminContext.isLoading}
      >{adminContext.needsUpdate("catalogs")
        ? <><ChangeCircleTwoToneIcon /><p style={{padding: 2}}>{"Annuler les modifications"}</p></>
        : <><CheckCircleTwoToneIcon /><small style={{padding: 2}}>{"Données à jour"}</small></>
      }</button></div>
      <div style={{flex: "1 0 33%", display: "flex", justifyContent: "center", alignItems: "start"}}><button
        className="lc-buttonicon"
        style={{color: appConfig.colorDark}}
        onClick={() => adminContext.set({state: "catalogs", id: "", data: {
          PID: "[lc]" + Date.now(),
          name: "",
          products: [],
        }})}
        disabled={adminContext.getCatalogs().filter((s) => s.name === "").length > 0 || adminContext.isLoading}
      >
        <AddCircleTwoToneIcon />
        <p style={{padding: 2}}>{"Nouveau catalogue"}</p>
      </button></div>
      <div style={{flex: "1 0 33%", display: "flex", justifyContent: "right", alignItems: "start"}}><button
        className="lc-buttonicon"
        style={{color: adminContext.needsUpdate("catalogs") ? "var(--dark)" : "green"}}
        onClick={() => adminContext.update("catalogs", "", (errorCode) => console.log(errorCode))}
        disabled={!adminContext.needsUpdate("catalogs") || adminContext.isLoading}
      >{adminContext.needsUpdate("catalogs")
        ? <><p style={{padding: 2}}>{"Enregistrer les modifications"}</p><SaveAltRoundedIcon /></>
        : <><small style={{padding: 2}}>{"Données à jour"}</small><CheckCircleTwoToneIcon /></>
      }</button></div>
    </div>
  </div>)
}