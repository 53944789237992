import React from 'react';
import { AnalyticsProvider } from './contexts/analytics';
import { DeviceProvider } from './contexts/device';
import { I18nProvider } from './contexts/i18n';
import { UserProvider } from './contexts/user';
import { CompanyProvider } from './contexts/company';
import { StoreProvider } from './contexts/store';
import { AppsProvider } from "./contexts/apps";
import { AdminProvider } from './contexts/admin';
import { CrewappProvider } from './contexts/crewapp';
import { DashboardProvider } from './contexts/dashboard';
import Nav from './nav';

function App() {
  return (
    <AnalyticsProvider>
        <I18nProvider>
          <UserProvider>
            <CompanyProvider>
              <StoreProvider>
                <AppsProvider>
                  <AdminProvider>
                    <CrewappProvider>
                      <DashboardProvider>
      <DeviceProvider>
                        <Nav />
      </DeviceProvider>
                      </DashboardProvider>
                    </CrewappProvider>
                  </AdminProvider>
                </AppsProvider>
              </StoreProvider>
            </CompanyProvider>
          </UserProvider>
        </I18nProvider>
    </AnalyticsProvider>
  );
}

export default App;
