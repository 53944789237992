// React
import React from 'react';

import {ReactComponent as Logo} from '../assets/logo.svg';
import { useDeviceContext } from '../contexts/device';

export default function Lost() {
  const deviceContext = useDeviceContext();

	return (
		<section className="loading" style={{justifyContent: 'center'}}>
			<div className="lc-card">
				<p>Lost in translation</p>
				<Logo />
        <p>{deviceContext.is()}</p>
			</div>
		</section>
	)
}