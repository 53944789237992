// React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// MUI
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// Interfaces
import { ViewInterface } from "../../../interfaces";
// Contexts
import { useI18nContext } from "../../../contexts/i18n";
import { useUserContext } from "../../../contexts/user";
// AppConfig
import appConfig from "../appConfig";
// Views
import RegisterView from "./Register";
import LoginView from "./Login";
// Components
import Widget from "../../../components/Widget";

const Recover = () => {
	const i18n = useI18nContext();
	const userContext = useUserContext();
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	// const [password, setPassword] = useState("");
	const [message, setMessage] = useState("");
	const [isDisabled, setIsDisabled] = useState(false);

	const onSuccess = () => {
		setMessage(i18n.t(appConfig.AID + ".views." + RecoverView.AVID + ".emailSent"));
		setIsDisabled(true);
	};

	const onError = (errorCode: string) => {
		setMessage(i18n.t(errorCode));
		setIsDisabled(false);
	}

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsDisabled(true);
		userContext.sendPassReset(email, onSuccess, onError);
	};

	return (<div className="view-guest">
		<Widget
			title={i18n.t(appConfig.AID + ".views." + RecoverView.AVID + ".name")}
			isFocused={true}
			width="100%"
		>
			<form
				onSubmit={handleSubmit}
			>
				<label htmlFor="email">{i18n.t("shared.userDict.email", {case: "first"})}</label>
				<input
					id="email"
					type="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					disabled={isDisabled}
				/>
				<input
					type="submit"
					aria-label="Submit button"
					value={i18n.t("shared.submit")}
					disabled={isDisabled}
				/>
				<small style={{textAlign: "center"}}>{message}</small>
			</form>
		</Widget>
		<div style={{
			width: "100%",
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "space-between",
			alignItems: "center",
			padding: "4px 0px",
		}}>
			<input
				type="button"
				aria-label={i18n.t(appConfig.AID + ".views." + RegisterView.AVID + ".name", {defaultValue: ""})}
				value={i18n.t(appConfig.AID + ".views." + RegisterView.AVID + ".name", {defaultValue: ""})}
				onClick={() => navigate(appConfig.url+RegisterView.url)}
			/>
			<input
				type="button"
				aria-label={i18n.t(appConfig.AID + ".views." + LoginView.AVID + ".name", {defaultValue: ""})}
				value={i18n.t(appConfig.AID + ".views." + LoginView.AVID + ".name", {defaultValue: ""})}
				onClick={() => navigate(appConfig.url+LoginView.url)}
			/>
		</div>
	</div>)
}

const RecoverView : ViewInterface = {
  AVID: "recover",
  component: (props?: {dual?: boolean}) => <Recover />,
  url: "/recover",
  icon: <AccountCircleIcon />
}

export default RecoverView;