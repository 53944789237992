// React
import React, { useEffect, useState } from 'react';
// Interfaces
import { I18nLng } from 'livecrew-interfaces';
import { i18nContextInterface } from "../interfaces";
// I18n
import i18n from "../i18n";

const I18nContext = React.createContext({} as i18nContextInterface);

const I18nProvider = ({children}: {children: JSX.Element}) => {
  const availableLocales = i18n.getAvailableLocales();
  const defaultLocale = navigator.language && availableLocales.includes(navigator.language as I18nLng) ? navigator.language as I18nLng : "fr-FR";
  const [isLoading, setIsLoading] = useState(true);
  const [currentLocale, setCurrentLocale] = useState(defaultLocale as I18nLng);
  const availableTips = 2;
  // const [isLoading, setIsLoading] = useState(true);

  const getAvailableLocales = () : I18nLng[] => Array.isArray(availableLocales) && availableLocales.length > 0 ? [...availableLocales] : [];

  const getLocale = () : I18nLng => currentLocale || "";

  const setLocale = (locale: I18nLng) : void => {
    Array.isArray(availableLocales) && availableLocales.includes(locale)
      ? i18n.updateLocale(locale, () => setCurrentLocale(locale))
      : i18n.updateLocale(defaultLocale, () => setCurrentLocale(defaultLocale))
  };

  const resetLocale = () => setCurrentLocale(defaultLocale);
  
  // const getTipsRef = () => Number.isInteger(currentTips) ? "nav.tips." + currentTips : "nav.tips.0";

  const getRandomTips = () => t("nav.tips."+Math.floor(Math.random() * availableTips), {defaultValue: "You'll always find a random tips here"});

  const t = (key: string, options?: {
    defaultValue?: string,
    case?: "upper" | "lower" | "first",
  }) : string => {
    const string = i18n.translate(key, {defaultValue: options?.defaultValue});
    if (options?.case) {
      switch(options.case) {
        case "upper":
          return string.toUpperCase();
        case "lower":
          return string.toLowerCase();
        case "first":
          return string.length > 0
            ? string[0].toUpperCase() + string.slice(1).toLowerCase()
            : string;
        default:
          return string;
      }
    } else {
      return string;
    }
  };

  // const t = (key: string, options?: {
  //   defaultValue?: string,
  //   case?: "upper" | "lower" | "first",
  // }) : string => {
  //   return i18n.translate(key, options);
  // };

  const accessors = {
    // availableLocales,
    // locale,
    isLoading,
    getAvailableLocales,
    getLocale,
    setLocale,
    resetLocale,
    getRandomTips,
    t,
    // getTipsRef,
    // renegotiateLocale
  }

  useEffect(() => {
    i18n.updateLocale(defaultLocale, () => setIsLoading(false));
  }, [defaultLocale])

  return (
    <I18nContext.Provider value={accessors}>
      {/* <LocalizationProvider l10n={new ReactLocalization(bundles)}> */}
        {!isLoading && children}
      {/* </LocalizationProvider> */}
    </I18nContext.Provider>
  )
};

const useI18nContext = () => {
  const context = React.useContext(I18nContext);
  if (context === undefined) {
      throw new Error('No i18n context!');
  }
  return context
};

export {
  I18nProvider,
  useI18nContext
}