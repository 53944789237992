// React
import React, { useEffect, useRef, useState } from "react";
// Material UI
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import IndeterminateCheckBoxTwoToneIcon from '@mui/icons-material/IndeterminateCheckBoxTwoTone';
// CSS
import "./index.css";
// Components
import SingleSelect from "../SingleSelect";

export default function Matrix(
  {items, colRefs, rowRefs, onUpdate, disabled}:
  {items: {colRef: string, rowRef: string}[], colRefs: {label: string, value: string}[], rowRefs: {label: string, value: string}[], onUpdate?: (items: {colRef: string, rowRef: string}[]) => void, disabled?: boolean}
) {
  const colsRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<HTMLDivElement>(null);
  const [selectedCol, setSelectedCol] = useState("");
  const [selectedRow, setSelectedRow] = useState("");
  const [highlighted, setHighlighted] = useState({colRef: "", rowRef: ""});

  useEffect(() => {
    const onScroll = () => itemsRef?.current?.scrollTo({left: colsRef?.current?.scrollLeft});
    colsRef?.current?.addEventListener("scroll", onScroll);
    return () => (colsRef && colsRef.current)
      ? colsRef.current.removeEventListener("scroll", onScroll)
      : undefined;
  }, [colsRef, itemsRef]);

	return (<div className="lc-matrix">
    <div className="lc-matrix-header">
      <div className="lc-matrix-menu">
        <SingleSelect
          id="category-select"
          options={colRefs}
          placeholder="Catégorie"
          selected={selectedCol}
          setSelected={(s) => setSelectedCol(s.value)}
        />
        <SingleSelect
          id="brand-select"
          options={rowRefs}
          placeholder="Marque"
          selected={selectedRow}
          setSelected={(s) => setSelectedRow(s.value)}
        />
        <button
          className="lc-buttonicon"
          style={{width: "100%"}}
          onClick={onUpdate ? () => {
            const index = items.findIndex((item) => item.colRef === selectedCol && item.rowRef === selectedRow);
            onUpdate((index !== -1)
              ? items.filter((item) => item.colRef !== selectedCol || item.rowRef !== selectedRow)
              : items.concat([{colRef: selectedCol, rowRef: selectedRow}])
            )} : undefined}
          disabled={selectedCol === "" || selectedRow === "" || disabled}
        >{items.findIndex((item) => item.colRef === selectedCol && item.rowRef === selectedRow) !== -1
          ? <><IndeterminateCheckBoxTwoToneIcon /><small>Retirer</small></>
          : <><AddBoxTwoToneIcon /><small>Ajouter</small></>
        }</button>
      </div>
      <div className="lc-matrix-colrefs" ref={colsRef}>
        {colRefs.filter((colRef) => items.findIndex((i) => i.colRef === colRef.value) !== -1).map((colRef) => <div
          className="lc-matrix-cell"
          style={{backgroundColor: (highlighted.colRef === colRef.value) ? "var(--interaction88)" : "var(--light)"}}
        >
          <small style={{minWidth: "180px",transform: "rotate(-90deg)", textAlign: "center"}}>{colRef.label}</small>
        </div>)}
      </div>
    </div>
    <div className="lc-matrix-container">
      <div className="lc-matrix-rowrefs">
        {rowRefs.filter((rowRef) => items.findIndex((i) => i.rowRef === rowRef.value) !== -1).map((rowRef) => <div
          className="lc-matrix-cell"
          style={{maxWidth: "200px", width: "200px", backgroundColor: (highlighted.rowRef === rowRef.value) ? "var(--interaction88)" : "var(--light)"}}
        >
          <small style={{textAlign: "center"}}>{rowRef.label}</small>
        </div>)}
      </div>
      <div className="lc-matrix-items" ref={itemsRef}>
        {rowRefs.filter((rowRef) => items.findIndex((i) => i.rowRef === rowRef.value) !== -1).map((rowRef) => <div className="lc-matrix-row">
          {colRefs.filter((colRef) => items.findIndex((i) => i.colRef === colRef.value) !== -1).map((colRef) => <div
            className="lc-matrix-cell"
            style={{backgroundColor: (highlighted.rowRef === rowRef.value || highlighted.colRef === colRef.value) ? "var(--interaction88)" : "var(--light)"}}
            onMouseEnter={() => setHighlighted({colRef: colRef.value, rowRef: rowRef.value})}
            onMouseLeave={() => setHighlighted({colRef: "", rowRef: ""})}
          >
            {<button
              className="lc-buttonicon"
              style={{color: "var(--dark)", padding: 0, borderRadius: "50%"}}
              onClick={onUpdate ? () => {
                const index = items.findIndex((item) => item.colRef === colRef.value && item.rowRef === rowRef.value);
                onUpdate((index !== -1)
                  ? items.filter((item) => item.colRef !== colRef.value || item.rowRef !== rowRef.value)
                  : items.concat([{colRef: colRef.value, rowRef: rowRef.value}])
                )}
              : undefined}
              disabled={disabled}
            >{(items.findIndex((item) => item.colRef === colRef.value && item.rowRef === rowRef.value) !== -1)
              ? <CheckCircleOutlineRoundedIcon />
              : <RadioButtonUncheckedRoundedIcon />
            }</button>}
          </div>)}
        </div>)}
      </div>
    </div>
  </div>)
}