import { serverTimestamp, query, where, onDocUpdate, getColRef, getDocRef, getDocs, getDoc, setDoc, doc } from "../../../firestore";
import { QuestFeedbackInterface, questFeedbacksColPath, questFeedbackDocPath, CID, CSID, TID, TFID } from "livecrew-interfaces";

const listenAll = (CID: CID, CSID: CSID, TID: TID, onChange: (feedbacks: QuestFeedbackInterface[]) => void) => onDocUpdate(
  CSID ? query(getColRef(questFeedbacksColPath(CID, TID))
  , where("createdAt", "!=", new Date().toISOString().split('T')[0])
  , where("CSID", "==", CSID)
  ) : query(getColRef(questFeedbacksColPath(CID, TID))
  , where("createdAt", "!=", new Date().toISOString().split('T')[0])
  ),
  (querySnap) => {
    const feedbacks = [] as QuestFeedbackInterface[];
    querySnap.forEach((doc) => feedbacks.push({...doc.data(), TFID: doc.id} as QuestFeedbackInterface));
    onChange(feedbacks);
  },
  (error) => {console.log(error); onChange([])},
);

const read = (CID: CID, TID: TID, TFID: TFID, onSuccess: (feedback: QuestFeedbackInterface) => void, onError: (errorCode: string) => void) => getDoc(getDocRef(questFeedbackDocPath(CID, TID, TFID)))
    .then((docSnap) => {
      if (docSnap.exists()) {
        onSuccess({...docSnap.data(), TFID: docSnap.id} as QuestFeedbackInterface);
      } else {
        onError("db.doc.01");
      }
    })
    .catch((error) => {
      console.log(error);
      onError(error.code);
    });

const list = (CID: CID, TID: TID, onSuccess: (feedbacks: QuestFeedbackInterface[]) => void, onError: (errorCode: string) => void) => getDocs(getColRef(questFeedbacksColPath(CID, TID)))
    .then((querySnap) => {
      const feedbacks = [] as QuestFeedbackInterface[];
      querySnap.forEach((doc) => feedbacks.push({...doc.data(), TFID: doc.id} as QuestFeedbackInterface));
      onSuccess(feedbacks);
    })
    .catch((error) => {
      console.log(error);
      onError(error.code);
    });

const create = (CID: CID, TID: TID, feedback: QuestFeedbackInterface, onSuccess: (TFID: TFID) => void, onError: (errorCode: string) => void) => {
  const newFeedback = doc(getColRef(questFeedbacksColPath(CID, TID)));
  setDoc(newFeedback, {...feedback, TFID: newFeedback.id})
      .then(() => onSuccess(newFeedback.id))
      .catch((error) => {
        console.log(error);
        onError(error.code);
      });
};
// , createdAt: serverTimestamp()
const feedbacks = {
  listenAll,
  read,
  list,
  create,
};

export default feedbacks;