// MUI
import SettingsApplicationsTwoToneIcon from '@mui/icons-material/SettingsApplicationsTwoTone';

const appConfig = {
  AID: "admin",
  version: "0.1.0",
  url: "/admin",
  // color: "#F4F4F8",
  color: "var(--light)",
  colorDark: "#404080",
  icon: <SettingsApplicationsTwoToneIcon />,
};

export default appConfig;