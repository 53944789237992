// React
import React from 'react';
// Interface
import { AppInterface } from "../../interfaces";
// Config
import appConfig from './appConfig';
// Wrapper
import AppWrapper from "./appWrapper";
// Views
import HomeView from './views/Home';
import WhyView from './views/Why';
import HowView from './views/How';
import AboutView from './views/About';
import ContactView from './views/Contact';

const website : AppInterface = {
  ...appConfig,
  component: (view) => <AppWrapper view={view || HomeView} />,
  views: [
    HomeView,
    // WhyView,
    // HowView,
    // AboutView,
    // ContactView
  ],
}

export default website;