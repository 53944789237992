import { onDocUpdate, getColRef, getDocs, getDocRef, getDoc, setDoc, doc, query } from "../../../firestore";
import { SurveyConfigInterface, surveyConfigsColPath, surveyConfigDocPath, CID, TID, TCID } from "livecrew-interfaces";

const listenAll = (CID: CID, TID: TID, onChange: (surveyConfigs: SurveyConfigInterface[]) => void) => onDocUpdate(
  query(getColRef(surveyConfigsColPath(CID, TID))),
  (querySnap) => {
    const surveyConfigs = [] as SurveyConfigInterface[];
    querySnap.forEach((doc) => surveyConfigs.push(doc.data() as SurveyConfigInterface));
    onChange(surveyConfigs);
  },
  (error) => onChange([]),
);

const read = (CID: CID, TID: TID, TCID: TCID, onSuccess: (config: SurveyConfigInterface) => void, onError: (errorCode: string) => void) => getDoc(getDocRef(surveyConfigDocPath(CID, TID, TCID)))
    .then((docSnap) => {
      if (docSnap.exists()) {
        onSuccess({...docSnap.data(), TCID: docSnap.id} as SurveyConfigInterface);
      } else {
        onError("db.doc.01");
      }
    })
    .catch((error) => {
      console.log(error);
      onError(error.code);
    });

const create = (CID: CID, TID: TID, config: SurveyConfigInterface, onSuccess: (TCID: TCID) => void, onError: (errorCode: string) => void) => {
  const newConfig = doc(getColRef(surveyConfigsColPath(CID, TID)));
  setDoc(newConfig, {...config, TCID: newConfig.id})
      .then(() => onSuccess(newConfig.id))
      .catch((error) => {
        console.log(error);
        onError(error.code);
      });
};
    
const configs = {
  listenAll,
  read,
  create,
};

export default configs;