import firebaseApp from "../firebase";
import { getAuth, connectAuthEmulator, onAuthStateChanged, getIdTokenResult, getIdToken, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, confirmPasswordReset } from "firebase/auth";
import { AuthInterface, UID, CID, CSID, PermissionType } from "livecrew-interfaces";
// import analytics from "../analytics";

const auth = getAuth(firebaseApp);
// if (window.location.hostname === "localhost") connectAuthEmulator(auth, "http://localhost:9090");

const onAuthChange = (handleChange: (user: AuthInterface | null) => void) => onAuthStateChanged(auth, (user) => {
  console.log("Auth change");
  if (user) {
    getIdTokenResult(user, true)
      .then((idTokenResult) => {
        const newUser = {
          email: user.email || "",
          UID: user.uid || "" as UID,
          CID: idTokenResult.claims.CID || "" as CID,
          CSID: idTokenResult.claims.CSID || "" as CSID,
          permissions: idTokenResult.claims.permissions || [] as PermissionType[],
        } as AuthInterface;
        // analytics.setUser(newUser);
        // analytics.log.login();
        handleChange(newUser);
      })
      .catch((error) => handleChange(null))
      // .catch((error) => handleChange({
      //   email: user.email || "",
      //   UID: user.uid || "",
      //   CID: "",
      //   CSID: "",
      //   permissions: [],
      // }))
  } else {
    handleChange(null);
  }
});

const refreshUser = (handleChange: (user: AuthInterface | null) => void) => {
  if (auth.currentUser) {
    getIdToken(auth.currentUser, true)
        .then((res) => {
          if (auth.currentUser) {
            getIdTokenResult(auth.currentUser, true)
              .then((idTokenResult) => handleChange({
                email: auth.currentUser?.email || "",
                UID: auth.currentUser?.uid || "" as UID,
                CID: idTokenResult.claims.CID || "" as CID,
                CSID: idTokenResult.claims.CSID || "" as CSID,
                permissions: idTokenResult.claims.permissions || [] as PermissionType[],
              } as AuthInterface))
              .catch((error) => handleChange(null))
          } else {
            handleChange(null)
          }
        })
        .catch((err) => handleChange(null));
  } else {
    handleChange(null);
  }
};

// const refreshUser = (handleChange: (user: AuthInterface | null) => void) => {
//   if (auth.currentUser) {
//     const email = auth.currentUser.email || "";
//     const UID = auth.currentUser.uid || "";
//     getIdTokenResult(auth.currentUser, true)
//       .then((idTokenResult) => handleChange({
//         email: email,
//         UID: UID,
//         CID: idTokenResult.claims.CID as string || "",
//         CSID: idTokenResult.claims.CSID as string || "",
//         permissions: idTokenResult.claims.permissions as string[] || [],
//       } as AuthInterface))
//       .catch((error) => handleChange({
//         email: email,
//         UID: UID,
//         CID: "",
//         CSID: "",
//         permissions: [],
//       }))
//   } else {
//     handleChange(null);
//   }
// };

const register = (email: string, password: string, onSuccess: () => void, onError: (errorCode: string) => void) => createUserWithEmailAndPassword(auth, email, password)
    .then(({user}) => {
      const newUser = {
        email: user.email || "",
        UID: user.uid || "" as UID,
        CID: "" as CID,
        CSID: "" as CSID,
        permissions: [] as PermissionType[],
      } as AuthInterface;
      // analytics.setUser(newUser);
      // analytics.log.signUp();
      onSuccess();
    })
    .catch((error) => {
      let errorCode = "auth.00";
      switch (error.code) {
        case "auth/email-already-in-use":
          errorCode = "auth.register.01";
          break;
        case "auth/invalid-email":
          errorCode = "auth.register.02";
          break;
        case "auth/weak-password":
          errorCode = "auth.register.03";
          break;
        case "auth/operation-not-allowed":
          errorCode = "auth.register.04";
          break;
        default:
          console.log(error);
          // errorCode = error.code || errorCode;
      }
      onError("errors." + errorCode);
    });

const login = (email: string, password: string, onSuccess: () => void, onError: (errorCode: string) => void) => signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => onSuccess())
    .catch((error) => {
      let errorCode = "auth.00";
      switch (error.code) {
        case "auth/user-not-found":
          errorCode = "auth.login.01";
          break;
        case "auth/wrong-password":
          errorCode = "auth.login.01";
          break;
        case "auth/too-many-requests":
          errorCode = "auth.login.02";
          break;
        default:
          console.log(error);
          // errorCode = error.code || errorCode;
      }
      onError("errors." + errorCode);
    });

const logout = (onSuccess: () => void, onError: (errorCode: string) => void) => signOut(auth)
    .then(() => onSuccess())
    .catch((error) => {
      let errorCode = "auth.00";
      // switch (error.code) {
      //   case "auth/invalid-email":
      //     errorCode = "auth.sendPassReset.01";
      //     break;
      //   default:
      //     console.log(error);
      // }
      onError("errors." + errorCode);
    });

const sendPassReset = (email: string, onSuccess: () => void, onError: (errorCode: string) => void) => sendPasswordResetEmail(auth, email)
    .then(() => onSuccess())
    .catch((error) => {
      let errorCode = "auth.00";
      switch (error.code) {
        case "auth/invalid-email":
          errorCode = "auth.sendPassReset.01";
          break;
        case "auth/user-not-found":
          errorCode = "auth.sendPassReset.02";
          break;
        case "auth/missing-continue-uri":
          errorCode = "auth.sendPassReset.03";
          break;
        case "auth/invalid-continue-uri":
          errorCode = "auth.sendPassReset.04";
          break;
        default:
          console.log(error);
          // errorCode = error.code || errorCode;
      }
      onError("errors." + errorCode);
    });

const confirmPassReset = (code: string, newPassword: string, onSuccess: () => void, onError: (errorCode: string) => void) => confirmPasswordReset(auth, code, newPassword)
    .then(() => onSuccess())
    .catch((error) => {
      let errorCode = "auth.00";
      switch (error.code) {
        case "auth/expired-action-code":
          errorCode = "auth.confirmPassReset.01";
          break;
        case "auth/invalid-action-code":
          errorCode = "auth.confirmPassReset.02";
          break;
        case "auth/user-disabled":
          errorCode = "auth.confirmPassReset.03";
          break;
        case "auth/user-not-found":
          errorCode = "auth.confirmPassReset.04";
          break;
        case "auth/weak-password":
          errorCode = "auth.confirmPassReset.05";
          break;
        default:
          console.log(error);
          // errorCode = error.code || errorCode;
      }
      onError("errors." + errorCode);
    });

const firebaseAuth = {
  onAuthChange,
  refreshUser,
  register,
  login,
  logout,
  sendPassReset,
  confirmPassReset
}

export default firebaseAuth;