// React
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
// Interfaces
import { AppInterface, ViewInterface } from "../interfaces";
// CSS
// import "./index.css";
// Components
import NavBar from './NavBar';
import MainContainer from './MainContainer';
// Contexts
import { useUserContext } from '../contexts/user';
import { useAppsContext } from '../contexts/apps';

export default function Nav() {
  const userContext = useUserContext();
  const appsContext = useAppsContext();

  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [currentApp, setCurrentApp] = useState({} as AppInterface);
  const [currentView, setCurrentView] = useState({} as ViewInterface);
  // const apps = appsContext.getApps();
  const headerHeight = 40;
  const headerPadding = 4;

  useEffect(() => {
    let timeoutOpen: ReturnType<typeof setTimeout>;
    let timeoutLoading: ReturnType<typeof setTimeout>;
    if (!userContext.isLoading && !appsContext.isLoading) {
      timeoutOpen = setTimeout(() => {
        setIsOpen(false);
      }, 500);
      timeoutLoading = setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      setIsLoading(true);
      setIsOpen(true);
    }
    return () => {
      timeoutOpen && clearTimeout(timeoutOpen);
      timeoutLoading && clearTimeout(timeoutLoading);
    };
    // let timeoutId: ReturnType<typeof setTimeout>;
    // if (!userContext.isLoading && !appsContext.isLoading) {
    //   timeoutId = setTimeout(() => {
    //     setIsOpen(false);
    //     setIsLoading(false);
    //   }, 500);
    // } else {
    //   setIsLoading(true);
    //   setIsOpen(true);
    // }
    // return () => timeoutId && clearTimeout(timeoutId);
  }, [userContext.isLoading, appsContext.isLoading])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      e.key === "Escape" && setIsOpen((oldstate) => !oldstate);
      // e.key === "1" && apps.length > 0 && navigate(apps[0].url)
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  // useEffect(() => console.log(currentApp.url + "" + currentView.url), [currentApp, currentView]);

	return (
		<BrowserRouter>
      <NavBar
        headerHeight={headerHeight}
        headerPadding={headerPadding}
        isLoading={isLoading}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        currentApp={currentApp}
        currentView={currentView}
      />
      <MainContainer
        headerHeight={headerHeight}
        headerPadding={headerPadding}
        isLoading={isLoading}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        currentApp={currentApp}
        setCurrentApp={setCurrentApp}
        currentView={currentView}
        setCurrentView={setCurrentView}
      />
		</BrowserRouter>
  )
}