// React
import React, { useEffect, useState } from 'react';
import { createInstance, useMatomo, MatomoProvider, MatomoContext } from "@datapunt/matomo-tracker-react";
import { MatomoInstance } from '@datapunt/matomo-tracker-react/lib/types';
import { ViewInterface } from '../interfaces';
import packageJSON from "../../package.json";

// const instanceTest = createInstance({
//   urlBase: "https://livecrew.matomo.cloud/",
//   siteId: 2,
// });

interface AnalyticsContextInterface {
  instance: null | MatomoInstance,
  optOut: boolean,
  setOptOut: (state: boolean) => void,
  // consentPersonnal: boolean,
};

/** 
 * Ref to the analytics context, do NOT expose directly
 * @see AnalyticsProvider to provide the context
 * @see useDeviceContext to subscribe to the context
 */
const AnalyticsContext = React.createContext<AnalyticsContextInterface>({} as AnalyticsContextInterface);

/**
 * Provides the device context to its children
 * @param {JSX} children React children props
 */
const AnalyticsProvider = ({children} : {children: JSX.Element}) => {
  const [instance, setInstance] = useState(null as null | MatomoInstance);
  const [optOut, setOptOut] = useState(false);
  // const [consentPersonnal, setConsentPersonnal] = useState(false);

  useEffect(() => {
    if (optOut) {
      setInstance(null);
      console.log("Analytics : off");
    } else {
      const i = createInstance({
        urlBase: "https://livecrew.matomo.cloud/",
        siteId: 2,
      });
      i.pushInstruction("requireCookieConsent");
      i.pushInstruction('setCustomDimension', 1, packageJSON.version || "0.0.0");
      // i.pushInstruction("visit_standard_length", 1);
      // _paq.push(['setCustomDimension', 1, 'valeurDeDimension']);
      setInstance(i);
      console.log("Analytics : anonymous mode on");
    }
  }, [optOut]);

  const accessors = {
    instance,
    optOut,
    setOptOut,
    // consentPersonnal,
  }


  // Do NOT expose state and setState directly in the provider value, ever
	return (
    <AnalyticsContext.Provider value={accessors}>
      {children}
    </AnalyticsContext.Provider>
	)
	// return (
	// 	<MatomoProvider value={instance}>
  //     {children}
	// 	</MatomoProvider>
	// )
};

/**
 * Returns the actual value of the device context
 * @example
 * const device = useDeviceContext() // subscribe to the context
 * const touchscreen = device.isTouch() // use methods provided to interact with the context
 * @see DeviceProvider for the full list of methods
 */
const useAnalyticsContext = () => {
	const context = React.useContext(AnalyticsContext);
	if (context === undefined) {
		throw new Error('No analytics context!');
	}
	return context;
};

// const useTrackPageView = (appConfig: {AID: string, url: string, [key: string]: any}, view: {AVID: string, [key: string]: any}) => {
const useTrackPageView = (AID: string, AVID: string) => {
  const analytics = useAnalyticsContext();
  useEffect(() => {
    if (analytics && analytics.instance) {
      // console.log("Track: " + appConfig.AID + " - " + view.AVID);
      analytics.instance.trackPageView({
        href: window.location.href,
        documentTitle: AID + " - " + AVID,
      })
    }
  }, [window.location, AID, AVID, analytics.instance]);
}

// Do NOT export DeviceContext directly, ever
export {
	AnalyticsProvider,
  useAnalyticsContext,
  useTrackPageView
};