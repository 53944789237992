// React
import React from 'react';
import Select from "react-select";
// CSS
// import "./index.css";
// Interfaces
import { ReactSelectInterface } from '../../interfaces';

export default function MultiSelect(
  {id, title, disabled, placeholder, maxGrow, options, selected, setSelected}:
  {id: string, title?: string, disabled?: boolean, placeholder: string, maxGrow?: string, options: ReactSelectInterface[], selected: ReactSelectInterface[], setSelected: (selected: ReactSelectInterface[]) => void}
) {

	return (<div style={{minWidth: "200px", margin: "8px", flex: maxGrow ? "1 0 calc("+maxGrow+" - 18px)" : "1 0 calc(100% - 18px)"}}>
    {title && <h6 style={{width: "100%", textAlign: "center", fontWeight: "normal"}}>{title}</h6>}
    <Select
      // defaultValue={selected}
      value={selected}
      isMulti
      name={id}
      isDisabled={disabled}
      placeholder={placeholder}
      options={options}
      onChange={(v) => setSelected(v as {value: string, label: string}[])}
      closeMenuOnSelect={false}
      className="basic-multi-select"
      classNamePrefix="lc-select"
      styles={{
        container: (styles, { isDisabled, isFocused }) => ({
          // boxSizing: "border-box",
          // width: "100%",
          // width: width ? width : "auto",
          // maxWidth: "400px",
          // maxWidth: maxWidth ? "calc("+maxWidth+" - 16px)" : "unset",
          // width: "100%",
          // flex: "1 0 calc(50% - 16px)",
          // flex: flex ? flex + " 0 calc(50% - 16px)" : "1 0 calc(50% - 16px)",
          // flex: "1 0 calc(50% - 16px)",
          // height: "100%",
          ...styles,
          // minWidth: "200px",
          // flex: maxGrow ? "1 0 calc("+maxGrow+" - 16px)" : "1 0 calc(100% - 18px)",
          // margin: "8px",
          fontSize: "calc(0.6rem + 1vmin)",
        }),
        menu: (styles) => ({
          ...styles,
          // boxSizing: "border-box",
          backgroundColor: "var(--light)",
          width: "100%",
          margin: "2px 0px",
          zIndex: 88,
        }),
        control: (styles, { isDisabled, isFocused }) => ({
          ...styles,
          color: "var(--dark)",
          backgroundColor: isDisabled ? "var(--interactive88)" : "var(--light)",
          borderColor: isFocused
            ? "var(--interaction)"
            : "var(--interactive)",
          cursor: "pointer",
          height: "100%",
          maxWidth: "100%",
        }),
        input: (styles, { isDisabled }) => ({
          ...styles,
          backgroundColor: "#00000000",
          "> *": {
            boxShadow: "none",
          }
        }),
        multiValueLabel: (styles, { data, isDisabled, isFocused }) => ({
          ...styles,
          color: "var(--light)",
          backgroundColor: "var(--interactive)",
        }),
        multiValue: (styles, { data, isDisabled, isFocused }) => ({
          ...styles,
          color: "var(--light)",
          backgroundColor: "var(--interactive)",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
          ...styles,
          backgroundColor: isFocused
            ? "var(--interaction)"
            : "var(--light)",
          cursor: "pointer",
        }),
        dropdownIndicator : (styles, { isFocused }) => ({
          ...styles,
          color: "var(--interactive)",
          backgroundColor: "#00000000",
          "&:hover": {
            color: "var(--interaction)"
          }
        }),
        clearIndicator: (styles, { isFocused }) => ({
          ...styles,
          color: "var(--interactive)",
          backgroundColor: "#00000000",
          "&:hover": {
            color: "var(--interaction)"
          }
        }),
      }}
    />
	</div>)
}