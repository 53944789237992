// React
import React, { useState, useLayoutEffect  } from 'react';
// Interfaces
import { CompanyInterface, PBID, PCID, StoreInterface } from 'livecrew-interfaces';
import { CompanyContextInterface } from "../interfaces";
// Backend
import { db } from "../backend"
// Contexts
import { useUserContext } from './user';

/** 
 * Ref to the user context, do NOT expose directly
 * @see CompanyProvider to provide the context
 * @see useCompanyContext to subscribe to the context
 */
const CompanyContext = React.createContext<CompanyContextInterface>({} as CompanyContextInterface);

/**
 * Provides the user context to its children
 * @param {JSX} children React children props
 */
const CompanyProvider = ({children}: {children: JSX.Element}) => {
  const userContext = useUserContext();
	const [state, setState] = useState({} as CompanyInterface);
  const [stores, setStores] = useState([] as StoreInterface[]);
  const [isLoading, setIsLoading] = useState(true);

  const getCategories = () => state?.categories?.length > 0 ? state.categories.map((c) => {return {...c}}) : [];

  const getCategory = (PCID: PCID) => state?.categories?.length > 0 ? state.categories.find((c) => c.value === PCID) || {label: "Autre", value: "[lc]other"} : {label: "Autre", value: "[lc]other"};

  const getBrands = () => state?.brands?.length > 0 ? state.brands.sort((a, b) => a.value.localeCompare(b.value)).map((b) => {return {...b}}) : [];

  const getBrand = (PBID: PBID) => state?.brands?.length > 0 ? state.brands.find((b) => b.value === PBID) || {label: "Autre", value: "[lc]other"} : {label: "Autre", value: "[lc]other"};

  const getStores = () => stores.length > 0 ? stores.filter((s) => !s.demo).sort((a, b) => a.name.localeCompare(b.name)).map((s) => {return {label: s.name, value: s.CSID}}) : [];

  // const fetchState = (CID: string) => {
  //   // setIsLoading(true);
  //   if (CID) {
  //     db.companies.read(
  //       CID,
  //       (company) => {setState(company); setIsLoading(false)},
  //       (errorCode) => {setState({} as CompanyInterface); setIsLoading(false)},
  //     );
  //   } else {
  //     setState({} as CompanyInterface);
  //     setIsLoading(false);
  //   }
  // };

  const accessors = {
    ...state,
    isLoading,
    getStores,
    getCategories,
    getCategory,
    getBrands,
    getBrand,
    // fetchState,
  };

  useLayoutEffect(() => {
    const unsubCompany = (userContext.CID && userContext.permissions)
      ? db.companies.listen(
        userContext.CID,
        (newCompany) => {setState(newCompany); setIsLoading(false)},
        // (errorCode) => fetchState(userContext.CID),
      ) : undefined;
    return unsubCompany;
  }, [userContext.CID, userContext.permissions]);

  useLayoutEffect(() => {
    const unsubStores = (userContext.CID && userContext.permissions && userContext.permissions.includes("data"))
      ? db.companies.stores.listenAll(
        userContext.CID,
        (stores) => {setStores(stores); setIsLoading(false)},
      ) : undefined;
    return unsubStores;
  }, [userContext.CID, userContext.permissions]);

  // useLayoutEffect(() => fetchState(userContext.CID), [userContext.CID]);

// Do NOT expose state and setState directly in the provider value, ever
	return (
		<CompanyContext.Provider value={accessors}>
      {children}
		</CompanyContext.Provider>
	)
};

/**
 * Returns the actual value of the user context
 * @example
 * const company = useCompanyContext() // subscribe to the context
 * const CID = company.CID // use methods provided to interact with the context
 * @see CompanyProvider for the full users of methods
 */
const useCompanyContext = () => {
	const context = React.useContext(CompanyContext);
	if (context === undefined) {
		throw new Error('No company context!');
	}
	return context
};

// Do NOT export CompanyContext directly, ever
export {
	CompanyProvider,
	useCompanyContext
};