// React
import React, { useState, useEffect, useRef } from 'react';
import * as dfd from "danfojs";
import { BarItem, ResponsiveBar } from '@nivo/bar';
import { ResponsiveSankey } from '@nivo/sankey'
import { ResponsiveFunnel } from '@nivo/funnel';
// MUI
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
// import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
// import MoneyOffOutlinedIcon from '@mui/icons-material/MoneyOffOutlined';
// import RemoveShoppingCartOutlinedIcon from '@mui/icons-material/RemoveShoppingCartOutlined';
// import PhotoSizeSelectSmallOutlinedIcon from '@mui/icons-material/PhotoSizeSelectSmallOutlined';
// import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
// import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
// Interfaces
import { ViewInterface, ReactDateRangeInterface, ReactSelectInterface, NivoDataInterface, ItemInterface, NivoNetworkDataInterface, NivoSankeyDataInterface } from '../../../interfaces';
// AppConfig
import appConfig from "../appConfig";
// Contexts
// import { useDeviceContext } from '../../../contexts/device';
import { useI18nContext } from '../../../contexts/i18n';
import { useCompanyContext } from '../../../contexts/company';
import { useStoreContext } from '../../../contexts/store';
import { useDashboardContext } from '../../../contexts/dashboard';
// Components
import PeriodToolBar from "../components/PeriodToolBar";
import MultiSelect from '../../../components/MultiSelect';
import ToolBar from '../../../components/ToolBar';
import SinglePie from '../components/SinglePie';
import SimpleBar from '../components/SimpleBar';

// const paired = ["#a6cee3","#1f78b4","#b2df8a","#33a02c","#fb9a99","#e31a1c","#fdbf6f","#ff7f00","#cab2d6","#6a3d9a","#ffff99","#b15928"]
// const pair1 = ["#a6cee3","#b2df8a","#fb9a99","#fdbf6f","#cab2d6","#ffff99"];
// const pair2 = ["#1f78b4","#33a02c","#e31a1c","#ff7f00","#6a3d9a","#b15928"];
// const continuousGreens = ['#68be71', '#74c478', '#80c981', '#8bcf89', '#96d492', '#a1d99c', '#abdda5', '#b5e1ae', '#bee5b7', '#c6e9c0', '#cfecc8', '#d6efd0', '#ddf2d8', '#e4f4df', '#e9f7e5', '#eef9eb', '#f3faf0'];
const continuousGreens = ['#68be71', '#74c478', '#8bcf89', '#96d492', '#abdda5', '#b5e1ae', '#bee5b7', '#c6e9c0', '#cfecc8', '#d6efd0', '#ddf2d8', '#e4f4df', '#e9f7e5', '#eef9eb', '#f3faf0'];
// const greens = ["#4daf62","#73c378","#97d494","#b7e2b1","#d3eecd","#e8f6e3","#f7fcf5"];
// const continuousReds = ['#f75e44', '#f96a4c', '#fb7455', '#fb7f5f', '#fc8969', '#fc9374', '#fc9d7f', '#fca78b', '#fcb196', '#fcbba2', '#fdc5af', '#fdceba', '#fdd6c6', '#feded0', '#fee5d9', '#ffebe2', '#fff0e9'];
const continuousReds = ['#f75e44', '#f96a4c', '#fb7f5f', '#fc8969', '#fc9d7f', '#fca78b', '#fcb196', '#fcbba2', '#fdc5af', '#fdceba', '#fdd6c6', '#feded0', '#fee5d9', '#ffebe2', '#fff0e9'];
// const reds = ["#ef4533","#f9694c","#fc8a6b","#fcaa8e","#fdc9b4","#fee3d6","#fff5f0"];

const purchaseColors = ['#f75e44', ...continuousGreens];
// const labelLight = "#F4F4F8";
const labelDark = "#222222";
// const tier = ["#a6cee3","#b2df8a","#33a02c"]

const Intentions = (props: {dual?: boolean}) => {
  // const deviceContext = useDeviceContext();
  const i18n = useI18nContext();
  const companyContext = useCompanyContext();
  const storeContext = useStoreContext();
  const dashboardContext = useDashboardContext();
  
  const goalRef = useRef<HTMLElement>(null);
  const convRef = useRef<HTMLElement>(null);
  // const [viewDf, setViewDf] = useState(new dfd.DataFrame([]));

	const [period, setPeriod] = useState({} as ReactDateRangeInterface);
  const [periodDf, setPeriodDf] = useState(new dfd.DataFrame([]));
  const [filteredDf, setFilteredDf] = useState(new dfd.DataFrame([]));

  const optionsStore = companyContext.getStores().length > 0 ? companyContext.getStores() : [{label: storeContext.name, value: storeContext.CSID}];
  const [filtersStore, setFiltersStore] = useState(props.dual ? [] as ItemInterface[] : dashboardContext.currentCSIDs);
  const optionsSocio = dashboardContext.getOptions(periodDf, "profile/socio");
  const [filtersSocio, setFiltersSocio] = useState([] as ItemInterface[]);
  const optionsAge = dashboardContext.getOptions(periodDf, "profile/age");
  const [filtersAge, setFiltersAge] = useState([] as ItemInterface[]);
  const optionsFrom = dashboardContext.getOptions(periodDf, "profile/from");
  const [filtersFrom, setFiltersFrom] = useState([] as ItemInterface[]);
  const optionsFreq = dashboardContext.getOptions(periodDf, "profile/frequence");
  const [filtersFreq, setFiltersFreq] = useState([] as ItemInterface[]);
  const optionsVip = dashboardContext.getOptions(periodDf, "profile/vip");
  const [filtersVip, setFiltersVip] = useState([] as ItemInterface[]);
  const optionsRecc = dashboardContext.getOptions(periodDf, "journey/reccuring");
  const [filtersRecc, setFiltersRecc] = useState([] as ItemInterface[]);

  const optionsCategory = companyContext.getCategories();
  const [filtersCategory, setFiltersCategory] = useState([] as ItemInterface[]);
  const [filtersConvCategory, setFiltersConvCategory] = useState([] as ItemInterface[]);
  const optionsBrand = companyContext.getBrands();
  const [filtersBrand, setFiltersBrand] = useState([] as ItemInterface[]);
  const [filtersConvBrand, setFiltersConvBrand] = useState([] as ItemInterface[]);

  const optionsWhy = dashboardContext.getOptions(periodDf, "purchase/why").filter((o) => o.value !== "[lc]null");
  const [filtersWhy, setFiltersWhy] = useState([] as ItemInterface[]);

  const [sankeyData, setSankeyData] = useState({nodes: [], links: []} as NivoSankeyDataInterface);
  const [funnelData, setFunnelData] = useState([] as NivoDataInterface[]);

  const [intentionDf, setIntentionDf] = useState(new dfd.DataFrame([]));
  const [conversionDf, setConversionDf] = useState(new dfd.DataFrame([]));

  const [categoryData, setCategoryData] = useState([] as NivoDataInterface[]);
  const [brandData, setBrandData] = useState([] as NivoDataInterface[]);
  const [categoryOtherData, setCategoryOtherData] = useState([] as NivoDataInterface[]);
  const [brandOtherData, setBrandOtherData] = useState([] as NivoDataInterface[]);

  const [convCategoryData, setConvCategoryData] = useState([] as NivoDataInterface[]);
  const [convBrandData, setConvBrandData] = useState([] as NivoDataInterface[]);
  const [convCategory2Data, setConvCategory2Data] = useState([] as NivoDataInterface[]);
  const [convBrand2Data, setConvBrand2Data] = useState([] as NivoDataInterface[]);
  const [convWhyData, setConvWhyData] = useState([] as NivoDataInterface[]);
  const [convWhyFreeData, setConvWhyFreeData] = useState([] as NivoDataInterface[]);

  const updateFilters = (item: ItemInterface, filters: ItemInterface[]) => {
    const newFilters = [...filters];
    const oldIndex = newFilters.findIndex((i) => i.value === item.value);
    if (oldIndex === -1) {
      newFilters.push(item);
    } else {
      newFilters.splice(oldIndex, 1);
    }
    return newFilters;
  };

  const toPercent = (value: number, total: number, decimal: number) => {
    const ratio = value / total * 100;
    const factor = isNaN(Math.pow(10, decimal)) ? 1 : Math.pow(10, decimal);
    return Math.round(ratio * factor) / factor;
  };

  useEffect(() => {
    if (dashboardContext.fullDF?.axis?.columns?.includes("createdAt") && dashboardContext.fullDF?.axis?.columns?.includes("TFID") && dashboardContext.fullDF?.axis?.columns?.includes("purchase/nb")) {
      try {
        const viewDf = dashboardContext.getFilteredDf(
          (dashboardContext.fullDF.loc({columns: ["createdAt", "start", "TFID", "CSID", "purchase/nb", "purchase/product", "profile/socio", "profile/age", "profile/from", "profile/frequence", "profile/vip", "journey/reccuring", "intention/purchase", "intention/goal", "intention/category", "intention/brand", "intention/categoryfree", "intention/brandfree", "purchase/productC1", "purchase/productB1", "purchase/productC2", "purchase/productB2", "purchase/why", "purchase/whyfree"]}) || new dfd.DataFrame([]))
            .query({condition: dashboardContext.fullDF["start"].ge(period.startDate?.valueOf() || 1).and(dashboardContext.fullDF["start"].le(period.endDate?.valueOf() || Date.now()))}) || new dfd.DataFrame([]),
          [{column: "CSID", filters: filtersStore.map((i) => i.value)}]
        );
        setFiltersSocio([]);
        setFiltersAge([]);
        setFiltersFrom([]);
        setFiltersFreq([]);
        setFiltersVip([]);
        setFiltersRecc([]);
        setPeriodDf(viewDf);
        setFiltersCategory([]);
        setFiltersBrand([]);
        setFiltersConvCategory([]);
        setFiltersConvBrand([]);
        setFiltersWhy([]);
      } catch {
        setFiltersAge([]);
        setFiltersSocio([]);
        setFiltersFrom([]);
        setFiltersFreq([]);
        setFiltersVip([]);
        setFiltersRecc([]);
        setPeriodDf(new dfd.DataFrame([]));
        setFiltersCategory([]);
        setFiltersBrand([]);
        setFiltersConvCategory([]);
        setFiltersConvBrand([]);
        setFiltersWhy([]);
      }
    }
  }, [dashboardContext.fullDF, period, filtersStore]);

  useEffect(() => {
    if (periodDf?.axis?.columns?.length > 0) {
      setFilteredDf(dashboardContext.getFilteredDf(periodDf, [
        // {column: "CSID", filters: filtersStore.map((i) => i.value)},
        {column: "profile/socio", filters: filtersSocio.map((i) => i.value)},
        {column: "profile/age", filters: filtersAge.map((i) => i.value)},
        {column: "profile/from", filters: filtersFrom.map((i) => i.value)},
        {column: "profile/frequence", filters: filtersFreq.map((i) => i.value)},
        {column: "profile/vip", filters: filtersVip.map((i) => i.value)},
        {column: "journey/reccuring", filters: filtersRecc.map((i) => i.value)},
      ]));
    } else {
      setFilteredDf(new dfd.DataFrame([]));
    }
  }, [periodDf, filtersSocio, filtersAge, filtersFrom, filtersFreq, filtersVip, filtersRecc]);

// const pair1 = ["#a6cee3","#b2df8a","#fb9a99","#fdbf6f","#cab2d6","#ffff99"];
// const pair2 = ["#1f78b4","#33a02c","#e31a1c","#ff7f00","#6a3d9a","#b15928"];
  useEffect(() => {
    if (filteredDf?.axis?.columns?.length > 0) {
      setSankeyData({
        nodes: [
          {id: "visit", text: "Visites", value: filteredDf?.axis?.index?.length || 0, nodeColor: "#0f82c3"},
          {id: "intention", text: "Intention d'acheter", value: dashboardContext.getFilteredDf(filteredDf, [
            {column: "intention/purchase", filters: ["[lc]true"]},
          ])?.axis?.index?.length || 0, nodeColor: "#0f82c3"},
          {id: "intentionNot", text: "Pas d'intention d'acheter", value: dashboardContext.getFilteredDf(filteredDf, [
            {column: "intention/purchase", filters: ["[lc]false"]},
          ])?.axis?.index?.length || 0, nodeColor: "#9bd6f7"},
          {id: "intentionIdk", text: "Ne sais pas", value: dashboardContext.getFilteredDf(filteredDf, [
            {column: "intention/purchase", filters: ["[lc]idk"]},
          ])?.axis?.index?.length || 0, nodeColor: "#888888"},
          {id: "goal", text: "Produit en particulier", value: dashboardContext.getFilteredDf(filteredDf, [
            {column: "intention/goal", filters: ["[lc]true"]},
          ])?.axis?.index?.length || 0, nodeColor: "#0f82c3"},
          {id: "goalNot", text: "Rien en particulier", value: (dashboardContext.getFilteredDf(filteredDf, [
              {column: "intention/goal", filters: ["[lc]false"]},
            ])?.axis?.index?.length + dashboardContext.getFilteredDf(filteredDf, [
              {column: "intention/purchase", filters: ["[lc]false"]},
          ])?.axis?.index?.length) || 0, nodeColor: "#9bd6f7"},
          {id: "goalIdk", text: "Ne sais pas", value: (dashboardContext.getFilteredDf(filteredDf, [
              {column: "intention/goal", filters: ["[lc]idk"]},
            ])?.axis?.index?.length + dashboardContext.getFilteredDf(filteredDf, [
              {column: "intention/purchase", filters: ["[lc]idk"]},
          ])?.axis?.index?.length) || 0, nodeColor: "#888888"},
          {id: "conversion", text: "Achats", value: dashboardContext.getFilteredDf(filteredDf, [
            {column: "purchase/nb", filters: [0], notEqual: true},
          ])?.axis?.index?.length || 0, nodeColor: '#68be71'},
          {id: "conversionNot", text: "Non-Achats", value: dashboardContext.getFilteredDf(filteredDf, [
            {column: "purchase/nb", filters: [0]},
          ])?.axis?.index?.length || 0, nodeColor: '#f75e44'},
        ],
        links: [
          {source: "visit", target: "intention", value: dashboardContext.getFilteredDf(filteredDf, [
            {column: "intention/purchase", filters: ["[lc]true"]},
          ])?.axis?.index?.length || 0},
          {source: "visit", target: "intentionNot", value: dashboardContext.getFilteredDf(filteredDf, [
            {column: "intention/purchase", filters: ["[lc]false"]},
          ])?.axis?.index?.length || 0},
          {source: "visit", target: "intentionIdk", value: dashboardContext.getFilteredDf(filteredDf, [
            {column: "intention/purchase", filters: ["[lc]idk", "[lc]null"]},
          ])?.axis?.index?.length || 0},
          {source: "intention", target: "goal", value: dashboardContext.getFilteredDf(filteredDf, [
            {column: "intention/purchase", filters: ["[lc]true"]},
            {column: "intention/goal", filters: ["[lc]true"]},
          ])?.axis?.index?.length || 0},
          {source: "intention", target: "goalNot", value: dashboardContext.getFilteredDf(filteredDf, [
            {column: "intention/purchase", filters: ["[lc]true"]},
            {column: "intention/goal", filters: ["[lc]false"]},
          ])?.axis?.index?.length || 0},
          {source: "intention", target: "goalIdk", value: dashboardContext.getFilteredDf(filteredDf, [
            {column: "intention/purchase", filters: ["[lc]true"]},
            {column: "intention/goal", filters: ["[lc]idk"]},
          ])?.axis?.index?.length || 0},
          {source: "intentionNot", target: "goalNot", value: dashboardContext.getFilteredDf(filteredDf, [
            {column: "intention/purchase", filters: ["[lc]false"]},
          ])?.axis?.index?.length || 0},
          {source: "intentionIdk", target: "goalIdk", value: dashboardContext.getFilteredDf(filteredDf, [
            {column: "intention/purchase", filters: ["[lc]idk"]},
          ])?.axis?.index?.length || 0},
          {source: "goal", target: "conversion", value: dashboardContext.getFilteredDf(filteredDf, [
            {column: "intention/purchase", filters: ["[lc]true"]},
            {column: "intention/goal", filters: ["[lc]true"]},
            {column: "purchase/nb", filters: [0], notEqual: true},
          ])?.axis?.index?.length || 0},
          {source: "goal", target: "conversionNot", value: dashboardContext.getFilteredDf(filteredDf, [
            {column: "intention/purchase", filters: ["[lc]true"]},
            {column: "intention/goal", filters: ["[lc]true"]},
            {column: "purchase/nb", filters: [0]},
          ])?.axis?.index?.length || 0},
          {source: "goalNot", target: "conversion", value: (dashboardContext.getFilteredDf(filteredDf, [
              {column: "intention/goal", filters: ["[lc]false"]},
              {column: "purchase/nb", filters: [0], notEqual: true},
            ])?.axis?.index?.length + dashboardContext.getFilteredDf(filteredDf, [
              {column: "intention/purchase", filters: ["[lc]false"]},
              {column: "purchase/nb", filters: [0], notEqual: true},
            ])?.axis?.index?.length) || 0},
          {source: "goalNot", target: "conversionNot", value: (dashboardContext.getFilteredDf(filteredDf, [
              {column: "intention/goal", filters: ["[lc]false"]},
              {column: "purchase/nb", filters: [0]},
            ])?.axis?.index?.length + dashboardContext.getFilteredDf(filteredDf, [
              {column: "intention/purchase", filters: ["[lc]false"]},
              {column: "purchase/nb", filters: [0]},
          ])?.axis?.index?.length) || 0},
          {source: "goalIdk", target: "conversion", value: (dashboardContext.getFilteredDf(filteredDf, [
              {column: "intention/goal", filters: ["[lc]idk"]},
              {column: "purchase/nb", filters: [0], notEqual: true},
            ])?.axis?.index?.length + dashboardContext.getFilteredDf(filteredDf, [
              {column: "intention/purchase", filters: ["[lc]idk"]},
              {column: "purchase/nb", filters: [0], notEqual: true},
          ])?.axis?.index?.length) || 0},
          {source: "goalIdk", target: "conversionNot", value: (dashboardContext.getFilteredDf(filteredDf, [
              {column: "intention/goal", filters: ["[lc]idk"]},
              {column: "purchase/nb", filters: [0]},
            ])?.axis?.index?.length + dashboardContext.getFilteredDf(filteredDf, [
              {column: "intention/purchase", filters: ["[lc]idk"]},
              {column: "purchase/nb", filters: [0]},
          ])?.axis?.index?.length) || 0},
        ],
      });
      // setSankeyData({
      //   nodes: [
      //     {id: "visit", text: "Visites", value: filteredDf?.axis?.index?.length || 0, nodeColor: "#0f82c3"},
      //     {id: "intention", text: "Intention d'acheter", value: dashboardContext.getFilteredDf(filteredDf, [
      //       {column: "intention/purchase", filters: ["[lc]true"]},
      //     ])?.axis?.index?.length || 0, nodeColor: "#0f82c3"},
      //     {id: "intentionNot", text: "Pas d'intention d'acheter", value: dashboardContext.getFilteredDf(filteredDf, [
      //       {column: "intention/purchase", filters: ["[lc]true"], notEqual: true},
      //     ])?.axis?.index?.length || 0, nodeColor: "#9bd6f7"},
      //     {id: "goal", text: "Produit en particulier", value: dashboardContext.getFilteredDf(filteredDf, [
      //       {column: "intention/goal", filters: ["[lc]true"]},
      //     ])?.axis?.index?.length || 0, nodeColor: "#0f82c3"},
      //     {id: "goalNot", text: "Rien en particulier", value: dashboardContext.getFilteredDf(filteredDf, [
      //       {column: "intention/goal", filters: ["[lc]true"], notEqual: true},
      //     ])?.axis?.index?.length || 0, nodeColor: "#9bd6f7"},
      //     {id: "conversion", text: "Achats", value: dashboardContext.getFilteredDf(filteredDf, [
      //       {column: "purchase/nb", filters: [0], notEqual: true},
      //     ])?.axis?.index?.length || 0, nodeColor: '#68be71'},
      //     {id: "conversionNot", text: "Non-Achats", value: dashboardContext.getFilteredDf(filteredDf, [
      //       {column: "purchase/nb", filters: [0]},
      //     ])?.axis?.index?.length || 0, nodeColor: '#f75e44'},
      //   ],
      //   links: [
      //     {source: "visit", target: "intention", value: dashboardContext.getFilteredDf(filteredDf, [
      //       {column: "intention/purchase", filters: ["[lc]true"]},
      //     ])?.axis?.index?.length || 0},
      //     {source: "visit", target: "intentionNot", value: dashboardContext.getFilteredDf(filteredDf, [
      //       {column: "intention/purchase", filters: ["[lc]true"], notEqual: true},
      //     ])?.axis?.index?.length || 0},
      //     {source: "intention", target: "goal", value: dashboardContext.getFilteredDf(filteredDf, [
      //       {column: "intention/purchase", filters: ["[lc]true"]},
      //       {column: "intention/goal", filters: ["[lc]true"]},
      //     ])?.axis?.index?.length || 0},
      //     {source: "intention", target: "goalNot", value: dashboardContext.getFilteredDf(filteredDf, [
      //       {column: "intention/purchase", filters: ["[lc]true"]},
      //       {column: "intention/goal", filters: ["[lc]true"], notEqual: true},
      //     ])?.axis?.index?.length || 0},
      //     {source: "intentionNot", target: "goalNot", value: dashboardContext.getFilteredDf(filteredDf, [
      //       {column: "intention/purchase", filters: ["[lc]true"], notEqual: true},
      //     ])?.axis?.index?.length || 0},
      //     {source: "goal", target: "conversion", value: dashboardContext.getFilteredDf(filteredDf, [
      //       {column: "intention/purchase", filters: ["[lc]true"]},
      //       {column: "intention/goal", filters: ["[lc]true"]},
      //       {column: "purchase/nb", filters: [0], notEqual: true},
      //     ])?.axis?.index?.length || 0},
      //     {source: "goal", target: "conversionNot", value: dashboardContext.getFilteredDf(filteredDf, [
      //       {column: "intention/purchase", filters: ["[lc]true"]},
      //       {column: "intention/goal", filters: ["[lc]true"]},
      //       {column: "purchase/nb", filters: [0]},
      //     ])?.axis?.index?.length || 0},
      //     {source: "goalNot", target: "conversion", value: dashboardContext.getFilteredDf(filteredDf, [
      //       {column: "intention/goal", filters: ["[lc]true"], notEqual: true},
      //       {column: "purchase/nb", filters: [0], notEqual: true},
      //     ])?.axis?.index?.length || 0},
      //     {source: "goalNot", target: "conversionNot", value: dashboardContext.getFilteredDf(filteredDf, [
      //       {column: "intention/goal", filters: ["[lc]true"], notEqual: true},
      //       {column: "purchase/nb", filters: [0]},
      //     ])?.axis?.index?.length || 0},
      //   ],
      // });
    } else {
      setSankeyData({nodes: [], links: []});
    }
  }, [filteredDf])

  useEffect(() => {
    if (filteredDf?.axis?.columns?.length > 0) {
      setIntentionDf(dashboardContext.getFilteredDf(filteredDf, [
        {column: "intention/purchase", filters: ["[lc]true"]},
        {column: "intention/goal", filters: ["[lc]true"]},
        {column: "intention/category", filters: filtersCategory.map((i) => i.value)},
        {column: "intention/brand", filters: filtersBrand.map((i) => i.value)},
        {column: "purchase/productC1", filters: filtersConvCategory.map((i) => i.value)},
        {column: "purchase/productB1", filters: filtersConvBrand.map((i) => i.value)},
        {column: "purchase/why", filters: filtersWhy.map((i) => i.value)},
      ]));
      setConversionDf(dashboardContext.getFilteredDf(filteredDf, [
        {column: "intention/purchase", filters: ["[lc]true"]},
        {column: "intention/goal", filters: ["[lc]true"]},
        {column: "purchase/nb", filters: [0], notEqual: true},
        {column: "intention/category", filters: filtersCategory.map((i) => i.value)},
        {column: "intention/brand", filters: filtersBrand.map((i) => i.value)},
        {column: "purchase/productC1", filters: filtersConvCategory.map((i) => i.value)},
        {column: "purchase/productB1", filters: filtersConvBrand.map((i) => i.value)},
        {column: "purchase/why", filters: filtersWhy.map((i) => i.value)},
      ]))
    } else {
      setIntentionDf(new dfd.DataFrame([]));
      setConversionDf(new dfd.DataFrame([]));
    }
  }, [filteredDf, filtersCategory, filtersBrand, filtersConvCategory, filtersConvBrand, filtersWhy]);

  useEffect(() => {
    if (filteredDf?.axis?.columns?.length > 0) {
      // const nodes = [
      //   {id: "visits", label: "Visites"},
      //   {id: "purchase", label: "Intention d'acheter"},
      //   {id: "goal", label: "Produit en particulier"},
      //   {id: "conversion", label: "Achats"},
      // ]
      setFunnelData([
        {id: "visits", label: "Visites", value: filteredDf?.axis?.index?.length || 0},
        {id: "purchase", label: "Intention d'acheter", value: dashboardContext.getFilteredDf(filteredDf, [
          {column: "intention/purchase", filters: ["[lc]true"]},
        ])?.axis?.index?.length || 0},
        {id: "goal", label: "Produit en particulier", value: intentionDf?.axis?.index?.length || 0},
        {id: "conversion", label: "Achats", value: conversionDf?.axis?.index?.length || 0},
      ] as NivoDataInterface[]);
    } else {
      setFunnelData([]);
    }
  }, [filteredDf, intentionDf, conversionDf])

  useEffect(() => {
    if (intentionDf?.axis?.columns?.length > 0) {
      const categories = companyContext.getCategories().concat([{label: "Autre", value: "[lc]other"},{label: "Je ne sais pas", value: "[lc]idk"},{label: "Aucun produit", value: "[lc]null"}]);
      const brands = companyContext.getBrands().concat([{label: "Autre", value: "[lc]other"},{label: "Je ne sais pas", value: "[lc]idk"},{label: "Aucun produit", value: "[lc]null"}]);
      setCategoryData(dashboardContext.getNivoItems(intentionDf, "intention/category").map((i) => {return {...i, label: categories.find((c) => c.value === i.id)?.label || ""}}));
      setBrandData(dashboardContext.getNivoItems(intentionDf, "intention/brand").map((i) => {return {...i, label: brands.find((b) => b.value === i.id)?.label || ""}}));
      setCategoryOtherData(dashboardContext.getNivoItems(dashboardContext.getFilteredDf(intentionDf, [
        {column: "intention/categoryfree", filters: ["[lc]null"], notEqual: true},
      ]), "intention/categoryfree"));
      setBrandOtherData(dashboardContext.getNivoItems(dashboardContext.getFilteredDf(intentionDf, [
        {column: "intention/brandfree", filters: ["[lc]null"], notEqual: true},
      ]), "intention/brandfree"));
    } else {
      setCategoryData([]);
      setBrandData([]);
      setCategoryOtherData([]);
      setBrandOtherData([]);
    }
  }, [intentionDf]);

  useEffect(() => {
    if (conversionDf?.axis?.columns?.length > 0) {
      // conversionDf.print();
      const categories = companyContext.getCategories().concat([{label: "Autre", value: "[lc]other"},{label: "Je ne sais pas", value: "[lc]idk"},{label: "Aucun produit", value: "[lc]null"}]);
      const brands = companyContext.getBrands().concat([{label: "Autre", value: "[lc]other"},{label: "Je ne sais pas", value: "[lc]idk"},{label: "Aucun produit", value: "[lc]null"}]);
      setConvCategoryData(dashboardContext.getNivoItems(conversionDf, "purchase/productC1").map((i) => {return {...i, label: categories.find((c) => c.value === i.id)?.label || ""}}));
      setConvBrandData(dashboardContext.getNivoItems(conversionDf, "purchase/productB1").map((i) => {return {...i, label: brands.find((b) => b.value === i.id)?.label || ""}}));
      setConvCategory2Data(dashboardContext.getNivoItems(dashboardContext.getFilteredDf(conversionDf, [
        {column: "purchase/productC2", filters: ["[lc]null"], notEqual: true},
      ]), "purchase/productC2").map((i) => {return {...i, label: categories.find((c) => c.value === i.id)?.label || ""}}));
      setConvBrand2Data(dashboardContext.getNivoItems(dashboardContext.getFilteredDf(conversionDf, [
        {column: "purchase/productB2", filters: ["[lc]null"], notEqual: true},
      ]), "purchase/productB2").map((i) => {return {...i, label: brands.find((b) => b.value === i.id)?.label || ""}}));
      // setConvWhyData(dashboardContext.getNivoItems(conversionDf, "purchase/why").reverse());
      // setConvWhyFreeData(dashboardContext.getNivoItems(conversionDf, "purchase/whyfree").filter((i) => i.id !== "[lc]null").reverse());
      setConvWhyData(dashboardContext.getNivoItems(conversionDf, "purchase/why").filter((i) => i.id !== "[lc]other").reverse());
      setConvWhyFreeData(dashboardContext.getNivoItems(conversionDf, "purchase/whyfree").filter((i) => i.id !== "[lc]null").reverse());
    } else {
      setConvCategoryData([]);
      setConvBrandData([]);
      setConvCategoryData([]);
      setConvBrandData([]);
      setConvCategory2Data([]);
      setConvBrand2Data([]);
      setConvWhyData([]);
      setConvWhyFreeData([]);
    }
  }, [conversionDf]);

	return (<>
    {/* <h2 style={{width: "100%", textAlign: "center"}}>{i18n.t(appConfig.AID + ".views." + IntentionsView.AVID + ".name", {defaultValue: ""})}</h2> */}
		<PeriodToolBar
			period={period}
			setPeriod={setPeriod}
		/>
    <ToolBar
      title={i18n.t("shared.filters")}
    ><>
      <MultiSelect
        id="filterStore"
        title="Boutiques"
        placeholder="Toutes"
        maxGrow="100%"
        options={optionsStore}
        selected={filtersStore as ReactSelectInterface[]}
        // setSelected={(s) => dashboardContext.setCurrentCSIDs(s)}
        // selected={filtersStore as ReactSelectInterface[]}
        // setSelected={(s) => setFiltersStore(s)}
        setSelected={(s) => {
          setFiltersStore(s);
          !props.dual && dashboardContext.setCurrentCSIDs(s);
        }}
      />
      <MultiSelect
        id="filterSocio"
        title="Sexes"
        placeholder="Tous"
        maxGrow="33%"
        options={optionsSocio}
        selected={filtersSocio as ReactSelectInterface[]}
        setSelected={(s) => setFiltersSocio(s)}
      />
      <MultiSelect
        id="filterAge"
        title="Ages"
        placeholder="Tous"
        maxGrow="33%"
        options={optionsAge}
        selected={filtersAge as ReactSelectInterface[]}
        setSelected={(s) => setFiltersAge(s)}
      />
      <MultiSelect
        id="filterFrom"
        title="Pays"
        placeholder="Tous"
        maxGrow="33%"
        options={optionsFrom}
        selected={filtersFrom as ReactSelectInterface[]}
        setSelected={(s) => setFiltersFrom(s)}
      />
      <MultiSelect
        id="filterFreq"
        title="Fréquentations"
        placeholder="Toutes"
        maxGrow="33%"
        options={optionsFreq}
        selected={filtersFreq as ReactSelectInterface[]}
        setSelected={(s) => setFiltersFreq(s)}
      />
      <MultiSelect
        id="filterVip"
        title="Membres Printania"
        placeholder="Tous"
        maxGrow="33%"
        options={optionsVip}
        selected={filtersVip as ReactSelectInterface[]}
        setSelected={(s) => setFiltersVip(s)}
      />
      <MultiSelect
        id="filterRecc"
        title="Clients récurrents"
        placeholder="Tous"
        maxGrow="33%"
        options={optionsRecc}
        selected={filtersRecc as ReactSelectInterface[]}
        setSelected={(s) => setFiltersRecc(s)}
      />
    </></ToolBar>
    <section className="lc-section" style={{minHeight: "unset"}}>
      <div style={{boxSizing: "border-box", width: "100%", minWidth: "280px", padding: "8px", display: "flex", flexDirection: "column", alignItems: "center"}}>
        <div style={{boxSizing: "border-box", minWidth: "280px", maxWidth: "840px", width: "100%", height: "280px"}}>
          {(sankeyData?.nodes?.length > 1 && sankeyData?.links?.length > 0) && <ResponsiveSankey
            data={sankeyData}
            // label={(item) => item.formattedValue}
            // layout="vertical"
            label={(i) => i.text}
            nodeSpacing={0}
            nodeBorderRadius={4}
            nodeTooltip={({node}) => <div
              style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "4px 8px", borderRadius: "4px", color: "var(--dark)", backgroundColor: "var(--light)", opacity: 0.9}}
            >
              <small style={{fontWeight: "bold"}}>{node.text}</small>
              <small>{node.value}</small>
            </div>}
            linkTooltip={({link}) => <div
              style={{width: "260px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "4px 8px", borderRadius: "4px", color: "var(--dark)", backgroundColor: "var(--light)", opacity: 0.9}}
            >
              <div style={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <small style={{flex: "1 0 40%", fontWeight: "bold", textAlign: "left"}}>{link.source.text}</small>
                <small style={{flex: "1 0 20%", fontWeight: "bold", textAlign: "center"}}>{"x"}</small>
                <small style={{flex: "1 0 40%", fontWeight: "bold", textAlign: "right"}}>{link.target.text}</small>
              </div>
              <div style={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <small style={{flex: "1 0 30%", textAlign: "left"}}>{toPercent(link.value, link.source.value, 1) + "%"}</small>
                <small style={{flex: "1 0 10%"}}>{">"}</small>
                <small style={{flex: "1 0 20%", fontWeight: "bold", textAlign: "center"}}>{link.value}</small>
                <small style={{flex: "1 0 10%"}}>{">"}</small>
                <small style={{flex: "1 0 30%", textAlign: "right"}}>{toPercent(link.value, link.target.value, 1) + "%"}</small>
              </div>
            </div>}
            linkOpacity={0.4}
            enableLinkGradient={true}
            isInteractive={true}
            onClick={(item) => console.log(item)}
            colors={(i) => i.nodeColor || "#444444"}
          />}
        </div>
      </div>
    </section>
    {/* <section className="lc-section" style={{minHeight: "unset"}}>
      <div style={{boxSizing: "border-box", width: "100%", minWidth: "280px", padding: "8px", display: "flex", flexDirection: "column", alignItems: "center"}}>
        <div style={{boxSizing: "border-box", minWidth: "280px", maxWidth: "840px", width: "100%", height: "100px"}}>
          <ResponsiveFunnel
            data={funnelData.length > 0 ? funnelData : [{id: "nothing", label: "Données manquantes", value: 0, total: 404, percent: 0}]}
            onClick={(item) => item.data.id === "goal"
              ? goalRef?.current?.scrollIntoView({behavior: "smooth"})
              : item.data.id === "conversion"
                ? convRef?.current?.scrollIntoView({behavior: "smooth"})
                : undefined}
            direction="horizontal"
            enableLabel={true}
            labelColor={labelDark}
            shapeBlending={0.22}
            borderWidth={8}
            isInteractive={true}
            animate={true}
            currentBorderWidth={16}
            colors={['#bee5b7', '#abdda5', '#8bcf89', '#68be71']}
          />
        </div>
        <div style={{boxSizing: "border-box", minWidth: "280px", maxWidth: "840px", width: "100%", display: "flex", justifyContent: "center", paddingTop: "4px"}}>
          <small style={{flex: "1 0 25%", textAlign: "center"}}>{"Visites"}</small>
          <small style={{flex: "1 0 25%", textAlign: "center"}}>{"Intentions d'achat"}</small>
          <small style={{flex: "1 0 25%", textAlign: "center"}}>{"Cherchaient un produit en particulier"}</small>
          <small style={{flex: "1 0 25%", textAlign: "center"}}>{"Conversions"}</small>
        </div>
      </div>
    </section> */}
    <section ref={goalRef} className="lc-section" style={{minHeight: "unset"}}>
      {/* <hr style={{minWidth: "280px", width: "88%"}} /> */}
			<h4 style={{width: "100%", textAlign: "center"}}>
        {(funnelData.length > 2 ? funnelData[2].value : 0) + " produit(s) recherché(s)"}
			</h4>
      {(funnelData.length > 2 && funnelData[2].value > 0) && <><div style={{width: "100%", display: "flex", flexWrap: "wrap"}}>
        <MultiSelect
          id="filterCategory"
          title="Catégories"
          placeholder="Toutes"
          maxGrow="50%"
          options={optionsCategory}
          selected={filtersCategory as ReactSelectInterface[]}
          setSelected={(s) => setFiltersCategory(s)}
        />
        <MultiSelect
          id="filterBrand"
          title="Marques"
          placeholder="Toutes"
          maxGrow="50%"
          options={optionsBrand}
          selected={filtersBrand as ReactSelectInterface[]}
          setSelected={(s) => setFiltersBrand(s)}
        />
      </div>
      <SinglePie
        title={i18n.t("shared.companyDict.categories", {case: "first"})}
        data={categoryData}
        onClick={(item) => setFiltersCategory(updateFilters(item, filtersCategory))}
        // flex="1 0 50%"
      />
      <SinglePie
        title={i18n.t("shared.companyDict.brands", {case: "first"})}
        data={brandData}
        onClick={(item) => setFiltersBrand(updateFilters(item, filtersBrand))}
        colors={{scheme: "set3"}}
        // flex="1 0 50%"
      />
      <hr style={{minWidth: "280px", width: "88%"}} />
			<h5 style={{width: "100%", textAlign: "center", marginBottom: "0.4rem"}}>
        {(categoryOtherData.length > 0 || brandOtherData.length > 0) ? "Réponses libres / Autre" : "Aucune réponse libre"}
			</h5>
      {(categoryOtherData.length > 0 || brandOtherData.length > 0) && <><SinglePie
        title={i18n.t("shared.companyDict.categories", {case: "first"})}
        data={categoryOtherData}
      />
      <SinglePie
        title={i18n.t("shared.companyDict.brands", {case: "first"})}
        data={brandOtherData}
        colors={{scheme: "set3"}}
      /></>}</>}
    </section>
    <section ref={convRef} className="lc-section" style={{minHeight: "unset"}}>
      {/* <hr style={{minWidth: "280px", width: "88%"}} /> */}
			<h4 style={{width: "100%", textAlign: "center"}}>
        {(funnelData.length > 3 ? funnelData[3].value : 0) + " conversion(s)"}
        {/* {"Produits achetés par ces " + (funnelData.length > 2 ? funnelData[3].value : 0) + " clients"} */}
			</h4>
      {(funnelData.length > 3 && funnelData[3].value > 0) && <>
        <div style={{width: "100%", display: "flex", flexWrap: "wrap"}}>
          <MultiSelect
            id="filterCategory"
            title="Catégories"
            placeholder="Toutes"
            maxGrow="50%"
            options={optionsCategory}
            selected={filtersConvCategory as ReactSelectInterface[]}
            setSelected={(s) => setFiltersConvCategory(s)}
          />
          <MultiSelect
            id="filterBrand"
            title="Marques"
            placeholder="Toutes"
            maxGrow="50%"
            options={optionsBrand}
            selected={filtersConvBrand as ReactSelectInterface[]}
            setSelected={(s) => setFiltersConvBrand(s)}
          />
        </div>
        <h5 style={{width: "100%", textAlign: "center", marginBottom: "0.4rem"}}>
          {"Produit principal acheté (" + (convCategoryData.length > 0 ? convCategoryData[0].total : 0) + ")"}
        </h5>
        <SinglePie
          title={i18n.t("shared.companyDict.categories", {case: "first"})}
          data={convCategoryData}
          onClick={(item) => setFiltersConvCategory(updateFilters(item, filtersConvCategory))}
        />
        <SinglePie
          title={i18n.t("shared.companyDict.brands", {case: "first"})}
          data={convBrandData}
          colors={{scheme: "set3"}}
          onClick={(item) => setFiltersConvBrand(updateFilters(item, filtersConvBrand))}
        />
        <hr style={{minWidth: "280px", width: "88%"}} />
        <h5 style={{width: "100%", textAlign: "center", marginBottom: "0.4rem"}}>
          {"Produit secondaire acheté (" + (convCategory2Data.length > 0 ? convCategory2Data[0].total : 0) + ")"}
        </h5>
        {(convCategory2Data.length > 0 || convBrand2Data.length > 0) && <>
          <SinglePie
            title={i18n.t("shared.companyDict.categories", {case: "first"})}
            data={convCategory2Data}
            // onClick={(item) => setFiltersConvCategory(updateFilters(item, filtersConvCategory))}
          />
          <SinglePie
            title={i18n.t("shared.companyDict.brands", {case: "first"})}
            data={convBrand2Data}
            colors={{scheme: "set3"}}
            // onClick={(item) => setFiltersConvBrand(updateFilters(item, filtersConvBrand))}
          />
        </>}
        <hr style={{minWidth: "280px", width: "88%"}} />
        <h5 style={{width: "100%", textAlign: "center", marginBottom: "0.4rem"}}>
          {"Raisons d'achat"}
        </h5>
        <div style={{width: "100%", display: "flex", flexWrap: "wrap"}}>
          <MultiSelect
            id="filterWhy"
            // title="Catégories"
            placeholder="Toutes"
            maxGrow="50%"
            options={optionsWhy}
            selected={filtersWhy as ReactSelectInterface[]}
            setSelected={(s) => setFiltersWhy(s)}
          />
        </div>
        <SimpleBar
          data={convWhyData}
          onClick={(item) => setFiltersWhy(updateFilters(item, filtersWhy))}
        />
        {(convWhyFreeData.length > 0) && <>
          <h5 style={{width: "100%", textAlign: "center", marginBottom: "0.4rem"}}>
            {"Autres raisons"}
          </h5>
          <SimpleBar
            data={convWhyFreeData}
          />
        </>}
      </>}
    </section>
	</>)
}

const IntentionsView : ViewInterface = {
  AVID: "intentions",
  component: (props?: {dual?: boolean}) => <Intentions dual={!!props?.dual} />,
  url: "/intentions",
  icon: <AccountCircleIcon />
}

export default IntentionsView;