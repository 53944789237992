
import i18next from "i18next";
import l10n from "./l10n";
// Fluent
// import { FluentBundle, FluentResource } from '@fluent/bundle';
import { I18nLng } from "livecrew-interfaces";

i18next.init({
  lng: "en-UK",
  debug: true,
  resources: l10n
})

const getAvailableLocales = () => Object.keys(l10n) as I18nLng[];

const updateLocale = (locale: string, callback: () => void) => i18next.changeLanguage(locale, callback);

const translate = (keys: string | string[], options?: {
  defaultValue?: string,
}) => i18next.t(keys, {
  ...options,
  fallbackLng: "en-UK"
});

// const i18n = {
//   getAvailableLocales: () => Object.keys(l10n),
//   getBundle: (locale) => {
//     if (Object.keys(l10n).includes(locale)) {
//       const bundle = new FluentBundle(locale);
//       l10n[locale].forEach((ressource) => bundle.addResource(new FluentResource(ressource)));
//       return bundle;
//     } else {
//       const bundle = new FluentBundle('en-UK');
//       l10n['en-UK'].forEach((ressource) => bundle.addResource(new FluentResource(ressource)));
//       return bundle;
//     }
//   },
// };
const i18n = {
  getAvailableLocales,
  updateLocale,
  translate
}

export default i18n;