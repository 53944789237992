import { getDocRef, getDoc, onDocUpdate } from "../../firestore";
import { ConfigAdminInterface, configAdminPath, CID } from "livecrew-interfaces";

const readAdmin = (CID: CID, onSuccess: (configAdmin: ConfigAdminInterface) => void, onError: (errorCode: string) => void) => getDoc(getDocRef(configAdminPath(CID)))
  .then((docSnap) => {
    if (docSnap.exists()) {
      onSuccess(docSnap.data() as ConfigAdminInterface);
    } else {
      onError("db.doc.01");
    }
  })
  .catch((error) => {
    console.log(error);
    onError(error.code);
  });

const listenAdmin = (CID: CID, onChange: (configAdmin: ConfigAdminInterface) => void) => onDocUpdate(
  getDocRef(configAdminPath(CID)),
  (docSnap) => onChange(docSnap.data() as ConfigAdminInterface),
  (error) => onChange({} as ConfigAdminInterface),
)
// const onAdminChange = (CID: CID, onChange: (configAdmin: ConfigAdminInterface) => void) => onDocUpdate(
//   getDocRef(configAdminPath(CID)),
//   onChange
// )

const configs = {
  readAdmin,
  listenAdmin,
}

export default configs;