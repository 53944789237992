// React
import React from 'react';
// Interface
import { AppInterface } from "../../interfaces";
// Config
import appConfig from "./appConfig";
// Wrapper
import AppWrapper from "./AppWrapper";
// CSS
import "./index.css";
// Views
import MyProfileView from './views/MyProfile';
import MyCompanyView from './views/MyCompany';
import LogoutView from './views/Logout';
import CreateCompanyView from './views/CreateCompany';
import JoinCompanyView from './views/JoinCompany';

const user : AppInterface = {
  ...appConfig,
  component: (view) => <AppWrapper view={view || MyProfileView} />,
  views: [
   MyProfileView,
	 MyCompanyView,
   LogoutView,
   CreateCompanyView,
   JoinCompanyView,
  ],
};

export default user;