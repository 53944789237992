// React
import React from 'react';
import Select from "react-select";
// CSS
// import "./index.css";
// Interfaces
import { DataValueType } from 'livecrew-interfaces';
import { ReactSelectInterface } from '../../interfaces';

export default function SingleSelect(
  {id, disabled, placeholder, maxGrow, options, selected, setSelected}:
  {id: string, disabled?: boolean, placeholder: string, maxGrow?: string, options: ReactSelectInterface[], selected?: DataValueType, setSelected: (selected: ReactSelectInterface) => void}
) {

	return (<div style={{minWidth: "180px", margin: "4px", flex: maxGrow ? "1 0 calc("+maxGrow+" - 10px)" : "1 0 calc(100% - 10px)"}}>
    <Select
      value={selected ? options.find((o) => o.value === selected) || "" : ""}
      name={id}
      isDisabled={disabled}
      placeholder={placeholder}
      options={options}
      onChange={(v) => setSelected(v as {value: string, label: string})}
      closeMenuOnSelect={true}
      className="basic-multi-select"
      classNamePrefix="lc-select"
      styles={{
        container: (styles, { isDisabled, isFocused }) => ({
          ...styles,
          fontSize: "calc(0.6rem + 1vmin)",
          // boxSizing: "border-box",
          // width: "100%",
          // width: width ? width : "auto",
          // minWidth: "200px",
          // maxWidth: "400px",
          // maxWidth: maxWidth ? "calc("+maxWidth+" - 16px)" : "unset",
          // width: "100%",
          // flex: "1 0 calc(50% - 16px)",
          // flex: "1 0 100%",
          // flex: maxGrow ? "1 0 calc("+maxGrow+" - 16px)" : "1 0 calc(100% - 16px)",
          // flex: flex ? flex + " 0 calc(50% - 16px)" : "1 0 calc(50% - 16px)",
          // flex: "1 0 calc(50% - 16px)",
          // height: "100%",
          // margin: "8px",
        }),
        menu: (styles) => ({
          ...styles,
          // boxSizing: "border-box",
          width: "100%",
          margin: "2px 0px",
        }),
        control: (styles, { isDisabled, isFocused }) => ({
          ...styles,
          color: "var(--dark)",
          backgroundColor: isDisabled ? "var(--interactive88)" : "var(--light)",
          borderColor: isFocused
            ? "var(--interaction)"
            : "var(--interactive)",
          cursor: "pointer",
          height: "100%",
          maxWidth: "100%",
        }),
        input: (styles, { isDisabled }) => ({
          ...styles,
          backgroundColor: "#00000000",
          "> *": {
            boxShadow: "none",
          }
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
          ...styles,
          backgroundColor: isFocused
            ? "var(--interaction)"
            : "var(--light)",
          cursor: "pointer",
        }),
        dropdownIndicator : (styles, { isFocused }) => ({
          ...styles,
          color: "var(--interactive)",
          backgroundColor: "#00000000",
          "&:hover": {
            color: "var(--interaction)"
          }
        }),
        clearIndicator: (styles, { isFocused }) => ({
          ...styles,
          color: "var(--interactive)",
          backgroundColor: "#00000000",
          "&:hover": {
            color: "var(--interaction)"
          }
        }),
      }}
    />
	</div>)
}