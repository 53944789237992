// React
import React from 'react';
import { Link } from 'react-router-dom';
// MUI
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
// Interfaces
import { ViewInterface } from '../interfaces';
// Contexts
import { useAnalyticsContext, useTrackPageView } from '../contexts/analytics';
import { useI18nContext } from '../contexts/i18n';
// import { useUserContext } from '../contexts/user';
// Components
import Widget from '../components/Widget';
import ToogleSwitch from '../components/ToggleSwitch';
import TosView from './ToS';
import PPView from './PP';
import DPAView from './DPA';

const Cookies = () => {
  const analytics = useAnalyticsContext();
	const i18n = useI18nContext();

	useTrackPageView("legal", "cookies");

	return (<div className="lc-app">
		<Widget
      title={i18n.t("legal.views." + CookiesView.AVID + ".name")}
			isFocused={true}
      maxHeight="unset"
      maxWidth="800px"
			width="100%"
		><>
      <hr style={{width: "calc(100% - 16px)"}} />
      <div style={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 4px"}}>
        <h5 style={{padding: "4px"}}>Mesures nécessaires à l'administration du site</h5>
        <ToogleSwitch
          id="optOut"
          checked={!analytics.optOut}
          onChange={(s) => analytics.setOptOut(!s)}
        />
      </div>
      <ul style={{width: "100%", paddingRight: "12px"}}>
        <li>Ces mesures sont effectuées dans le respect du RGPD, et en accord avec les <a href="https://www.cnil.fr/fr/cookies-solutions-pour-les-outils-de-mesure-daudience" target="_blank" rel="noopener noreferrer" style={{color: "var(--interactive)", whiteSpace: "nowrap"}}>règles d'exemption de consentement de la CNIL<LaunchRoundedIcon fontSize="inherit" style={{paddingLeft: "4px", transform: "translateY(3px)"}} /></a>.</li>
        <li>Elles sont anonymisées, ne nécessitent pas de cookie, et sont utilisées dans le seul et unique but d'améliorer les solutions proposées par LiveCrew.</li>
        <li>Vous pouvez désactiver cette fonctionnalité si vous le souhaitez en utilisant le bouton ci-dessus.</li>
      </ul>
      <hr style={{width: "calc(100% - 16px)"}} />
      <small style={{padding: "0 8px", textAlign: "center"}}>Pour toujours plus de respect de votre vie privée et de vos choix, nos solutions respectent l'option <a href="https://allaboutdnt.com/" target="_blank" rel="noopener noreferrer"style={{color: "var(--interactive)", whiteSpace: "nowrap"}}>Do Not Track<LaunchRoundedIcon fontSize="inherit" style={{paddingLeft: "4px", transform: "translateY(3px)"}} /></a> de votre navigateur.</small>
    </></Widget>
		<Widget
      title={"Mentions légales"}
			isFocused={true}
      maxHeight="unset"
      maxWidth="800px"
			width="100%"
		><>
      
      <hr style={{width: "calc(100% - 16px)"}} />
      <h5 style={{width: "100%", padding: "4px 8px"}}>LiveCrew</h5>
      <small style={{width: "100%", padding: "2px 12px"}}>LiveCrew aide les marques et les détaillants à mieux servir leurs clients, en analysant les besoins qu'ils expriment.</small>
      {/* LiveCrew helps brands and retailers to better serve their clients, by analysing the needs they express. */}
      {/* <small style={{width: "100%", padding: "2px 12px"}}><b style={{color: "red"}}>[...] Blabla on aime nos utilisateurs, la sécurité, la transparence, xoxo [...]</b></small> */}
      <small style={{width: "100%", padding: "2px 12px"}}>Vous trouverez nos mentions légales ci-dessous :</small>
      <ul style={{width: "100%", paddingRight: "12px"}}>
        {/* <li><Link
          key={"link-" + TosView.AVID}
          to={TosView.url}
          style={{display: "flex", alignItems: "center", color: "var(--interactive)"}}
        ><small style={{color: "inherit"}}>{i18n.t("legal.views." + TosView.AVID + ".name")}</small></Link></li> */}
        {/* <li><Link
          key={"link-" + PPView.AVID}
          to={PPView.url}
          style={{display: "flex", alignItems: "center", color: "var(--interactive)"}}
        ><small style={{color: "inherit"}}>{i18n.t("legal.views." + PPView.AVID + ".name")}</small></Link></li> */}
        <li><small style={{color: "inherit"}}>{i18n.t("legal.views." + PPView.AVID + ".name") + " (à venir)"}</small></li>
        <li><Link
          key={"link-" + DPAView.AVID}
          to={DPAView.url}
          style={{display: "flex", alignItems: "center", color: "var(--interactive)"}}
        ><small style={{color: "inherit"}}>{i18n.t("legal.views." + DPAView.AVID + ".name")}</small></Link></li>
      </ul>
      <hr style={{width: "calc(100% - 16px)"}} />
      <h5 style={{width: "100%", padding: "4px 8px"}}>Mesures d'audience</h5>
      <small style={{width: "100%", padding: "2px 12px"}}>Afin d'améliorer continuellement notre plateforme tout en respectant au mieux la vie privée de nos visiteurs, nous faisons appel à une solution de mesures et d'analyse d'audience respectueuse du RGPD : Matomo Cloud.</small>
      <small style={{width: "100%", padding: "2px 12px"}}>Nous restons pleinement propriétaire de ces données, et notre sous-traitant s'engage à ne pas les utiliser à ses fins, ni les déplacer hors de l'UE.</small>
      <small style={{width: "100%", padding: "2px 12px"}}>Vous trouverez leurs mentions légales ci-dessous :</small>
      <ul style={{width: "100%", paddingRight: "12px"}}>
        <li><a
          key={"link-matomo-tos"}
          style={{color: "var(--interactive)", whiteSpace: "nowrap"}}
          href="https://matomo.org/matomo-cloud-terms-of-service/"
          target="_blank" rel="noopener noreferrer"
        >
          {"Matomo Cloud - " + i18n.t("legal.views." + TosView.AVID + ".name")}
          <LaunchRoundedIcon fontSize="inherit" style={{paddingLeft: "4px", transform: "translateY(3px)"}} />
        </a></li>
        <li><a
          key={"link-matomo-dpa"}
          style={{color: "var(--interactive)", whiteSpace: "nowrap"}}
          href="https://matomo.org/matomo-cloud-dpa/"
          target="_blank" rel="noopener noreferrer"
        >
          {"Matomo Cloud - " + i18n.t("legal.views." + DPAView.AVID + ".name")}
          <LaunchRoundedIcon fontSize="inherit" style={{paddingLeft: "4px", transform: "translateY(3px)"}} />
        </a></li>
      </ul>
    </></Widget>
	</div>)
}

const CookiesView : ViewInterface = {
  AVID: "cookies",
  component: (props?: {dual?: boolean}) => <Cookies />,
  url: "/legal",
  icon: <AccountCircleIcon />,
}

export default CookiesView;