// MUI
// import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
// import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';

const appConfig = {
  AID: "crewapp",
  version: "0.1.0",
  url: "/crewapp",
  color: "#F0F4F0",
  // color: "var(--light)",
  colorDark: "#408040",
  icon: <ListAltTwoToneIcon />,
};

export default appConfig;


// "CI": {
//   "light": "#9BECDD",
//   "dark": "#126455"
// },
// "SV": {
//   "light": "#FEE2A7",
//   "dark": "#836423"
// },
// "BB": {
//   "light": "#FF83A0",
//   "dark": "#8A1C35"
// },
// "default": {
//   "light": "white",
//   "dark": "black"
// }