// Apps
import website from "./website";
import guest from "./guest";
import user from "./user";
import admin from "./admin";
import crewapp from "./crewapp";
import dashboard from "./dashboard";

// export const restrictedApps[] = [crewapp, dashboard, admin];
export const websiteApp = website;
export const guestApp = guest;
export const userApp = user;
// export const restrictedApps = [dashboard];
export const restrictedApps = [admin, crewapp, dashboard];