import { serverTimestamp, query, where, onDocUpdate, getColRef, getDocRef, getDocs, getDoc, setDoc, doc } from "../../../firestore";
import { SurveyFeedbackInterface, surveyFeedbacksColPath, surveyFeedbackDocPath, CID, CSID, TID, TFID, UID } from "livecrew-interfaces";

const listenAll = (CID: CID, CSID: CSID, TID: TID, onChange: (feedbacks: SurveyFeedbackInterface[]) => void) => onDocUpdate(
  query(getColRef(surveyFeedbacksColPath(CID, TID))
  , where("CSID", "==", CSID)
  , where("createdAt", "!=", new Date().toISOString().split('T')[0])
  ),
  (querySnap) => {
    const feedbacks = [] as SurveyFeedbackInterface[];
    querySnap.forEach((doc) => feedbacks.push({...doc.data(), TFID: doc.id} as SurveyFeedbackInterface));
    onChange(feedbacks);
  },
  (error) => onChange([]),
);

const read = (CID: CID, TID: TID, TFID: TFID, onSuccess: (feedback: SurveyFeedbackInterface) => void, onError: (errorCode: string) => void) => getDoc(getDocRef(surveyFeedbackDocPath(CID, TID, TFID)))
    .then((docSnap) => {
      if (docSnap.exists()) {
        onSuccess({...docSnap.data(), TFID: docSnap.id} as SurveyFeedbackInterface);
      } else {
        onError("db.doc.01");
      }
    })
    .catch((error) => {
      console.log(error);
      onError(error.code);
    });

const list = (CID: CID, TID: TID, onSuccess: (feedbacks: SurveyFeedbackInterface[]) => void, onError: (errorCode: string) => void) => getDocs(getColRef(surveyFeedbacksColPath(CID, TID)))
    .then((querySnap) => {
      const feedbacks = [] as SurveyFeedbackInterface[];
      querySnap.forEach((doc) => feedbacks.push({...doc.data(), TFID: doc.id} as SurveyFeedbackInterface));
      onSuccess(feedbacks);
    })
    .catch((error) => {
      console.log(error);
      onError(error.code);
    });

const set = (UID: UID, CID: CID, TID: TID, feedback: SurveyFeedbackInterface, onSuccess: (TFID: TFID) => void, onError: (errorCode: string) => void) => {
  // const newFeedback = doc(getColRef(surveyFeedbacksColPath(CID, TID)));
  const feedbackDoc = getDocRef(surveyFeedbackDocPath(CID, TID, UID));
  setDoc(feedbackDoc, {...feedback, TFID: feedbackDoc.id})
      .then(() => onSuccess(feedbackDoc.id))
      .catch((error) => {
        console.log(error);
        onError(error.code);
      });
};
// , createdAt: serverTimestamp()
const feedbacks = {
  listenAll,
  read,
  list,
  set,
};

export default feedbacks;