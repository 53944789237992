// React
import React from "react";
// Interface
import { AppInterface } from "../../interfaces";
// Config
import appConfig from "./appConfig";
// Wrapper
import AppWrapper from "./AppWrapper";
// CSS
import "./index.css";
// Views
import OverviewView from "./views/Overview";
// import RecommendationsView from "./views/Recommendations";
import IntentionsView from "./views/Intentions";
import ReasonsView from "./views/Reasons";
// import ProductsView from "./views/Products";
import AllAnswersView from "./views/AllAnswers";
// import WebUsageView from "./views/WebUsage";
// import ConversionView from "./views/Conversion";
// import CustomersView from "./views/Customers";

const dashboard : AppInterface = {
  ...appConfig,
  component: (view) => <AppWrapper view={view || OverviewView} />,
  views: [
    OverviewView,
    IntentionsView,
    ReasonsView,
    // ProductsView,
    AllAnswersView,
    // RecommendationsView,
    // ConversionView,
    // WebUsageView,
  ],
};

export default dashboard;