import React, { useEffect, useState } from "react";
// CSS
import "./index.css";
// MUI
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

export default function ToolBar(
  {children, title}:
  {children: JSX.Element, title?: string}
) {

  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
		let timeoutId: ReturnType<typeof setTimeout>;
		if (isOpen) {
			timeoutId = setTimeout(() => setIsVisible(true), 500);
		} else {
			setIsVisible(false);
		}
		return () => timeoutId && clearTimeout(timeoutId);
  }, [isOpen])

  return (
    <div className="lc-section lc-toolbar" style={{minHeight: "unset"}}>
      <div className="lc-toolbar-toggle"
        onClick={() => setIsOpen((oldV) => !oldV)}
      >
        <DoubleArrowIcon style={{transition: "all 0.5s ease-in-out", transform: isOpen ? "rotate(90deg)" : "rotate(0deg)"}} />
        <h6 style={{width: "50%", textAlign: "center"}}>{title}</h6>
        <DoubleArrowIcon style={{transition: "all 0.5s ease-in-out", transform: isOpen ? "rotate(90deg)" : "rotate(180deg)"}} />
      </div>
      <div className="lc-toolbar-container" style={{maxHeight: isOpen ? "1200px" : "0", overflow: isVisible ? "visible" : "hidden"}}>
        {children}
      </div>
    </div>
  )
}