// React
import React from "react";
import { Link, useNavigate } from "react-router-dom";
// Interfaces
import { AppInterface, ViewInterface } from "../../interfaces";
// CSS
import "./index.css";
// MUI
import WidgetsTwoToneIcon from '@mui/icons-material/WidgetsTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
// import AppsIcon from "@mui/icons-material/Apps";
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import LoopIcon from "@mui/icons-material/Loop";
// import LoginIcon from "@mui/icons-material/Login";
// import LogoutIcon from "@mui/icons-material/Logout";
// Contexts
import { useDeviceContext } from "../../contexts/device";
import { useI18nContext } from "../../contexts/i18n";
import { useUserContext } from "../../contexts/user";
// Assets
import logo from "../../assets/logo.svg";
import minilogo from "../../assets/minilogo.png";
// Components
import NavButton from "./NavButton";

export default function NavBar(
  {headerHeight, headerPadding, isLoading, isOpen, setIsOpen, currentApp, currentView}:
  {headerHeight: number, headerPadding: number, isLoading: boolean, isOpen: boolean, setIsOpen: (value: boolean) => void, currentApp: AppInterface, currentView: ViewInterface}
) {
  const device = useDeviceContext();
  const i18n = useI18nContext();
  const userContext = useUserContext();
  const navigate = useNavigate();

	return (
    <div className="lc-navbar"
      style={{
        height: headerHeight + headerPadding * 2,
        // padding: headerPadding, 
        backgroundColor: isOpen ? "var(--light)" : currentView?.color || currentApp?.color || undefined,
      }}
    >
      <NavButton
        isLoading={isLoading}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        text=""
        desc="Menu"
        icon={<WidgetsTwoToneIcon fontSize="large" />}
        // isDisabled={isLoading}
        isMirrored={true}
      />
      <Link
        className="lc-navbar-logo"
        to="/"
        onClick={() => isOpen && setIsOpen(false)}
      ><img
        src={device.isMobile() || device.isTablet() ? minilogo : logo}
        style={{width: device.isMobile() || device.isTablet() ? "35px" : "200px"}}
        alt="Livecrew logo"
      /></Link>
      <NavButton
        isLoading={isLoading}
        isOpen={isOpen}
        onClick={() => {
          isOpen && setIsOpen(false);
          userContext.UID ? navigate("/user/logout") : navigate("/guest/login");
        }}
        text={userContext.email ? userContext.email : ""}
        desc={userContext.email ? i18n.t("shared.userDict.logout", {case: "first"}) : i18n.t("shared.userDict.login", {case: "first"})}
        icon={<AccountCircleTwoToneIcon fontSize="large" />}
        // isDisabled={isLoading}
      />
    </div>
	)
}