// React
import React from 'react';
import { Link } from 'react-router-dom';
// MUI
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
// Interfaces
import { AppInterface, ViewInterface } from "../../interfaces";
// Contexts
import { useDeviceContext } from '../../contexts/device';
import { useI18nContext } from '../../contexts/i18n';

export default function NavRight(
  {headerHeight, headerPadding, isLoading, isOpen, setIsOpen, currentApp, selectedApp, currentView, selectedView, setSelectedView}:
  {headerHeight: number, headerPadding: number, isLoading: boolean, isOpen: boolean, setIsOpen: (value: boolean) => void, currentApp: AppInterface, selectedApp: AppInterface, currentView: ViewInterface, selectedView: ViewInterface, setSelectedView: (view: ViewInterface) => void}
) {
  const device = useDeviceContext();
  const i18n = useI18nContext();

	return (
    <div className="lc-navpanel lc-navpanel-right"
      style={{height: device.innerHeight - (headerHeight + headerPadding * 2) * 2 - headerHeight, top: headerHeight + headerPadding * 2, right: isOpen ? "0%" : "-55%", padding: headerPadding,
      // backgroundColor: isOpen ? "var(--light)" : "#FFFFFF88"
    }}>
      {!isLoading ? <>
        <div className="lc-navpanel-linkslist">
          {selectedApp.views && selectedApp.views.filter((view) => !view.hidden).map((view) => <Link
            tabIndex={isOpen ? undefined : -1}
            key={"link-"+selectedApp.AID+"-"+view.AVID}
            className={"lc-navpanel-link"
              + (view.AVID === selectedView.AVID ? " lc-navpanel-link-selected" : "")
              + (currentApp.AID === selectedApp.AID && view.AVID === currentView.AVID ? " lc-navpanel-link-current" : "")}
            to={selectedApp.url + view.url}
            onClick={() => setIsOpen(false)}
            onMouseOver={() => setSelectedView(view)}
            onTouchStart={() => setSelectedView(view)}
          >
            <DoubleArrowIcon className="lc-navpanel-arrow" style={{left: -4, color: view.AVID === selectedView.AVID ? "var(--interactive)" : "var(--light)"}} />
            {i18n.t(selectedApp.AID + ".views." + view.AVID + ".name", {defaultValue: "No View name !"})}
          </Link>)}
        </div>
        <small className="lc-navpanel-desc">
          <HelpTwoToneIcon className="lc-navpanel-descicon" />
          {selectedApp && selectedApp.AID
            ? selectedView && selectedView.AVID
              ? i18n.t(selectedApp.AID + ".views." + selectedView.AVID + ".desc", {defaultValue: " "})
              : i18n.t(selectedApp.AID + ".desc", {defaultValue: " "})
            : " "
          }
        </small>
      </> : <div style={{height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <AutorenewRoundedIcon />
      </div>}
      {/* {isLoading
        ? <h2></h2>
        : <div className="lc-navpanel-linkslist">
          {selectedApp.views && selectedApp.views.map((view) => <Link
            tabIndex={isOpen ? undefined : -1}
            key={"link-"+selectedApp.AID+"-"+view.AVID}
            className={"lc-navpanel-link"
              + (view.AVID === selectedView.AVID ? " lc-navpanel-link-selected" : "")
              + (currentApp.AID === selectedApp.AID && view.AVID === currentView.AVID ? " lc-navpanel-link-current" : "")}
            // to={selectedApp.url + "/" + view.url}
            to={selectedApp.url + view.url}
            onClick={() => setIsOpen(false)}
            onMouseOver={() => setSelectedView(view)}
            onTouchStart={() => setSelectedView(view)}
          >
            <DoubleArrowIcon className="lc-navpanel-arrow" style={{left: -4, color: view.AVID === selectedView.AVID ? "var(--interactive)" : "var(--light)"}} />
            {i18n.t(selectedApp.AID + ".views." + view.AVID + ".name", {defaultValue: "No View name !"})}
          </Link>)}
        </div>
      }
      <small className="lc-navpanel-desc">
        <HelpTwoToneIcon className="lc-navpanel-descicon" />
        {selectedApp && selectedApp.AID
          ? selectedView && selectedView.AVID
            ? i18n.t(selectedApp.AID + ".views." + selectedView.AVID + ".desc", {defaultValue: " "})
            : i18n.t(selectedApp.AID + ".desc", {defaultValue: " "})
          : " "
        }
      </small> */}
    </div>
	)
}