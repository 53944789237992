import React, { useState } from "react";
import { BarItem, ResponsiveBar } from '@nivo/bar';
// Interfaces
import { ItemInterface, NivoDataInterface, NivoDataWOChildrenInteface } from "../../../interfaces";
// AppConfig
// import appConfig from "../appConfig";
// Contexts
import { useI18nContext } from "../../../contexts/i18n";

const labelLight = "#F4F4F8";
const labelDark = "#222222";
const colorsLC = ['#08306b', '#083a7a', '#094589', '#0b4f96', '#1059a1', '#1763aa', '#1f6db1'].reverse();

export default function SimpleBar(
  {title, data, flex, colors, reverse, onClick}:
  {title?: string, data: NivoDataInterface[], flex?: string, colors?: string[] | {scheme: "paired" | "set3"}, reverse?: boolean, onClick?: (item: ItemInterface) => void}
) {
	// const i18n = useI18nContext();

  // const [infoId, setInfoId] = useState("");

  return (<div style={{height: "fit-content", minWidth: "320px", maxWidth: "840px", display: "flex", flexDirection: "column", flex: flex ? flex : "1 0 100%"}}>
    {title && <h5 style={{width: "100%", textAlign: "center"}}>{title}</h5>}
    <div style={{height: ((30*data.length)+40)+"px", width: "100%"}}><ResponsiveBar
      data={data as NivoDataWOChildrenInteface[]}
      keys={["percent"]}
      indexBy="label"
      valueFormat={(value) => value === 0 || isNaN(value) ? "" : value+"%"}
      valueScale={{type: "symlog"}}
      onClick={onClick ? (item) => onClick({label: item.data.label, value: item.data.id}) : undefined}
      // tooltipLabel={(l) => l.indexValue + " (" + l.data.value + "/" + l.data.total + ")"}
      tooltip={(item) => <small style={{
        padding: 8, color: "var(--dark)", backgroundColor: "var(--lightEE)",
        boxShadow: "1px 1px 2px var(--dark88)", borderRadius: 8
      }}><span style={{fontWeight: "bold"}}>{item.indexValue}</span>{" (" + item.data.value + "/" + item.data.total + ") "}<span style={{fontWeight: "bold"}}>{item.value + "%"}</span></small>}
      // tooltip={(item) => <small style={{
      //   padding: 8, color: "var(--dark)", backgroundColor: "var(--lightEE)",
      //   boxShadow: "1px 1px 2px var(--dark88)", borderRadius: 8
      // }}>{item.indexValue + " (" + item.data.value + "/" + item.data.total + ") " + item.value + "%"}</small>}
      barComponent={(props) => <BarItem {...props}
        style={{
          ...props.style,
          color: props.style.color,
          labelColor: props.style.labelColor,
        }}
        onMouseEnter={(_data, event) => {
          event.currentTarget.style.fill = "var(--interaction)";
          event.currentTarget.style.cursor = onClick ? "pointer" : "inherit";
        }}
        onMouseLeave={(_data, event) => {
          event.currentTarget.style.fill = "";
        }}
      />}
      margin={{top: 10, right: !reverse ? 10 : 200, bottom: 10, left: !!reverse ? 10 : 200}}
      padding={0.2}
      layout="horizontal"
      reverse={!!reverse}
      layers={['grid', 'bars', 'axes', 'markers', 'legends', 'annotations']}
      maxValue={100}
      enableGridX={true}
      enableGridY={false}
      gridXValues={[0, 50, 100]}
      labelTextColor={labelLight}
      axisLeft={!reverse ? {
        tickSize: 0,
        // tickPadding: (-deviceContext.innerWidth / 2) + 30 || -150,
        tickRotation: 0,
      } : null}
      axisRight={reverse ? {
        tickSize: 0,
        // tickPadding: (-deviceContext.innerWidth / 2) + 30 || -150,
        tickRotation: 0,
      } : null}
      axisBottom={null}
      colorBy={"indexValue"}
      colors={colorsLC}
      theme={{
        fontSize: 12,
        textColor: "var(--dark)",
        background: "var(--light88)",
      }}
    /></div>
  </div>)
}