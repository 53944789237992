// React
import React from 'react';
import { useNavigate } from 'react-router-dom';
// MUI
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// Interfaces
import { ViewInterface } from '../../../interfaces';
// Contexts
import { useI18nContext } from '../../../contexts/i18n';
// import { useCrewappContext } from '../../../contexts/crewapp';
// AppConfig
import appConfig from '../appConfig';
// Views
import QuestionnaireView from './Questionnaire';
import SurveyView from './Surveys';
// Components
import Widget from '../../../components/Widget';

const HomeCrewapp = () => {
	const i18n = useI18nContext();
	// const crewappContext = useCrewappContext();
  const navigate = useNavigate();
	// const [isDisabled, setIsDisabled] = useState(false);
	
	return (<>
		<Widget
			title={i18n.t(appConfig.AID + ".views." + QuestionnaireView.AVID + ".name", {case: "first"})}
			onClick={() => navigate(appConfig.url+QuestionnaireView.url)}
			flex="1 0 100%"
			maxHeight="180px"
		><div className="lc-widget-container-flex">
			<p style={{textAlign: "center"}}>{i18n.t(appConfig.AID + ".views." + QuestionnaireView.AVID + ".desc")}</p>
			{QuestionnaireView.icon}
		</div></Widget>
		<Widget
			title={i18n.t(appConfig.AID + ".views." + SurveyView.AVID + ".name", {case: "first"})}
			onClick={() => navigate(appConfig.url+SurveyView.url)}
			flex="1 0 100%"
			maxHeight="180px"
		><div className="lc-widget-container-flex">
			<p style={{textAlign: "center"}}>{i18n.t(appConfig.AID + ".views." + SurveyView.AVID + ".desc")}</p>
			{SurveyView.icon}
		</div></Widget>
	</>)
};

const HomeCrewappView : ViewInterface = {
  AVID: "homecrewapp",
  component: (props?: {dual?: boolean}) => <HomeCrewapp />,
  url: "/home",
  icon: <AccountCircleIcon />,
  color: "#00000000"
};

export default HomeCrewappView;