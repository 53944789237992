import { onDocUpdate, getColRef, getDocs, getDocRef, getDoc, setDoc, doc, query } from "../../firestore";
import { SurveyInterface, surveysColPath, surveyDocPath, CID, TID } from "livecrew-interfaces";
import configs from "./configs";
import feedbacks from "./feedbacks";

const listenAll = (CID: CID, onChange: (surveys: SurveyInterface[]) => void) => onDocUpdate(
  query(getColRef(surveysColPath(CID))),
  (querySnap) => {
    const surveys = [] as SurveyInterface[];
    querySnap.forEach((doc) => surveys.push(doc.data() as SurveyInterface));
    onChange(surveys);
  },
  (error) => onChange([]),
);

const read = (CID: CID, TID: TID, onSuccess: (template: SurveyInterface) => void, onError: (errorCode: string) => void) => getDoc(getDocRef(surveyDocPath(CID, TID)))
    .then((docSnap) => {
      if (docSnap.exists()) {
        onSuccess({...docSnap.data(), TID: docSnap.id} as SurveyInterface);
      } else {
        onError("db.doc.01");
      }
    })
    .catch((error) => {
      console.log(error);
      onError(error.code);
    });

const create = (CID: CID, template: SurveyInterface, onSuccess: (TID: TID) => void, onError: (errorCode: string) => void) => {
  const newTemplate = doc(getColRef(surveysColPath(CID)));
  setDoc(newTemplate, {...template, TID: newTemplate.id})
      .then(() => onSuccess(newTemplate.id))
      .catch((error) => {
        console.log(error);
        onError(error.code);
      });
};
    
const surveys = {
  listenAll,
  read,
  create,
  configs: configs,
  feedbacks: feedbacks,
};

export default surveys;