// React
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Interfaces
import { AppInterface, ViewInterface } from "../../interfaces";
// CSS
import "./index.css";
// Contexts
import { useAppsContext } from '../../contexts/apps';
// Components
import AppsRoutes from './AppsRoutes';
// import Footer from './Footer';
// import NavLeft from './NavLeft';
// import NavRight from './NavRight';
import Overlay from './Overlay';

export default function MainContainer(
  {headerHeight, headerPadding, isLoading, isOpen, setIsOpen, currentApp, setCurrentApp, currentView, setCurrentView}:
  {headerHeight: number, headerPadding: number, isLoading: boolean, isOpen: boolean, setIsOpen: (value: boolean) => void, currentApp: AppInterface, setCurrentApp: (app: AppInterface) => void, currentView: ViewInterface, setCurrentView: (view: ViewInterface) => void}
) {
  // const device = useDeviceContext();
  const appsContext = useAppsContext();
  const location = useLocation();
  // const [currentApp, setCurrentApp] = useState({} as AppInterface);
  // const [selectedApp, setSelectedApp] = useState({} as AppInterface);
  // const [currentView, setCurrentView] = useState({} as ViewInterface);

  // const [selectedView, setSelectedView] = useState({} as ViewInterface);

  useEffect(() => {
    // console.log(appsContext.apps);
    if (isLoading) return;
    const url = location.pathname.split("/");
    const sAppId = (!url[1] || url[1] === "") ? appsContext.getApps()[0].AID ?? "" : url[1] ?? "";
    const sViewId = url[2] ?? "";
    const sApp = appsContext.getApp(sAppId);
    const sView = appsContext.getView(sAppId, sViewId);
    // console.log(sApp);
    // console.log("sView", sView);
    setCurrentApp(sApp);
    // setSelectedApp(sApp);
    setCurrentView(sView);
    // setSelectedView(sView);
  }, [location, isLoading, isOpen, appsContext]);

  // useEffect(() => console.log("Main container update"));
  
	return (
    <div className="lc-maincontainer" style={{paddingTop: headerHeight + headerPadding * 2 + 1, cursor: isLoading ? "wait" : "inherit", backgroundColor: currentView?.color || currentApp?.color || undefined }}>
      <Overlay
        headerHeight={headerHeight}
        headerPadding={headerPadding}
        isLoading={isLoading}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        currentApp={currentApp}
        currentView={currentView}
      />
      <AppsRoutes />
    </div>
	)
}