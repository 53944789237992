// React
import React from 'react';
// MUI
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
// Interfaces
import { ViewInterface } from '../../../interfaces';
// Contexts
import { useI18nContext } from '../../../contexts/i18n';
import { useAdminContext } from '../../../contexts/admin';
// AppConfig
import appConfig from '../appConfig';
import * as crewappAppConfig from "../../crewapp/appConfig";
import * as dashboardAppConfig from "../../dashboard/appConfig";
// Components
import UsersList from '../components/UsersList';

const Users = () => {
	const i18n = useI18nContext();
	const adminContext = useAdminContext();

	return (<>
    <section className="lc-section" style={{flexDirection: "column"}}>
			<div className="lc-section-toolbar">
				<h2 style={{width: "100%", textAlign: "center"}}>{i18n.t(appConfig.AID + ".views." + UsersView.AVID + ".name", {case: "first"})}</h2>
			</div>
			<div style={{display: "flex", flexDirection: "column"}}>
				<div style={{width: "100%", display: "flex", alignItems: "center", color: appConfig.colorDark}}>
					{appConfig.icon}
					<small style={{textAlign: "left", padding: 8, color: "var(--dark)"}}>
						{i18n.t(appConfig.AID + ".name") + " : " + i18n.t(appConfig.AID + ".desc", {case: "first"})}
					</small>
				</div>
				<div style={{width: "100%", display: "flex", alignItems: "center", color: crewappAppConfig.default.colorDark}}>
					{crewappAppConfig.default.icon}
					<small style={{textAlign: "left", padding: 8, color: "var(--dark)"}}>
						{i18n.t(crewappAppConfig.default.AID + ".name") + " : " + i18n.t(crewappAppConfig.default.AID + ".desc", {case: "first"})}
					</small>
				</div>
				<div style={{width: "100%", display: "flex", alignItems: "center", color: dashboardAppConfig.default.colorDark}}>
					{dashboardAppConfig.default.icon}
					<small style={{textAlign: "left", padding: 8, color: "var(--dark)"}}>
						{i18n.t(dashboardAppConfig.default.AID + ".name") + " : " + i18n.t(dashboardAppConfig.default.AID + ".desc", {case: "first"})}
					</small>
				</div>
				<div style={{width: "100%", display: "flex", alignItems: "center", color: "var(--dark)"}}>
					<LockOpenRoundedIcon />
					<small style={{textAlign: "left", padding: 8, color: "var(--dark)"}}>{"Permettre l'accès aux données de l'ensemble des boutiques"}</small>
				</div>
			</div>
			<UsersList
				users={adminContext.configAdmin?.users?.sort((a, b) => a.email.localeCompare(b.email)) || []}
			/>
		</section>
	</>)
}

const UsersView : ViewInterface = {
  AVID: "users",
  component: (props?: {dual?: boolean}) => <Users />,
  url: "/users",
  icon: <AccountCircleIcon />,
}

export default UsersView;