// React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// MUI
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// Interfaces
import { ViewInterface } from "../../../interfaces";
// Contexts
import { useI18nContext } from "../../../contexts/i18n";
import { useUserContext } from "../../../contexts/user";
// AppConfig
import appConfig from "../appConfig";
// Views
import LoginView from "./Login";
import RecoverView from "./Recover";
// Components
import Widget from "../../../components/Widget";

const Register = () => {
	const i18n = useI18nContext();
	const navigate = useNavigate();
	const userContext = useUserContext();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [password2, setPassword2] = useState("");
	const [message, setMessage] = useState("");
	const [isDisabled, setIsDisabled] = useState(false);

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsDisabled(true);
		userContext.register(email, password, password2, (errorCode) => {
			setMessage(i18n.t(errorCode));
			setIsDisabled(false);
		})
	};

	return (<div className="view-guest">
		<Widget
			title={i18n.t(appConfig.AID + ".views." + RegisterView.AVID + ".name")}
			isFocused={true}
			width="100%"
		>
			<form
				onSubmit={handleSubmit}
			>
				<label htmlFor="email">{i18n.t("shared.userDict.email", {case: "first"})}</label>
				<input
					id="email"
					type="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					disabled={isDisabled}
				/>
				<label htmlFor="password1">{i18n.t("shared.userDict.password", {case: "first"})}</label>
				<input
					id="password1"
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					disabled={isDisabled}
				/>
				<label htmlFor="password2">{i18n.t("shared.userDict.passwordConfirm", {case: "first"})}</label>
				<input
					id="password2"
					type="password"
					value={password2}
					onChange={(e) => setPassword2(e.target.value)}
					disabled={isDisabled}
				/>
				<input
					type="submit"
					aria-label="Submit button"
					value={i18n.t(appConfig.AID + ".views." + RegisterView.AVID + ".name")}
					disabled={isDisabled}
				/>
				<small style={{textAlign: "center"}}>{message}</small>
			</form>
		</Widget>
		<div style={{
			width: "100%",
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "space-between",
			alignItems: "center",
			padding: "4px 0px",
		}}>
			<input
				type="button"
				aria-label={i18n.t(appConfig.AID + ".views." + LoginView.AVID + ".name", {defaultValue: ""})}
				value={i18n.t(appConfig.AID + ".views." + LoginView.AVID + ".name", {defaultValue: ""})}
				onClick={() => navigate(appConfig.url+LoginView.url)}
			/>
			<input
				type="button"
				aria-label={i18n.t(appConfig.AID + ".views." + RecoverView.AVID + ".name", {defaultValue: ""})}
				value={i18n.t(appConfig.AID + ".views." + RecoverView.AVID + ".name", {defaultValue: ""})}
				onClick={() => navigate(appConfig.url+RecoverView.url)}
			/>
		</div>
	</div>)
}

const RegisterView : ViewInterface = {
  AVID: "register",
  component: (props?: {dual?: boolean}) => <Register />,
  url: "/register",
  icon: <AccountCircleIcon />
}

export default RegisterView;