// React
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// MUI
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// Interfaces
import { ViewInterface } from '../../../interfaces';
// Contexts
import { useI18nContext } from '../../../contexts/i18n';
import { useUserContext } from '../../../contexts/user';
// AppConfig
import appConfig from '../appConfig';
// Components
import Widget from '../../../components/Widget';
// Views
import LoginView from "./Login";

interface ValidationInterface {
  mode: string,
  oobCode: string,
  apiKey: string,
  lang: string,
}

const Validation = () => {
	const i18n = useI18nContext();
	const userContext = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [validationState, setValidationState] = useState({mode: "", oobCode: "", apiKey: "", lang: ""} as ValidationInterface);
  // const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [password2, setPassword2] = useState("");
	const [message, setMessage] = useState("");
	const [isDisabled, setIsDisabled] = useState(false);

  const forms = [{
    mode: "resetPassword",
    component: <form
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsDisabled(true);
        userContext.confirmPassReset(
          validationState.oobCode,
          password,
          password2,
          () => {
            setMessage(i18n.t(appConfig.AID + ".views." + ValidationView.AVID + ".modes.resetPasswordSuccess"));
            setIsDisabled(true);
            setTimeout(() => navigate(appConfig.url + LoginView.url), 1000);
          },
          (errorCode) => {
            setMessage(i18n.t(errorCode));
            setIsDisabled(false);
          }
        );
      }}
    >
      <label htmlFor="password1">{i18n.t("shared.userDict.passwordNew", {case: "first"})}</label>
      <input
        id="password1"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        disabled={isDisabled}
      />
      <label htmlFor="password2">{i18n.t("shared.userDict.passwordConfirm", {case: "first"})}</label>
      <input
        id="password2"
        type="password"
        value={password2}
        onChange={(e) => setPassword2(e.target.value)}
        disabled={isDisabled}
      />
      <input
        type="submit"
        aria-label="Submit button"
        value={i18n.t("shared.submit")}
        disabled={isDisabled}
      />
      <small style={{textAlign: "center"}}>{message}</small>
    </form>,
  }];

  const getFormWidget = (validationState: ValidationInterface) => {
    const form = forms.find((f) => f.mode === validationState.mode) || {mode: "badLink", component: <small style={{width: "100%", textAlign: "center"}}>{i18n.t(appConfig.AID + ".views." + ValidationView.AVID + ".badLink")}</small>};
    return <Widget
      title={i18n.t(appConfig.AID + ".views." + ValidationView.AVID + ".modes." + form.mode)}
      isFocused={true}
      >{form.component}</Widget>
  };

  useEffect(() => {
    if (location.search && location.search.length > 1) {
      const options = location.search.slice(1).split("&");
      const newState = options.reduce((acc, val) => {
        const [label, value] = val.split("=");
        return label && value
          ? {...acc, [label]: value}
          : acc;
      }, {});
      setValidationState((oldState) => {return {...oldState, ...newState}})
    } else {
      navigate("/");
    }
  }, [location.search, navigate]);

  useEffect(() => console.log(validationState), [validationState])

return (<>
    {getFormWidget(validationState)}
	</>)
}

const ValidationView : ViewInterface = {
  AVID: "validation",
  component: (props?: {dual?: boolean}) => <Validation />,
  url: "/validation",
  icon: <AccountCircleIcon />,
  hidden: true,
}

export default ValidationView;