// React
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// MUI
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
// Interfaces
import { ViewInterface } from '../../../interfaces';
// Contexts
import { useI18nContext } from '../../../contexts/i18n';
// AppConfig
import appConfig from '../appConfig';
import guestConfig from "../../guest/appConfig";
// Views
// import WhyView from './Why';
// import HowView from './How';
// Components
import Widget from '../../../components/Widget';
import CookiesView from '../../../views/Cookies';

const Home = () => {
	const i18n = useI18nContext();
	const navigate = useNavigate();

	const [currentWidget, setCurrentWidget] = useState(0);

	useEffect(() => {
		let timeoutId: ReturnType<typeof setTimeout>;
		if (currentWidget > -1 && currentWidget < 5) {
			timeoutId = setTimeout(() => setCurrentWidget(currentWidget + 1), 2000);
		} else {
			setCurrentWidget(-1);
		}
		return () => timeoutId && clearTimeout(timeoutId);
	}, [currentWidget]);

	return (
		<>
			<div style={{height: "48px", width: "100%", position: "absolute", top: 0, backgroundColor: currentWidget === 4 ? "var(--lightEE)" : "inherit", transition: "all 0.5s ease-in-out"}}></div>
			{/* <Widget
				title={i18n.t(appConfig.AID + ".views." + WhyView.AVID + ".name", {defaultValue: ""})}
				isFocused={currentWidget === 1}
				onClick={() => navigate(appConfig.url+WhyView.url)}
			><p style={{textAlign: "center"}}>{i18n.t(appConfig.AID + ".views." + WhyView.AVID + ".desc", {defaultValue: ""})}</p></Widget>
			<Widget
				title={i18n.t(appConfig.AID + ".views." + HowView.AVID + ".name", {defaultValue: ""})}
				onClick={() => navigate(appConfig.url+HowView.url)}
				isFocused={currentWidget === 2}
			><p style={{textAlign: "center"}}>{i18n.t(appConfig.AID + ".views." + HowView.AVID + ".desc", {defaultValue: ""})}</p></Widget> */}
			<Widget
				title={i18n.t(guestConfig.AID + ".views.login.name", {defaultValue: ""})}
				onClick={() => navigate(guestConfig.url)}
				isFocused={currentWidget === 3}
				flex="1 0 100%"
				maxHeight="180px"
			>
				<p style={{padding: "20px 0px", textAlign: "center"}}>{i18n.t(guestConfig.AID + ".views.login.desc", {defaultValue: ""})}</p>
			</Widget>
      <div className="lc-footer-legal" style={{width: "100%", alignSelf: "flex-end", padding: 20}}>
        <small>Copyright © 2022 LiveCrew SAS</small>
				<Link
					key={"link-cookies"}
					style={{display: "flex", alignItems: "center"}}
					to={CookiesView.url}
				>
					<small style={{paddingRight: "4px"}}>{"Gestion des cookies & mentions légales"}</small>
					<LaunchRoundedIcon fontSize="small" />
				</Link>
        {/* <small>{i18n.t("nav.legal", {defaultValue: "Privacy policy"})}</small> */}
      </div>
		</>
	)
}

const HomeView : ViewInterface = {
  AVID: "home",
  component: (props?: {dual?: boolean}) => <Home />,
  url: "/home",
  icon: <AccountCircleIcon />
}

export default HomeView;