// React
import React from 'react';
// Interfaces
import { ViewInterface } from '../../interfaces';
// AppConfig
import appConfig from './appConfig';
// Contexts
import { useTrackPageView } from '../../contexts/analytics';

export default function AppWrapper({view} : {view: ViewInterface}) {
	useTrackPageView(appConfig.AID, view.AVID);

	return (
		<div className={(!view || view.AVID === "homecrewapp") ? "lc-app" : "lc-app app-crewapp"} id="lc-app-crewapp">
			{view.component()}
		</div>
	)
}