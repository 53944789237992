// React
import React from 'react';
import { Link } from 'react-router-dom';
// MUI
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
// Interfaces
import { ViewInterface } from '../interfaces';
// Contexts
import { useTrackPageView } from '../contexts/analytics';
import { useI18nContext } from '../contexts/i18n';
// Components
import Widget from '../components/Widget';

const ToS = () => {
	const i18n = useI18nContext();

	useTrackPageView("legal", "tos");

	return (<div className="lc-app">
		<Link
      key={"link-legal"}
			to={"/legal"}
			className="lc-buttonicon"
			style={{position: "absolute", left: 0}}
		><ArrowBackRoundedIcon /></Link>
		<Widget
      title={i18n.t("legal.views." + TosView.AVID + ".name")}
			isFocused={true}
      maxHeight="unset"
      maxWidth="800px"
			width="100%"
		>
			<div className="lc-legaldoc">
				<hr style={{padding: "0px 8px"}} />
				<p>ToDo</p>
      </div>
		</Widget>
	</div>)
}

const TosView : ViewInterface = {
  AVID: "tos",
  component: (props?: {dual?: boolean}) => <ToS />,
  url: "/tos",
  icon: <AccountCircleIcon />,
}

export default TosView;