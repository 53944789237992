// React
// import React from 'react';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
// Interfaces
import { ViewInterface } from '../../interfaces';
// AppConfig
import appConfig from './appConfig';
// Contexts
import { useTrackPageView } from '../../contexts/analytics';
// Hooks
// import usePageView from '../../hooks/pageView';

export default function AppWrapper({view} : {view: ViewInterface}) {
	// usePageView(appConfig, view);
	useTrackPageView(appConfig.AID, view.AVID);

  const location = useLocation();
  const topOfAdmin = useRef<HTMLDivElement>(null);

  useEffect(() => topOfAdmin?.current?.scrollIntoView(),[location])

	return (
		<div className="lc-app app-admin" id="lc-app-admin" ref={topOfAdmin}>
			{/* {view?.AVID !== "homeadmin" && <span id="top-app-admin" ref={topOfAdmin} style={{height: 0, width: "100%"}} />} */}
			{view.component()}
		</div>
	)
}