// React
import React, { useState, useLayoutEffect, useEffect } from 'react';
import { utils, writeFileXLSX } from 'xlsx';
// Dataframe-Js
import * as dfd from "danfojs";
// Interfaces
import { functions, QuestConfigInterface, QuestionnaireInterface } from 'livecrew-interfaces';
import { DashboardContextInterface, ItemInterface, NivoDataInterface } from "../interfaces";
// Backend
import { db } from "../backend"
// Contexts
import { useI18nContext } from './i18n';
import { useUserContext } from './user';
import { useCompanyContext } from './company';
import { useStoreContext } from './store';
// import { useAppsContext } from './apps';
import { QuestFeedbackInterface } from 'livecrew-interfaces';

/** 
 * Ref to the dashboard context, do NOT expose directly
 * @see DashboardProvider to provide the context
 * @see useDashboardContext to subscribe to the context
 */
const DashboardContext = React.createContext({} as DashboardContextInterface);

/**
 * Provides the dashboard context to its children
 * @param {JSX} children React children props
 */
const DashboardProvider = ({children} : {children: JSX.Element}) => {
  const i18n = useI18nContext();
	const userContext = useUserContext();
  const companyContext = useCompanyContext();
  const storeContext = useStoreContext();
  // const appsContext = useAppsContext();
  const [feedbacks, setFeedbacks] = useState([] as QuestFeedbackInterface[]);
  const [fullDF, setFullDF] = useState({} as dfd.DataFrame);
  const [stats, setStats] = useState({first: 0, last: 0, total: 0});
  const [currentCSIDs, setCurrentCSIDs] = useState([] as ItemInterface[])
  const [isLoading, setIsLoading] = useState(true);
  
  const meta = ["createdAt", "start", "duration", "UID", "CID", "CSID", "TID", "TCID", "TFID"];

  const getFeedbacks = () => feedbacks.length > 0 ? [...feedbacks] : [];

  const getFilteredDf = (df: dfd.DataFrame, config: {
    column: string | number,
    filters: Array<string | number | boolean>,
    notEqual?: boolean,
  }[]) => {
    if (df?.axis?.index?.length > 0 && config.length > 0) {
      const cols = df?.axis?.columns || [];
      const filterItems = config.map((c) => {return {...c, index: cols.indexOf(c.column)}});
      return df?.addColumn({
        column: "[lc]keep",
        values: df?.axis?.index?.map((v, i) => filterItems.reduce((acc: boolean, fi) => {
          if (fi.filters.length === 0) return !!(acc && true);
          const value = (df?.values[i] as [])[fi.index];
          const found = value !== undefined
            ? Array.isArray(value)
              ? (fi.filters.includes(value[0]) || fi.filters.includes(value[1]))
              : fi.filters.includes(value)
            : false;
          // if (found) console.log(fi);
          return fi.notEqual
            ? !!(acc && !found)
            : !!(acc && found)
        }, true)),
      })?.query({column: "[lc]keep", is: "==", to: true})?.drop({columns: "[lc]keep"}) || new dfd.DataFrame([]);
    } else {
      return new dfd.DataFrame([]);
    }
  };

  const getOptions = (df: dfd.DataFrame, col: string, ignore?: string[]) => {
    if (df?.axis?.columns?.includes(col)) {
      const [TDID, TDKID] = col.split("/");
      const {views} = functions.mapTemplate(storeContext.questTemplate, storeContext.questConfig);
      return (df.column(col).unique().values as string[]).filter((v) => v !== null && v !== undefined && (!ignore || !ignore.includes(v))).map((v) => {
        return {label: functions.getLabel(views, i18n.getLocale(), TDID, TDKID, v), value: v}
      }).sort((a, b) => a.label.localeCompare(b.label))
    } else {
      return [];
    }
  };

  const getNivoItems = (df: dfd.DataFrame, col: string) => {
    if (df?.axis?.columns?.includes(col) && df?.axis?.columns?.includes("TFID")) {
      const {views} = functions.mapTemplate(storeContext.questTemplate, storeContext.questConfig);
      const groupedDf = df.groupby([col]).count().loc({columns: [col, "TFID_count"]})?.sort_values({by: "TFID_count", ascending: false}) || new dfd.DataFrame([]);
      return groupedDf?.values?.map((row) => {
        if (Array.isArray(row)) {
          const count = row.length > 1 && typeof row[1] === "number" ? row[1] : 0;
          return {
            id: row[0] + "",
            label: functions.getLabel(views, i18n.getLocale(), col.split("/")[0], col.split("/")[1], row[0] + "") || row[0] + "",
            value: count,
            total: df?.axis?.index?.length || 0,
            percent: Math.floor(count / (df?.axis?.index?.length || 0) * 1000) / 10,
          }
        } else {
          return {
            id: row + "",
            label: row + "",
            value: 0,
            total: 0,
            percent: 0
          }
        }
      }) || [];
    } else {
      return [] as NivoDataInterface[];
    }
  };

  // const exportFeedbacks = () => {
  //   const {views} = functions.mapTemplate(storeContext.questTemplate, storeContext.questConfig);
  //   const header = [] as string[];
  //   views.forEach((v) => v.order.forEach((o) => header.push(functions.getLabel(views, i18n.getLocale(), v.TDID, o))));
  //   // views.forEach((v) => v.order.forEach((o) => header.push(v.TDID+"/"+o)));
  //   const workbook = utils.book_new();
  //   const stores = companyContext.getStores().map((s) => { return {
  //     CSID: s.value,
  //     name: s.label,
  //     worksheet: utils.json_to_sheet(
  //       feedbacks.filter((f) => f.CSID === s.value).map((f) => Object.entries(f.data).reduce((acc, [key, val]) => {
  //         const [TDID, TDKID] = key.split("/");
  //         const question = views.find((v) => v.TDID === TDID)?.keys[TDKID];
  //         return {...acc, [functions.getLabel(views, i18n.getLocale(), TDID, TDKID)]: val === null || !question
  //           ? null
  //           : question.type === "range" || question.type === "free"
  //             ? val
  //             : question.type === "number" && Number.isInteger(val)
  //               ? val < 0 ? "Plus de " + (-1*(val as number)) : val
  //               : question.type === "brand" || question.type === "brand-full"
  //                 ? companyContext.getBrand(val + "")?.label || val
  //                 : question.type === "category" || question.type === "category-full"
  //                   ? companyContext.getCategory(val + "")?.label || val
  //                   : question.type === "product" && Array.isArray(val) && val.length === 2
  //                     ? (companyContext.getCategory(val[0] + "")?.label || val[0]) + " / " + (companyContext.getBrand(val[1] + "")?.label || val[1])
  //                     : functions.getLabel(views, "fr-FR", TDID, TDKID, val)}
  //       }, {} as Record<string,any>)),
  //       {header: header}
  //     )
  //   }});
  //   stores.forEach((s) => utils.book_append_sheet(workbook, s.worksheet, s.name));
  //   writeFileXLSX(workbook, "lc-feedbacks.xlsx", {bookType: "xlsx"});
  // };

  useLayoutEffect(() => {
    const unsubFeedbacks = (userContext.CID && userContext.permissions && userContext.permissions.includes("dashboard") && storeContext.questTID)
      ? db.companies.questionnaires.feedbacks.listenAll(
        userContext.CID,
        userContext.permissions.includes("data") ? "" : storeContext.CSID,
        storeContext.questTID,
        (feedbacks) => {setFeedbacks(feedbacks); setIsLoading(false)}
      ) : undefined;
    return unsubFeedbacks;
  }, [userContext.permissions, companyContext.CID, storeContext.questTID]);

  useEffect(() => {
    // console.log(feedbacks);
    const parse = async (fs: QuestFeedbackInterface[], t: QuestionnaireInterface) => {
      try {
        const mapping = t.order.reduce((acc, TDID) => acc.concat(t.dicts[TDID].order.map((TDKID) => TDID+"/"+TDKID)), [] as string[]);
        const data = fs.map((f) => {
          let product1 : [string, string] = ["[lc]null", "[lc]null"];
          let product2 : [string, string] = ["[lc]null", "[lc]null"];
          // @ts-ignore
          const d : any[] = [f.createdAt, f.start, f.duration || (f.end - f.start), f.UID, f.CID, f.CSID, f.TID, f.TCID, f.TFID];
          mapping.forEach((l) => {
            const v = f.data[l] === undefined || f.data[l] === null
              ? "[lc]null"
              : f.data[l];
            if (Array.isArray(v)) {
              if (l === "purchase/product") {product1 = v};
              if (l === "purchase/product2") {product2 = v};
              d.push(v.reduce((acc, val) => acc + "/lc/" + val))
            } else {
              d.push(v);
            }
          });
          d.push(product1[0],product1[1],product2[0],product2[1])
          return d;
        });
        const df = new dfd.DataFrame(data, {columns: meta.concat(mapping).concat(["purchase/productC1", "purchase/productB1", "purchase/productC2", "purchase/productB2"])});
        df.sort_values({by: "start", inplace: true});
        return df;
      } catch {
        return new dfd.DataFrame([]);
      }
    };
    if (feedbacks.length > 0 && storeContext.questTemplate && storeContext.questConfig) {
      setIsLoading(true);
      parse(feedbacks, storeContext.questTemplate)
          .then((df) => {
            const t = df.axis?.index?.length || 0;
            const firstR = df.values[0];
            const lastR = df.values[t-1];
            setFullDF(df);
            setStats({
              first: Array.isArray(firstR) ? firstR[1] as number : 0,
              last: Array.isArray(lastR) ? lastR[1] as number : 0,
              total: df.axis?.index?.length || 0
            });
            setIsLoading(false);
          })
    } else {
      setFullDF({} as dfd.DataFrame);
      setStats({first: 0, last: 0, total: 0});
      setIsLoading(false);
    }
  }, [feedbacks, storeContext.questTemplate, storeContext.questConfig]);

	const accessors = {
    isLoading,
    fullDF,
    stats,
    currentCSIDs,
    getFeedbacks,
    setCurrentCSIDs,
    getFilteredDf,
    getOptions,
    getNivoItems,
    // exportFeedbacks,
	};
  
  // Do NOT expose state and setState directly in the provider value, ever
	return (
    <DashboardContext.Provider value={accessors}>
      {children}
    </DashboardContext.Provider>
  )
};

/**
 * Returns the actual value of the dashboard context
 * @example
 * const dashboard = useDashboardContext() // subscribe to the context
 * const df = dashboard.getDataFrame() // use methods provided to interact with the context
 * @see DashboardProvider for the full list of methods
 */
const useDashboardContext = () => {
	const context = React.useContext(DashboardContext);
	if (context === undefined) {
		throw new Error('No dashboard context!');
	}
	return context
};

// Do NOT export DashboardContext directly, ever
export {
	DashboardProvider,
	useDashboardContext
};




// const getFilteredDf = (df: dfd.DataFrame, config: {
//   column: string | number,
//   filters: Array<string | number | boolean>,
//   notEqual?: boolean,
// }[]) => {
//   if (df?.axis?.index?.length > 0 && config.length > 0) {
//     const cols = df?.axis?.columns || [];
//     const filterItems = config.map((c) => {return {...c, index: cols.indexOf(c.column)}});
//     return df?.addColumn({
//       column: "[lc]keep",
//       values: df?.axis?.index?.map((v, i) => filterItems.reduce((acc: boolean, val) => {
//         const found = val.filters.length > 0
//           ? val.filters.includes((df?.values[i] as [])[val.index] || "")
//           : val.notEqual
//             ? false
//             : true;
//         return val.notEqual
//           ? !!(acc && !found)
//           : !!(acc && found)
//       }, true)),
//     })?.query({column: "[lc]keep", is: "==", to: true})?.drop({columns: "[lc]keep"}) || new dfd.DataFrame([]);
//   } else {
//     return new dfd.DataFrame([]);
//   }
// }

// const getHeadDf = (df: dfd.DataFrame, column: string | number, count: number) => df?.axis?.columns?.includes(column)
//   ? getFilteredDf(df.groupby([column+""]).count(), [{column: column, filters: ["false"], notEqual: true}]).sort_values({by: "Brand_count", ascending: false}) || new dfd.DataFrame([])
//   : new dfd.DataFrame([]);

// const getUniqueItems = (column: string) => fullDF?.axis?.columns?.includes(column)
//   ? (fullDF.column(column).unique().values as string[]).filter((v) => v !== null && v !== undefined && v !== "LC-null-LC")
//   : [];

// const getItems = (df: dfd.DataFrame, col: string) => {
//   if (df?.axis?.columns?.includes(col) && df?.axis?.columns?.includes("FID")) {
//     const filteredDf = getFilteredDf(df, [{column: col, filters: ["LC-null-LC", "IDK"], notEqual: true}]);
//     const groupedDf = filteredDf?.axis?.columns?.includes(col)
//       ? filteredDf.groupby([col]).count().loc({columns: [col, "FID_count"]})?.sort_values({by: "FID_count", ascending: false}) || new dfd.DataFrame([])
//       : new dfd.DataFrame([]);
//     return groupedDf?.values?.map((row) => {
//       if (Array.isArray(row)) {
//         const count = row.length > 1 && typeof row[1] === "number" ? row[1] : 0;
//         return {
//           id: row[0] + "",
//           label: row[0] + "",
//           value: count,
//           total: filteredDf?.axis?.index?.length || 0,
//           percent: Math.floor(count / (filteredDf?.axis?.index?.length || 0) * 1000) / 10,
//         }
//       } else {
//         return {
//           id: row + "",
//           label: row + "",
//           value: 0,
//           total: 0,
//           percent: 0
//         }
//       }
//     }) || [];
//   } else {
//     return [];
//   }
// };