// React
import React from "react";
// Contexts
import { useDeviceContext } from "../../contexts/device";
// CSS
import "./index.css";

export default function ThemeSelect() {
  const device = useDeviceContext();

	return (
    <select name="theme-select" id="theme-select" className="lc-themeselect"
      value={device.getTheme()}
      onChange={(e) => device.setTheme(e.target.value)}
    >
      {device.getAvailableThemes().map((theme) => <option
        key={theme}
        value={theme}
      >
        {theme.length > 1 ? theme[0].toUpperCase() + theme.slice(1) : theme.toUpperCase()}
      </option>)}
    </select>
	)
}