// React
import React from 'react';
import { Link } from 'react-router-dom';
// MUI
import CancelIcon from '@mui/icons-material/Cancel';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
// Contexts
import { useI18nContext } from '../../contexts/i18n';
// Components
import ThemeSelect from '../../components/ThemeSelect';
import LocaleSelect from '../../components/LocaleSelect';
import CookiesView from '../../views/Cookies';

export default function Footer(
  {headerHeight, headerPadding, isLoading, isOpen, setIsOpen}:
  {headerHeight: number, headerPadding: number, isLoading: boolean, isOpen: boolean, setIsOpen: (value: boolean) => void}
  ) {
  const i18n = useI18nContext();

	return (
    <div className="lc-footer"
      style={{
        height: headerHeight * 2 + headerPadding * 2,
        padding: headerPadding, bottom: isOpen ? "0%" : "-55%",
        backgroundColor: isOpen ? "var(--light)" : "#FFFFFF88"
      }}
    >
      {!isLoading ? <>
        <CancelIcon className="lc-footer-close" fontSize="large" onClick={() => setIsOpen(false)}/>
        <div className="lc-footer-button">
          <ThemeSelect />
        </div>
        <div className="lc-footer-legal">
          <small>Copyright © 2022 LiveCrew SAS</small>
          <Link
            key={"link-cookies"}
            style={{display: "flex", alignItems: "center", color: "var(--interactive)"}}
            to={CookiesView.url}
            onClick={() => setIsOpen(false)}
          >
            <small style={{color: "inherit", paddingRight: "4px"}}>{"Gestion des cookies & mentions légales"}</small>
            <LaunchRoundedIcon fontSize="small" />
          </Link>
          {/* <small>{i18n.t("nav.legal", {defaultValue: "Privacy policy"})}</small> */}
        </div>
        <div className="lc-footer-button">
          <LocaleSelect />
        </div>
      </> : <div style={{height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <AutorenewRoundedIcon />
      </div>}
    </div>
	)
}