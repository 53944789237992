import { onDocUpdate, getDocRef, getDoc, setDoc } from "../firestore";
import { CID, CompanyInterface, companyDocPath } from "livecrew-interfaces";
import configs from "./configs";
import stores from "./stores";
import catalogs from "./catalogs";
// import templates from "./templates";
import questionnaires from "./questionnaires";
import surveys from "./surveys";
// import feedbacks from "./feedbacks";

const listen = (CID: CID, onChange: (company: CompanyInterface) => void) => onDocUpdate(
  getDocRef(companyDocPath(CID)),
  (docSnap) => onChange({...docSnap.data(), CID: docSnap.id} as CompanyInterface),
  (error) => {console.log(error); onChange({} as CompanyInterface)},
);

const read = (CID: CID, onSuccess: (company: CompanyInterface) => void, onError: (errorCode: string) => void) => getDoc(getDocRef(companyDocPath(CID)))
  .then((docSnap) => {
    if (docSnap.exists()) {
      onSuccess({...docSnap.data(), CID: docSnap.id} as CompanyInterface);
    } else {
      onError("db.doc.01");
    }
  })
  .catch((error) => {
    console.log(error);
    onError(error.code);
  });

const update = (company: CompanyInterface, onSuccess: () => void, onError: (errorCode: string) => void) => setDoc(getDocRef(companyDocPath(company.CID)), company)
  .then(() => onSuccess())
  .catch((error) => {
    console.log(error);
    onError(error.code);
  });

const companies = {
  listen,
  read,
  update,
  configs,
  stores,
  catalogs,
  questionnaires,
  surveys,
}

export default companies;