import enUK from "./enUK.json";
import frFR from "./frFR.json";
// import nlBE from "./nlBE.json";

const l10n = {
  "en-UK": enUK,
  "fr-FR": frFR,
  // "nl-BE": nlBE,
};

export default l10n;