// React
import React from 'react';
import { DateRange } from 'react-date-range';
// CSS
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import "./index.css";
// Interfaces
import { ReactDateRangeInterface } from '../../interfaces';

export default function PeriodSelect(
  {id, period, setPeriod, minDate, maxDate}:
  {id: string, period: ReactDateRangeInterface, setPeriod: (period: ReactDateRangeInterface) => void, minDate?: Date, maxDate?: Date}
) {
  const key = "periodselect";

	return (
    // <div style={{transform: "scale(0.8)"}}>
      <DateRange
        ranges={[{...period, key: key, showDateDisplay: false}]}
        onChange={(ranges) => setPeriod(ranges[key])}
        fixedHeight={true}
        minDate={minDate}
        maxDate={maxDate}
        // moveRangeOnFirstSelection
        // focusedRange={[0, 1]}
        // weekdayDisplayFormat=""
      />
    // </div>
	)
}