// React
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import * as dfd from "danfojs";
import { ResponsiveBar } from "@nivo/bar";
// MUI
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// Interfaces
// import { functions } from "livecrew-interfaces";
import { ViewInterface, ReactDateRangeInterface, NivoDataWOChildrenInteface, NivoDataInterface, ItemInterface, ReactSelectInterface } from "../../../interfaces";
// AppConfig
import appConfig from "../appConfig";
// Contexts
import { useI18nContext } from "../../../contexts/i18n";
import { useCompanyContext } from "../../../contexts/company";
import { useStoreContext } from '../../../contexts/store';
import { useDashboardContext } from "../../../contexts/dashboard";
// Views
// import WebUsageView from "./WebUsage";
// import ConversionView from "./Conversion";
// import RecommendationsView from "./Recommendations";
// Components
// import ToolBar from "../../../components/ToolBar";
// import Widget from "../../../components/Widget";
import PeriodToolBar from "../components/PeriodToolBar";
import ToolBar from "../../../components/ToolBar";
import MultiSelect from "../../../components/MultiSelect";
import DoublePie from "../components/DoublePie";

const dayColors = ['#08306b', '#083a7a', '#094589', '#0b4f96', '#1059a1', '#1763aa', '#1f6db1'];

const labelLight = "#F4F4F8";
// const labelDark = "#222222";

const Overview = (props: {dual?: boolean}) => {
	const i18n = useI18nContext();
  const companyContext = useCompanyContext();
  const storeContext = useStoreContext();
  const dashboardContext = useDashboardContext();
	// const navigate = useNavigate();
  
  // const [viewDf, setViewDf] = useState(new dfd.DataFrame([]));

	const [period, setPeriod] = useState({} as ReactDateRangeInterface);
  const [periodDf, setPeriodDf] = useState(new dfd.DataFrame([]));
  const [filteredDf, setFilteredDf] = useState(new dfd.DataFrame([]));

  const optionsStore = companyContext.getStores().length > 0 ? companyContext.getStores() : [{label: storeContext.name, value: storeContext.CSID}];
  const [filtersStore, setFiltersStore] = useState(props.dual ? [] as ItemInterface[] : dashboardContext.currentCSIDs);
  const optionsSocio = dashboardContext.getOptions(periodDf, "profile/socio");
  const [filtersSocio, setFiltersSocio] = useState([] as ItemInterface[]);
  const optionsAge = dashboardContext.getOptions(periodDf, "profile/age");
  const [filtersAge, setFiltersAge] = useState([] as ItemInterface[]);
  const optionsFrom = dashboardContext.getOptions(periodDf, "profile/from");
  const [filtersFrom, setFiltersFrom] = useState([] as ItemInterface[]);
  const optionsFreq = dashboardContext.getOptions(periodDf, "profile/frequence");
  const [filtersFreq, setFiltersFreq] = useState([] as ItemInterface[]);
  const optionsVip = dashboardContext.getOptions(periodDf, "profile/vip");
  const [filtersVip, setFiltersVip] = useState([] as ItemInterface[]);
  const optionsRecc = dashboardContext.getOptions(periodDf, "journey/reccuring");
  const [filtersRecc, setFiltersRecc] = useState([] as ItemInterface[]);

  const [convDf, setConvDf] = useState(new dfd.DataFrame([]));
  const [noconvDf, setNoconvDf] = useState(new dfd.DataFrame([]));

  const [dataTime, setDataTime] = useState([] as NivoDataInterface[]);
  const [average, setAverage] = useState(0);
  const [median, setMedian] = useState(0);

  const [dataConvSocio, setDataConvSocio] = useState([] as NivoDataInterface[]);
  const [dataNoconvSocio, setDataNoconvSocio] = useState([] as NivoDataInterface[]);

  const [dataConvAge, setDataConvAge] = useState([] as NivoDataInterface[]);
  const [dataNoconvAge, setDataNoconvAge] = useState([] as NivoDataInterface[]);

  const [dataConvFrom, setDataConvFrom] = useState([] as NivoDataInterface[]);
  const [dataNoconvFrom, setDataNoconvFrom] = useState([] as NivoDataInterface[]);

  const [dataConvFrequence, setDataConvFrequence] = useState([] as NivoDataInterface[]);
  const [dataNoconvFrequence, setDataNoconvFrequence] = useState([] as NivoDataInterface[]);

  const [dataConvVip, setDataConvVip] = useState([] as NivoDataInterface[]);
  const [dataNoconvVip, setDataNoconvVip] = useState([] as NivoDataInterface[]);

  const [dataConvReccuring, setDataConvReccuring] = useState([] as NivoDataInterface[]);
  const [dataNoconvReccuring, setDataNoconvReccuring] = useState([] as NivoDataInterface[]);

	const getDayColor = (date: string) => {
		const d = new Date(date) || new Date();
		const dotw = d.getDay();
		if (dotw >= 0 && dotw < dayColors.length) {
			return dayColors[dotw];
		} else {
			return dayColors[0];
		}
	};

	const getDayLabel = (date: string) => {
		const d = new Date(date) || new Date();
		const dotw = d.getDay();
		const day = d.getDate();
		const month = d.getMonth() + 1;
		if (dotw >= 0 && dotw < 7) {
			return i18n.t("shared.calendar.days." + dotw) + " " + (day > 9 ? day : "0"+day) + "-" + (month > 9 ? month : "0"+month) ;
		} else {
			return "";
		}
	};

  const updateFilters = (item: ItemInterface, filters: ItemInterface[]) => {
    const newFilters = [...filters];
    const oldIndex = newFilters.findIndex((i) => i.value === item.value);
    if (oldIndex === -1) {
      newFilters.push(item);
    } else {
      newFilters.splice(oldIndex, 1);
    }
    return newFilters;
  };

  useEffect(() => {
    if (dashboardContext.fullDF?.axis?.columns?.includes("createdAt") && dashboardContext.fullDF?.axis?.columns?.includes("TFID") && dashboardContext.fullDF?.axis?.columns?.includes("purchase/nb")) {
      try {
        const viewDf = dashboardContext.getFilteredDf(
          (dashboardContext.fullDF.loc({columns: ["createdAt", "start", "duration", "TFID", "CSID", "purchase/nb", "profile/socio", "profile/age", "profile/from", "profile/frequence", "profile/vip", "journey/reccuring"]}) || new dfd.DataFrame([]))
            .query({condition: dashboardContext.fullDF["start"].ge(period.startDate?.valueOf() || 1).and(dashboardContext.fullDF["start"].le(period.endDate?.valueOf() || Date.now()))}) || new dfd.DataFrame([]),
          [{column: "CSID", filters: filtersStore.map((i) => i.value)}]
        );
        setFiltersSocio([]);
        setFiltersAge([]);
        setFiltersFrom([]);
        setFiltersFreq([]);
        setFiltersVip([]);
        setFiltersRecc([]);
        setPeriodDf(viewDf);
      } catch {
        setFiltersAge([]);
        setFiltersSocio([]);
        setFiltersFrom([]);
        setFiltersFreq([]);
        setFiltersVip([]);
        setFiltersRecc([]);
        setPeriodDf(new dfd.DataFrame([]));
      }
    }
  }, [dashboardContext.fullDF, period, filtersStore]);

  useEffect(() => {
    if (periodDf?.axis?.columns?.length > 0) {
      const serie = periodDf["duration"] as dfd.Series || new dfd.Series([]);
      const durations = serie.loc(serie.le(240000).values as boolean[]).sort_values();
      setAverage(Math.round((durations?.mean() || 0) / 1000));
      setMedian(Math.round((durations?.median() || 0) / 1000));
      setFilteredDf(dashboardContext.getFilteredDf(periodDf, [
        {column: "profile/socio", filters: filtersSocio.map((i) => i.value)},
        {column: "profile/age", filters: filtersAge.map((i) => i.value)},
        {column: "profile/from", filters: filtersFrom.map((i) => i.value)},
        {column: "profile/frequence", filters: filtersFreq.map((i) => i.value)},
        {column: "profile/vip", filters: filtersVip.map((i) => i.value)},
        {column: "journey/reccuring", filters: filtersRecc.map((i) => i.value)},
      ]));
    } else {
      setFilteredDf(new dfd.DataFrame([]));
    }
  }, [periodDf, filtersSocio, filtersAge, filtersFrom, filtersFreq, filtersVip, filtersRecc]);

  useEffect(() => {
    if (filteredDf?.axis?.columns?.length > 0) {
      setConvDf(dashboardContext.getFilteredDf(filteredDf, [
        {column: "purchase/nb", filters: [0], notEqual: true}
      ]));
      setNoconvDf(dashboardContext.getFilteredDf(filteredDf, [
        {column: "purchase/nb", filters: [0], notEqual: false}
      ]));
      setDataTime(dashboardContext.getNivoItems(filteredDf, "createdAt").sort((a, b) => a.id.localeCompare(b.id)));
    } else {
      setConvDf(new dfd.DataFrame([]));
      setNoconvDf(new dfd.DataFrame([]));
      setDataTime([]);
    }
  }, [filteredDf]);

  useEffect(() => {
    if (convDf?.axis?.columns?.length > 0) {
      setDataConvSocio(dashboardContext.getNivoItems(convDf, "profile/socio").sort((a, b) => a.label.localeCompare(b.label)));
      setDataConvAge(dashboardContext.getNivoItems(convDf, "profile/age").sort((a, b) => a.label.localeCompare(b.label)));
      setDataConvFrom(dashboardContext.getNivoItems(convDf, "profile/from"));
      setDataConvFrequence(dashboardContext.getNivoItems(convDf, "profile/frequence"));
      setDataConvVip(dashboardContext.getNivoItems(convDf, "profile/vip"));
      setDataConvReccuring(dashboardContext.getNivoItems(convDf, "journey/reccuring"));
    } else {
      setDataConvSocio([]);
      setDataConvAge([]);
      setDataConvFrom([]);
      setDataConvFrequence([]);
      setDataConvVip([]);
      setDataConvReccuring([]);
    }
  }, [convDf]);

  useEffect(() => {
    if (noconvDf?.axis?.columns?.length > 0) {
      setDataNoconvSocio(dashboardContext.getNivoItems(noconvDf, "profile/socio").sort((a, b) => a.label.localeCompare(b.label)));
      setDataNoconvAge(dashboardContext.getNivoItems(noconvDf, "profile/age").sort((a, b) => a.label.localeCompare(b.label)));
      setDataNoconvFrom(dashboardContext.getNivoItems(noconvDf, "profile/from"));
      setDataNoconvFrequence(dashboardContext.getNivoItems(noconvDf, "profile/frequence"));
      setDataNoconvVip(dashboardContext.getNivoItems(noconvDf, "profile/vip"));
      setDataNoconvReccuring(dashboardContext.getNivoItems(noconvDf, "journey/reccuring"));
    } else {
      setDataNoconvSocio([]);
      setDataNoconvAge([]);
      setDataNoconvFrom([]);
      setDataNoconvFrequence([]);
      setDataNoconvVip([]);
      setDataNoconvReccuring([]);
    }
  }, [noconvDf]);

	return (<>
		{/* <h2 style={{width: "100%", textAlign: "center"}}>{i18n.t(appConfig.AID + ".views." + OverviewView.AVID + ".name", {defaultValue: ""})}</h2> */}
		<PeriodToolBar
			period={period}
			setPeriod={setPeriod}
		/>
    <ToolBar
      title={i18n.t("shared.filters")}
    ><>
      <MultiSelect
        id="filterStore"
        title="Boutiques"
        placeholder="Toutes"
        maxGrow="100%"
        options={optionsStore}
        selected={filtersStore as ReactSelectInterface[]}
        setSelected={(s) => {
          setFiltersStore(s);
          !props.dual && dashboardContext.setCurrentCSIDs(s);
        }}
        // selected={dashboardContext.currentCSIDs as ReactSelectInterface[]}
        // setSelected={(s) => dashboardContext.setCurrentCSIDs(s)}
      />
      <MultiSelect
        id="filterSocio"
        title="Sexes"
        placeholder="Tous"
        maxGrow="33%"
        options={optionsSocio}
        selected={filtersSocio as ReactSelectInterface[]}
        setSelected={(s) => setFiltersSocio(s)}
      />
      <MultiSelect
        id="filterAge"
        title="Ages"
        placeholder="Tous"
        maxGrow="33%"
        options={optionsAge}
        selected={filtersAge as ReactSelectInterface[]}
        setSelected={(s) => setFiltersAge(s)}
      />
      <MultiSelect
        id="filterFrom"
        title="Pays"
        placeholder="Tous"
        maxGrow="33%"
        options={optionsFrom}
        selected={filtersFrom as ReactSelectInterface[]}
        setSelected={(s) => setFiltersFrom(s)}
      />
      <MultiSelect
        id="filterFreq"
        title="Fréquentations"
        placeholder="Toutes"
        maxGrow="33%"
        options={optionsFreq}
        selected={filtersFreq as ReactSelectInterface[]}
        setSelected={(s) => setFiltersFreq(s)}
      />
      <MultiSelect
        id="filterVip"
        title="Membres Printania"
        placeholder="Tous"
        maxGrow="33%"
        options={optionsVip}
        selected={filtersVip as ReactSelectInterface[]}
        setSelected={(s) => setFiltersVip(s)}
      />
      <MultiSelect
        id="filterRecc"
        title="Clients récurrents"
        placeholder="Tous"
        maxGrow="33%"
        options={optionsRecc}
        selected={filtersRecc as ReactSelectInterface[]}
        setSelected={(s) => setFiltersRecc(s)}
      />
    </></ToolBar>
		<section className="lc-section">
			<h5 style={{width: "100%", textAlign: "center"}}>
				{i18n.t(appConfig.AID+".views."+OverviewView.AVID+".title")}
			</h5>
      <div style={{height: "240px", width: "100%"}}>
				<ResponsiveBar
					data={dataTime as NivoDataWOChildrenInteface[]}
					indexBy="label"
					colorBy="indexValue"
					label={(i) => dataTime.length > 31 ? "" : !i.value || i.value === 0 || isNaN(i.value) ? "" : i.value +""}
					labelTextColor={labelLight}
					gridYValues={2}
					axisLeft={{
						tickSize: 0,
						tickPadding: 2,
						tickRotation: 0,
						format: (v) => v !== 0 && v % 10 === 0 ? v : "",
					}}
					axisBottom={{
						tickSize: 0,
						tickPadding: 2,
						tickRotation: 0,
						format: (v) => v === dataTime[0]?.label || v === dataTime[dataTime.length - 1]?.label ? v : "",
					}}
					colors={(item: {indexValue: string | number}) => getDayColor(item.indexValue + "")}
          isInteractive={true}
          tooltip={(item) => <small style={{
            padding: 8, color: "var(--dark)", backgroundColor: "var(--lightEE)",
            boxShadow: "1px 1px 2px var(--dark88)", borderRadius: 8
          }}>{getDayLabel(item.indexValue + "") + " : "}<span style={{fontWeight: "bold"}}>{item.value}</span></small>}
					margin={{top: 10, right: 50, bottom: 40, left: 50}}
          // onClick={(item) => console.log(getDayLabel(item.indexValue + ""))}
				/>
			</div>
			<h5 style={{width: "100%", textAlign: "center"}}>
				{"Temps de réponse"}
			</h5>
      <small style={{flex: "1 0 50%", textAlign: "center", paddingBottom: 8}}>{"Moyen : " + average + "s"}</small>
      <small style={{flex: "1 0 50%", textAlign: "center", paddingBottom: 8}}>{"Median : " + median + "s"}</small>
    </section>
		<section className="lc-section">
      <h5 style={{width: "100%", textAlign: "center"}}>
        {i18n.t(appConfig.AID+".views."+OverviewView.AVID+".title2")}
      </h5>
      <DoublePie
        title={"Sexes"}
        dataTop={dataConvSocio}
        dataBottom={dataNoconvSocio}
        flex="1 0 400px"
        onClick={(item) => setFiltersSocio(updateFilters(item, filtersSocio))}
      />
      <DoublePie
        title={"Ages"}
        dataTop={dataConvAge}
        dataBottom={dataNoconvAge}
        flex="1 0 400px"
        onClick={(item) => setFiltersAge(updateFilters(item, filtersAge))}
      />
      <DoublePie
        title={"Pays"}
        dataTop={dataConvFrom}
        dataBottom={dataNoconvFrom}
        flex="1 0 400px"
        onClick={(item) => setFiltersFrom(updateFilters(item, filtersFrom))}
      />
      <DoublePie
        title={"Fréquentations"}
        dataTop={dataConvFrequence}
        dataBottom={dataNoconvFrequence}
        flex="1 0 400px"
        onClick={(item) => setFiltersFreq(updateFilters(item, filtersFreq))}
      />
      <DoublePie
        title={"Membres Printania"}
        dataTop={dataConvVip}
        dataBottom={dataNoconvVip}
        flex="1 0 400px"
        onClick={(item) => setFiltersVip(updateFilters(item, filtersVip))}
      />
      <DoublePie
        title={"Clients réccurents"}
        dataTop={dataConvReccuring}
        dataBottom={dataNoconvReccuring}
        flex="1 0 400px"
        onClick={(item) => setFiltersRecc(updateFilters(item, filtersRecc))}
      />
		</section>
	</>)
};

const OverviewView : ViewInterface = {
  AVID: "overview",
  component: (props?: {dual?: boolean}) => <Overview dual={!!props?.dual} />,
  url: "/overview",
  icon: <AccountCircleIcon />
}

export default OverviewView;