// React
import React from 'react';
// Interfaces
import { I18nLng } from 'livecrew-interfaces';
// Contexts
import { useI18nContext } from '../../contexts/i18n';
// CSS
import "./index.css";

export default function LocaleSelect() {
  const i18n = useI18nContext();

	return (
    <select name="locale-select" id="locale-select" className="lc-localeselect"
      value={i18n.getLocale()}
      onChange={(e) => i18n.setLocale(e.target.value as I18nLng)}
    >
      {i18n.getAvailableLocales().map((locale) => <option
        key={locale}
        value={locale}
      >
        {locale}
      </option>)}
    </select>
	)
}