// React
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
// MUI
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
// Interfaces
import { ViewInterface } from '../interfaces';
// Contexts
import { useTrackPageView } from '../contexts/analytics';
import { useI18nContext } from '../contexts/i18n';
// Components
import Widget from '../components/Widget';
// CSS
import "./index.css";

const DPA = () => {
	const i18n = useI18nContext();

  useTrackPageView("legal", "dpa");
  
	return (<div className="lc-app app-legal">
    <Link
      key={"link-legal"}
      to={"/legal"}
      className="lc-buttonicon"
      style={{position: "absolute", left: 0}}
    ><ArrowBackRoundedIcon /></Link>
		<Widget
      title={i18n.t("legal.views." + DPAView.AVID + ".name")}
			isFocused={true}
      maxHeight="unset"
      maxWidth="800px"
			width="100%"
		>
      <div className="lc-legaldoc">
        <hr style={{padding: "0px 8px"}} />
        <small>Entre</small>
        <small><span style={{fontWeight: 500}}>LiveCrew SAS</span>, situé à 3 boulevard de Sébastopol, 75001 Paris, France</small>
        <small>et représenté par <span style={{fontWeight: 500}}>Jonathan Dory</span>.</small>
        <small>(ci-après, « le responsable de traitement »)</small>
        <small>Et,</small>
        <small><span style={{fontWeight: 500}}>InnoCraft Ltd</span>, situé à 7 Waterloo Quay, PO Box 625, 6140 Wellington, Nouvelle-Zélande</small>
        <small>et représenté en UE par <span style={{fontWeight: 500}}>Prof. Dr. Christoph Bauer</span>, Große Bleichen 21, 20354 Hambourg, Allemagne</small>
        <small>(ci-après, « le sous-traitant »)</small>
        <br />
        <h4 style={{padding: "4px"}}><span style={{color: "var(--interactive)", paddingRight: "4px"}}>I.</span>Objet</h4>
        <small>Les présentes clauses ont pour objet de définir les conditions dans lesquelles le sous-traitant s’engage à effectuer pour le compte du responsable de traitement les opérations de traitement de données à caractère personnel définies ci-après.</small>
        <small>Dans le cadre de leurs relations contractuelles, les parties s’engagent à respecter la réglementation en vigueur applicable au traitement de données à caractère personnel et, en particulier, le règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 applicable à compter du 25 mai 2018 (ci-après, « le règlement européen sur la protection des données »).</small>
        <br />
        <h4 style={{padding: "4px"}}><span style={{color: "var(--interactive)", paddingRight: "4px"}}>II.</span>Description du traitement</h4>
        <small>Le sous-traitant est autorisé à traiter pour le compte du responsable de traitement les données à caractère personnel nécessaires aux mesures d'audience de la solution LiveCrew et leurs analyses.</small>
        <small>La nature des opérations réalisées sur les données est : collecte, enregistrement, conservation, modification, extraction, consultation, utilisation, et destruction.</small>
        <small>La finalité du traitement est la suivante : mesurer et analyser l'audience, les performances, et la sécurité de la solution LiveCrew, dans le seul et unique but d'améliorer le service fourni par le responsable de traitement.</small>
        <small>Les données à caractère personnel traitées sont :</small>
        <ul>
          <li>adresse IP anonymisée de l'utilisateur (ex: 192.168.xxx.xxx)</li>
          <li>géolocalisation (pays, région, ville et longitude/latitude imprécises)</li>
          <li>horodatage</li>
          <li>temps passé sur la page</li>
          <li>titre de la page</li>
          <li>url de la page (protocole, domaine et chemin uniquement)</li>
          <li>url de la page vue précédement (protocole, domaine et chemin uniquement)</li>
          <li>temps de génération de la page</li>
          <li>lien utilisé vers un autre domaine</li>
          <li>résolution d'écran</li>
          <li>langage du navigateur utilisé</li>
          <li>agent utilisateur du navigateur utilisé (navigateur, système d'opération, marque et modèle de l'appareil)</li>
          <li>identifiant de visite (persiste 30min après la dernière interaction, n'est pas à une empreinte digitale du navigateur <a href="https://matomo.org/faq/general/how-is-the-visitor-config_id-processed/" target="_blank" rel="noopener noreferrer"><LaunchRoundedIcon fontSize="inherit" style={{transform: "translateY(3px)"}} /></a>)</li>
        </ul>
        <small>Les catégories de personnes concernées sont tous les utilisateurs finaux du domaine live-crew.com</small>
        <small>Pour l’exécution du service objet du présent contrat, le responsable de traitement met à la disposition du sous-traitant les informations nécessaires suivantes :</small>
        <ul>
          <li>nom, adresses emails et informations concernant le responsable de traitement</li>
          <li>nom de domaine pour lequel la prestation de services est fournie</li>
        </ul>
        <br />
        <h4 style={{padding: "4px"}}><span style={{color: "var(--interactive)", paddingRight: "4px"}}>III.</span>Durée du contrat</h4>
        <small>Le présent contrat entre en vigueur à compter du 10 Mai 2022, jusqu'à sa modification ou l'arrivée à terme de la prestation de services.</small>
        <br />
        <h4 style={{padding: "4px"}}><span style={{color: "var(--interactive)", paddingRight: "4px"}}>IV.</span>Obligations du sous-traitant vis-à-vis du responsable de traitement</h4>
        <ol>
          <li>
            <small style={{fontWeight: 500}}>Le sous-traitant s'engage à :</small>
            <ul>
              <li>traiter les données uniquement pour la seule finalité qui fait l’objet de la sous-traitance</li>
              {/* <li>traiter les données conformément aux instructions documentées du responsable de traitement figurant en annexe du présent contrat. Si le sous-traitant considère qu’une instruction constitue une violation du règlement européen sur la protection des données ou de toute autre disposition du droit de l’Union ou du droit des Etats membres relative à la protection des données, il en informe immédiatement le responsable de traitement. En outre, si le sous-traitant est tenu de procéder à un transfert de données vers un pays tiers ou à une organisation internationale, en vertu du droit de l’Union ou du droit de l’Etat membre auquel il est soumis, il doit informer le responsable du traitement de cette obligation juridique avant le traitement, sauf si le droit concerné interdit une telle information pour des motifs importants d'intérêt public</li> */}
              <li>prendre en compte, s’agissant de ses outils, produits, applications ou services, les principes de protection des données dès la conception et de protection des données par défaut</li>
              <li>garantir la confidentialité des données à caractère personnel traitées dans le cadre du présent contrat</li>
              <li>veiller à ce que les personnes autorisées à traiter les données à caractère personnel en vertu du présent contrat :</li>
              <ul>
                <li>s’engagent à respecter la confidentialité ou soient soumises à une obligation légale appropriée de confidentialité</li>
                <li>reçoivent la formation nécessaire en matière de protection des données à caractère personnel</li>
              </ul>
            </ul>
          </li>
          <li>
            <small style={{fontWeight: 500}}>Sous-traitance</small>
            <ul>
              <li>Le sous-traitant peut faire appel à un autre sous-traitant (ci-après, « le sous-traitant ultérieur ») pour mener des activités de traitement spécifiques. Dans ce cas, il informe préalablement par courrier électronique le responsable de traitement de tout changement envisagé concernant l’ajout ou le remplacement d’autres sous-traitants. Cette information doit indiquer clairement les activités de traitement sous-traitées, l’identité et les coordonnées du sous-traitant et les dates du contrat de sous-traitance. Le responsable de traitement dispose d’un délai minium de trente (30) jours à compter de la date de réception de cette information pour présenter ses objections. Cette sous-traitance ne peut être effectuée que si le responsable de traitement n'a pas émis d'objection pendant le délai convenu.</li>
              <li>Le sous-traitant ultérieur est tenu de respecter les obligations du présent contrat pour le compte et selon les instructions du responsable de traitement. Il appartient au sous-traitant initial de s’assurer que le sous-traitant ultérieur présente les mêmes garanties suffisantes quant à la mise en œuvre de mesures techniques et organisationnelles appropriées de manière à ce que le traitement réponde aux exigences du règlement européen sur la protection des données. Si le sous-traitant ultérieur ne remplit pas ses obligations en matière de protection des données, le sous-traitant initial demeure pleinement responsable devant le responsable de traitement de l’exécution par l’autre sous-traitant de ses obligations.</li>
            </ul>
          </li>
          <li>
            <small style={{fontWeight: 500}}>Droit d’information des personnes concernées</small>
            <ul>
              <li>Il appartient au responsable de traitement de fournir l’information aux personnes concernées par les opérations de traitement au moment de la collecte des données.</li>
            </ul>
          </li>
          <li>
            <small style={{fontWeight: 500}}>Exercice des droits des personnes</small>
            <ul>
              <li>Dans la mesure du possible, le sous-traitant doit aider le responsable de traitement à s’acquitter de son obligation de donner suite aux demandes d’exercice des droits des personnes concernées : droit d’accès, de rectification, d’effacement et d’opposition, droit à la limitation du traitement, droit à la portabilité des données, droit de ne pas faire l’objet d’une décision individuelle automatisée (y compris le profilage).</li>
              <li>Lorsque les personnes concernées exercent auprès du sous-traitant des demandes d’exercice de leurs droits, le sous-traitant doit adresser ces demandes dès réception par courrier électronique au responsable de traitement.</li>
            </ul>
          </li>
          <li>
            <small style={{fontWeight: 500}}>Notification des violations de données à caractère personnel</small>
            <ul>
              <li>Le sous-traitant notifie par courrier électronique au responsable de traitement toute violation de données à caractère personnel sans délai indu après en avoir pris connaissance.</li>
              <li>Cette notification est accompagnée de toute documentation utile afin de permettre au responsable de traitement, si nécessaire, de notifier cette violation à l’autorité de contrôle compétente.</li>
            </ul>
          </li>
          <li>
            <small style={{fontWeight: 500}}>Aide du sous-traitant dans le cadre du respect par le responsable de traitement de ses obligations</small>
            <ul>
              <li>Le sous-traitant aide le responsable de traitement pour la réalisation d’analyses d’impact relative à la protection des données.</li>
              <li>Le sous-traitant aide le responsable de traitement pour la réalisation de la consultation préalable de l’autorité de contrôle.</li>
            </ul>
          </li>
          <li>
            <small style={{fontWeight: 500}}>Mesures de sécurité</small>
            <ul>
              <li>Le sous-traitant s’engage à mettre en œuvre les mesures de sécurité prévues par les articles 28(3)(c), 32 et 5(1 et 2) du règlement européen sur la protection des données.</li>
              <li>Le sous-traitant s’engage à mettre en œuvre les mesures de sécurité décrites dans l'<a href="https://fr.matomo.org/matomo-cloud-dpa/" target="_blank" rel="noopener noreferrer">appendice 1 de son Accord de traitement des données.<LaunchRoundedIcon fontSize="inherit" style={{paddingLeft: "4px", transform: "translateY(3px)"}} /></a></li>
            </ul>
          </li>
          <li>
            <small style={{fontWeight: 500}}>Sort des données</small>
            <ul>
              <li>Au terme de la prestation de services relatifs au traitement de ces données, le sous-traitant s’engage à détruire toutes les données dans les 30 jours.</li>
            </ul>
          </li>
          <li>
            <small style={{fontWeight: 500}}>Délégué à la protection des données</small>
            <ul>
              <li>Le sous-traitant communique au responsable de traitement le nom et les coordonnées de son délégué à la protection des données, s’il en a désigné un conformément à l’article 37 du règlement européen sur la protection des données.</li>
            </ul>
          </li>
          {/* <li>
            <small style={{fontWeight: 500}}>Registre des catégories d’activités de traitement</small>
            <ul>
              <li><b style={{color: "red"}}>[A remplir ... ou à supprimer entièrement ?]</b></li>
            </ul>
          </li> */}
          <li>
            <small style={{fontWeight: 500}}>Documentation</small>
            <ul>
              <li>Le sous-traitant met à la disposition du responsable de traitement la documentation nécessaire pour démontrer le respect de toutes ses obligations et pour permettre la réalisation d'audits, y compris des inspections, par le responsable du traitement ou un autre auditeur qu'il a mandaté, et contribuer à ces audits.</li>
            </ul>
          </li>
        </ol>
        <br />
        <h4 style={{padding: "4px"}}><span style={{color: "var(--interactive)", paddingRight: "4px"}}>V.</span>Obligations du responsable de traitement vis-à-vis du sous-traitant</h4>
        <small>Le responsable de traitement s’engage à :</small>
        <ul>
          <li>fournir au sous-traitant les données visées à l'article <span style={{color: "var(--interactive)", paddingRight: "4px"}}>II.</span> des présentes clauses</li>
          <li>documenter par écrit toute instruction concernant le traitement des données par le sous-traitant</li>
          <li>veiller, au préalable et pendant toute la durée du traitement, au respect des obligations prévues par le règlement européen sur la protection des données de la part du sous-traitant</li>
          <li>superviser le traitement, y compris réaliser les audits et les inspections auprès du sous-traitant</li>
        </ul>
      </div>
		</Widget>
	</div>)
}

const DPAView : ViewInterface = {
  AVID: "dpa",
  component: (props?: {dual?: boolean}) => <DPA />,
  url: "/dpa",
  icon: <AccountCircleIcon />,
}

export default DPAView;