// React
import React from 'react';
// Interfaces
import { ViewInterface } from '../../interfaces';
// CSS
import "./index.css";
// AppConfig
import appConfig from './appConfig';
// Contexts
import { useTrackPageView } from '../../contexts/analytics';

export default function AppWrapper({view} : {view: ViewInterface}) {
	useTrackPageView(appConfig.AID, view.AVID);

	return (
		<div className="lc-app app-website">
			{view && view.component()}
		</div>
	)
}