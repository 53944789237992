// React
import React, { useState, useLayoutEffect  } from 'react';
import { utils, writeFileXLSX } from 'xlsx';
// Interfaces
import { functions, ConfigAdminInterface, StoreInterface, UID, CSID, AuthInterface, ProductCatalogInterface, QuestionnaireInterface, QuestConfigInterface, SurveyInterface, SurveyConfigInterface, TID, QuestFeedbackInterface, CompanyInterface } from 'livecrew-interfaces';
import { AdminContextInterface } from "../interfaces";
// Backend
import { db, api } from "../backend"
// Contexts
import { useI18nContext } from './i18n';
import { useUserContext } from './user';
import { useCompanyContext } from './company';
import { Unsubscribe } from 'firebase/firestore';

/** 
 * Ref to the user context, do NOT expose directly
 * @see AdminProvider to provide the context
 * @see useAdminContext to subscribe to the context
 */
const AdminContext = React.createContext<AdminContextInterface>({} as AdminContextInterface);

/**
 * Provides the user context to its children
 * @param {JSX} children React children props
 */
const AdminProvider = ({children}: {children: JSX.Element}) => {
  const i18n = useI18nContext();
  const userContext = useUserContext();
  const companyContext = useCompanyContext();
  const [isLoading, setIsLoading] = useState(false);
	const [configAdmin, setConfigAdmin] = useState({} as ConfigAdminInterface);
	const [configAdminDb, setConfigAdminDb] = useState({} as ConfigAdminInterface);
  const [stores, setStores] = useState([] as StoreInterface[]);
  const [storesDb, setStoresDb] = useState([] as StoreInterface[]);
  const [catalogs, setCatalogs] = useState([] as ProductCatalogInterface[]);
  const [catalogsDb, setCatalogsDb] = useState([] as ProductCatalogInterface[]);
  const [questionnaires, setQuestionnaires] = useState([] as QuestionnaireInterface[]);
  const [questionnairesDb, setQuestionnairesDb] = useState([] as QuestionnaireInterface[]);
  const [questionnairesConfigs, setQuestionnairesConfigs] = useState([] as QuestConfigInterface[]);
  const [questionnairesConfigsDb, setQuestionnairesConfigsDb] = useState([] as QuestConfigInterface[]);
  const [surveys, setSurveys] = useState([] as SurveyInterface[]);
  const [surveysDb, setSurveysDb] = useState([] as SurveyInterface[]);
  const [surveysConfigs, setSurveysConfigs] = useState([] as SurveyConfigInterface[]);
  const [surveysConfigsDb, setSurveysConfigsDb] = useState([] as SurveyConfigInterface[]);

  const updateCompany = (categories: CompanyInterface["categories"] | null, brands: CompanyInterface["brands"] | null) => {
    setIsLoading(true);
    // console.log({
    //   CID: companyContext.CID,
    //   name: companyContext.name,
    //   categories: categories || companyContext.categories,
    //   brands: brands || companyContext.brands,
    //   stores: companyContext.stores
    // })
    db.companies.update(
      {
        CID: companyContext.CID,
        name: companyContext.name,
        categories: categories || companyContext.categories,
        brands: brands || companyContext.brands,
        stores: companyContext.stores
      },
      () => setIsLoading(false),
      (errorCode) => {setIsLoading(false); console.log(errorCode)}
    )
  }

  const updateInvitation = (action: "generate" | "delete", onError: (errorCode: string) => void) => {
    setIsLoading(true);
    api.updateInvitation({action: action})
        .then(() => setIsLoading(false))
        .catch((err) => {setIsLoading(false); onError("errors.invitation.00"); console.log(err)});
    // api.init()
    //   .then(() => console.log("Init done"))
    //   .catch((errors) => console.log(errors))
  };

  const getStores = () => stores?.length > 0 ? [...stores] : [];

  const getCatalogs = () => catalogs?.length > 0 ? [...catalogs] : [];

  const getQuestionnaires = () => questionnaires?.length > 0 ? [...questionnaires] : [];

  const getQuestionnairesConfigs = (TID?: TID) => questionnairesConfigs?.length > 0
    ? TID
      ? questionnairesConfigs.filter((c) => c.TID === TID)
      : [...questionnairesConfigs]
    : [];

  const getSurveys = () => surveys?.length > 0 ? [...surveys] : [];

  const getSurveysConfigs = (TID?: TID) => surveysConfigs?.length > 0
    ? TID
      ? surveysConfigs.filter((c) => c.TID === TID)
      : [...surveysConfigs]
    : [];

  const set = (opt: {state: "users", id: UID, data: AuthInterface} | {state: "stores", id: CSID, data: StoreInterface} | {state: "catalogs", id: CSID, data: ProductCatalogInterface}) => {
    switch (opt.state) {
      case "users":
        const oldUser = configAdmin?.users?.find((u) => u.UID === opt.id) || null;
        if (oldUser && oldUser.UID === opt.data.UID) {
          const newUsers = configAdmin.users.filter((u) => u.UID !== opt.id);
          newUsers.push(opt.data);
          setConfigAdmin((oldState) => {return {IID: oldState.IID, users: newUsers}})
        };
        break;
      case "stores":
        const newStores = stores.filter((s) => s.CSID !== opt.id);
        newStores.push(opt.data);
        setStores(newStores);
        break;
      case "catalogs":
        const newCatalogs = catalogs.filter((c) => c.PID !== opt.id);
        newCatalogs.push(opt.data);
        setCatalogs(newCatalogs);
        break;
    }
  };

  const needsUpdate = (state: "users" | "stores" | "catalogs", id?: string) => {
    switch (state) {
      case "users":
        const user = configAdmin?.users?.find((u) => u.UID === id) || null;
        const userDb = configAdminDb?.users?.find((u) => u.UID === id) || null;
        return user && userDb && user.CSID === userDb.CSID
          && user.permissions.reduce((acc, val) => userDb.permissions.includes(val) && acc, true)
          && userDb.permissions.reduce((acc, val) => user.permissions.includes(val) && acc, true)
            ? false
            : true;
      case "stores":
        if (id) {
          const store = stores.find((s) => s.CSID === id) || null;
          const storeEntries = store ? Object.entries(store) : null;
          const storeDb = storesDb.find((s) => s.CSID === id) || null;
          const storeDbEntries = storeDb ? Object.entries(storeDb) : null;
          return storeEntries && storeDbEntries
            && storeEntries.reduce((acc, val) => (val[1] === storeDbEntries.find((s) => s[0] === val[0])?.[1]) && acc,true)
            && storeDbEntries.reduce((acc, val) => (val[1] === storeEntries.find((s) => s[0] === val[0])?.[1]) && acc,true)
              ? false
              : true;
        } else {
          return stores.reduce((accu, store) => {
            const storeEntries = store ? Object.entries(store) : null;
            const storeDb = storesDb.find((s) => s.CSID === store.CSID) || null;
            const storeDbEntries = storeDb ? Object.entries(storeDb) : null;
            return storeEntries && storeDbEntries
              && storeEntries.reduce((acc, val) => (val[1] === storeDbEntries.find((s) => s[0] === val[0])?.[1]) && acc,true)
              && storeDbEntries.reduce((acc, val) => (val[1] === storeEntries.find((s) => s[0] === val[0])?.[1]) && acc,true)
                ? true && accu
                : false;
          }, true)
            ? false
            : true;
        }
      case "catalogs":
        if (id) {
          const catalog = catalogs.find((c) => c.PID === id) || null;
          const catalogDb = catalogsDb.find((c) => c.PID === id) || null;
          return catalog && catalogDb && catalog.products && catalogDb.products && catalog.products.length === catalogDb.products.length
            && catalog.products.reduce((acc, val) => (catalogDb.products.findIndex((p) => p.PBID === val.PBID && p.PCID === val.PCID) !== -1) && acc,true)
            && catalogDb.products.reduce((acc, val) => (catalog.products.findIndex((p) => p.PBID === val.PBID && p.PCID === val.PCID) !== -1) && acc,true)
              ? false
              : true;
        } else {
          return catalogs.reduce((accu, catalog) => {
            const catalogDb = catalogsDb.find((c) => c.PID === catalog.PID) || null;
            return catalog && catalogDb && catalog.products && catalogDb.products && catalog.products.length === catalogDb.products.length
            && catalog.products.reduce((acc, val) => (catalogDb.products.findIndex((p) => p.PBID === val.PBID && p.PCID === val.PCID) !== -1) && acc,true)
            && catalogDb.products.reduce((acc, val) => (catalog.products.findIndex((p) => p.PBID === val.PBID && p.PCID === val.PCID) !== -1) && acc,true)
                ? true && accu
                : false;
          }, true)
            ? false
            : true;
        }
      default:
        return false
    }
  };

  const update = (state: "users" | "stores" | "catalogs", id: string, onError: (errorCode: string) => void) => {
    switch (state) {
      case "users":
        const user = configAdmin?.users?.find((u) => u.UID === id) || null;
        if (user) {
          setIsLoading(true);
          api.setUser({UID: user.UID, CSID: user.CSID, permissions: user.permissions})
              .then(() => {
                setIsLoading(false);
                (user.UID === userContext.UID) && userContext.logout(() => {});
              })
              .catch((errors) => {setIsLoading(false); console.log(errors)})
        } else {
          onError("No user found");
        }
        break;
      case "stores":
        if (id === "") {
          setIsLoading(true);
          db.companies.stores.update(
            userContext.CID,
            stores.filter((s) => needsUpdate("stores", s.CSID)),
            () => setIsLoading(false),
            (errorCode) => {setIsLoading(false); onError(errorCode)},
          );
        } else {
          
        }
        break;
      case "catalogs":
        if (id === "") {
          setIsLoading(true);
          db.companies.catalogs.update(
            userContext.CID,
            catalogs.filter((c) => needsUpdate("catalogs", c.PID)),
            () => setIsLoading(false),
            (errorCode) => {setIsLoading(false); onError(errorCode)},
          );
        } else {
          
        }
        break;
      default:
        onError("Nothing to do");
        break;
    }
  };

  const cancel = (state: "users" | "stores" | "catalogs") => {
    switch (state) {
      case "users":
        setConfigAdmin(JSON.parse(JSON.stringify(configAdminDb)));
        break;
      case "stores":
        setStores(JSON.parse(JSON.stringify(storesDb)));
        break;
      case "catalogs":
        setCatalogs(JSON.parse(JSON.stringify(catalogsDb)));
        break;
    }
  };

  const exportFeedbacks = (feedbacks: QuestFeedbackInterface[]) => {
    // const {views} = functions.mapTemplate(storeContext.questTemplate, storeContext.questConfig);
    // const header = [] as string[];
    // views.forEach((v) => v.order.forEach((o) => header.push(functions.getLabel(views, i18n.getLocale(), v.TDID, o))));
    // views.forEach((v) => v.order.forEach((o) => header.push(v.TDID+"/"+o)));
    const workbook = utils.book_new();
    const storeWorksheets = stores.filter((s) => !s.demo).map((s) => {
      const template = questionnairesDb.find((t) => t.TID === s.questTID);
      const config = questionnairesConfigsDb.find((c) => c.TCID === s.questTCID);
      if (template && config) {
        const {views} = functions.mapTemplate(template, config);
        const header = ["date"] as string[];
        views.forEach((v) => v.order.forEach((o) => header.push(functions.getLabel(views, i18n.getLocale(), v.TDID, o))));
        return {
          CSID: s.CSID,
          name: s.name,
          worksheet: utils.json_to_sheet(
            feedbacks.filter((f) => f.CSID === s.CSID).map((f) => Object.entries(f.data).reduce((acc, [key, val]) => {
              const [TDID, TDKID] = key.split("/");
              const question = views.find((v) => v.TDID === TDID)?.keys[TDKID];
              return {...acc, date: f.createdAt, [functions.getLabel(views, i18n.getLocale(), TDID, TDKID)]: val === null || !question
                ? null
                : question.type === "range" || question.type === "free"
                  ? val
                  : question.type === "number" && Number.isInteger(val)
                    ? val < 0 ? "Plus de " + (-1*(val as number)) : val
                    : question.type === "brand" || question.type === "brand-full"
                      ? companyContext.getBrand(val + "")?.label || val
                      : question.type === "category" || question.type === "category-full"
                        ? companyContext.getCategory(val + "")?.label || val
                        : question.type === "product" && Array.isArray(val) && val.length === 2
                          ? (companyContext.getCategory(val[0] + "")?.label || val[0]) + " / " + (companyContext.getBrand(val[1] + "")?.label || val[1])
                          : functions.getLabel(views, "fr-FR", TDID, TDKID, val)}
            }, {} as Record<string,any>)),
            {header: header}
          )
        }
      } else {
        return {
          CSID: "",
          name: "",
          worksheet: utils.json_to_sheet([{feedbacks: "No data"}],{header: ["feedbacks"]})
        }
      }
    });
    storeWorksheets.forEach((s) => utils.book_append_sheet(workbook, s.worksheet, s.name));
    writeFileXLSX(workbook, "lc-feedbacks.xlsx", {bookType: "xlsx"});
  };

  const accessors = {
    configAdmin,
    isLoading,
    updateCompany,
    updateInvitation,
    set,
    needsUpdate,
    update,
    cancel,
    getStores,
    getCatalogs,
    getQuestionnaires,
    getQuestionnairesConfigs,
    getSurveys,
    getSurveysConfigs,
    exportFeedbacks,
  };

  // useLayoutEffect(() => fetchState(userContext.CID, userContext.permissions), [userContext.CID, userContext.permissions]);

  useLayoutEffect(() => {
    const unsubConfigAdmin = (userContext.CID && userContext.permissions && userContext.permissions.includes("admin"))
      ? db.companies.configs.listenAdmin(
        userContext.CID,
        (ca) => {
          if (ca) {
            setConfigAdmin(ca);
            setConfigAdminDb(JSON.parse(JSON.stringify(ca)));
          }
        }
      ) : undefined;
    return unsubConfigAdmin;
  }, [userContext.CID, userContext.permissions]);

  useLayoutEffect(() => {
    const unsubStores = (userContext.CID && userContext.permissions && userContext.permissions.includes("admin"))
      ? db.companies.stores.listenAll(
        userContext.CID,
        (s) => {
          setStores(s);
          setStoresDb(JSON.parse(JSON.stringify(s)));
        }
      ) : undefined;
    return unsubStores;
  }, [userContext.CID, userContext.permissions]);

  useLayoutEffect(() => {
    const unsubCatalogs = (userContext.CID && userContext.permissions && userContext.permissions.includes("admin"))
      ? db.companies.catalogs.listenAll(
        userContext.CID,
        (c) => {
          setCatalogs(c);
          setCatalogsDb(JSON.parse(JSON.stringify(c)));
        }
      ) : undefined;
    return unsubCatalogs;
  }, [userContext.CID, userContext.permissions]);

  useLayoutEffect(() => {
    const unsubQuestionnaires = (userContext.CID && userContext.permissions && userContext.permissions.includes("admin"))
      ? db.companies.questionnaires.listenAll(
        userContext.CID,
        (qu) => {
          setQuestionnaires(qu);
          setQuestionnairesDb(JSON.parse(JSON.stringify(qu)));
        }
      ) : undefined;
    return unsubQuestionnaires;
  }, [userContext.CID, userContext.permissions]);

  useLayoutEffect(() => {
    const unsubs = [] as Unsubscribe[];
    if (userContext.CID && userContext.permissions && userContext.permissions.includes("admin") && questionnaires.length > 0) {
      questionnaires.forEach((q) => unsubs.push(db.companies.questionnaires.configs.listenAll(
        userContext.CID,
        q.TID,
        (qc) => {
          setQuestionnairesConfigs((oldValue) => {
            const filtered = oldValue.filter((c) => c.TID !== q.TID);
            return filtered.concat(qc);
          });
          setQuestionnairesConfigsDb((oldValue) => {
            const filtered = oldValue.filter((c) => c.TID !== q.TID);
            return filtered.concat(JSON.parse(JSON.stringify(qc)));
          });
        }
      )))
    };
    return unsubs.length > 0
      ? () => unsubs.forEach((u) => u())
      : undefined;
  }, [userContext.CID, userContext.permissions, questionnaires]);

  useLayoutEffect(() => {
    const unsubSurveys = (userContext.CID && userContext.permissions && userContext.permissions.includes("admin"))
      ? db.companies.surveys.listenAll(
        userContext.CID,
        (su) => {
          setSurveys(su);
          setSurveysDb(JSON.parse(JSON.stringify(su)));
        }
      ) : undefined;
    return unsubSurveys;
  }, [userContext.CID, userContext.permissions]);

  useLayoutEffect(() => {
    const unsubs = [] as Unsubscribe[];
    if (userContext.CID && userContext.permissions && userContext.permissions.includes("admin") && surveys.length > 0) {
      surveys.forEach((q) => unsubs.push(db.companies.surveys.configs.listenAll(
        userContext.CID,
        q.TID,
        (qc) => {
          setSurveysConfigs((oldValue) => {
            const filtered = oldValue.filter((c) => c.TID !== q.TID);
            return filtered.concat(qc);
          });
          setSurveysConfigsDb((oldValue) => {
            const filtered = oldValue.filter((c) => c.TID !== q.TID);
            return filtered.concat(JSON.parse(JSON.stringify(qc)));
          });
        }
      )))
    };
    return unsubs.length > 0
      ? () => unsubs.forEach((u) => u())
      : undefined;
  }, [userContext.CID, userContext.permissions, surveys]);

// Do NOT expose state and setState directly in the provider value, ever
	return (
		<AdminContext.Provider value={accessors}>
      {children}
		</AdminContext.Provider>
	)
};

/**
 * Returns the actual value of the user context
 * @example
 * const admin = useAdminContext() // subscribe to the context
 * const users = admin.users // use methods provided to interact with the context
 * @see AdminProvider for the full users of methods
 */
const useAdminContext = () => {
	const context = React.useContext(AdminContext);
	if (context === undefined) {
		throw new Error('No admin context!');
	}
	return context
};

// Do NOT export AdminContext directly, ever
export {
	AdminProvider,
	useAdminContext
};