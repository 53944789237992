// React
import React, { useState, useLayoutEffect, useEffect  } from 'react';
// Interfaces
import { StoreInterface, ProductCatalogInterface, QuestionnaireInterface, QuestConfigInterface, PCID, PBID } from 'livecrew-interfaces';
import { StoreContextInterface } from "../interfaces";
// Backend
import { db } from "../backend"
// Contexts
import { useUserContext } from './user';
import { useCompanyContext } from './company';

/** 
 * Ref to the user context, do NOT expose directly
 * @see StoreProvider to provide the context
 * @see useStoreContext to subscribe to the context
 */
const StoreContext = React.createContext<StoreContextInterface>({} as StoreContextInterface);

/**
 * Provides the user context to its children
 * @param {JSX} children React children props
 */
const StoreProvider = ({children}: {children: JSX.Element}) => {
  const userContext = useUserContext();
  const companyContext = useCompanyContext();
	const [store, setStore] = useState({} as StoreInterface);
  const [products, setProducts] = useState([] as ProductCatalogInterface["products"]);
  const [questTemplate, setQuestTemplate] = useState({} as QuestionnaireInterface);
  const [questConfig, setQuestConfig] = useState({} as QuestConfigInterface);
  const [surveyTemplate, setSurveyTemplate] = useState({} as QuestionnaireInterface);
  const [surveyConfig, setSurveyConfig] = useState({} as QuestConfigInterface);
  const [isLoading, setIsLoading] = useState(true);

	const getStoreList = (field: "PCID" | "PBID", options?: {filtersPCID?: PCID[], filtersPBID?: PBID[]}) => {
		const fields = options && ((options.filtersPCID && options.filtersPCID.length > 0) || (options.filtersPBID && options.filtersPBID.length > 0))
      ? products.filter((p) => (!options.filtersPCID || (options.filtersPCID && options.filtersPCID.includes(p.PCID)))
        && (!options.filtersPBID || (options.filtersPBID && options.filtersPBID.includes(p.PBID)))).map((p) => p[field])
      : products.map((p) => p[field]);
		const filteredFields = fields.filter((v, i) => fields.indexOf(v) === i);
    let items : {label: string, value: string}[] = [];
    switch(field) {
      case "PCID":
        items = companyContext.getCategories();
        break;
      case "PBID":
        items = companyContext.getBrands();
        break;
    }
		return items.filter((i) => filteredFields.includes(i.value));
	};

  // const getProductsList = () => products.map((p) => );

  const accessors = {
    ...store,
    products,
    questTemplate,
    questConfig,
    surveyTemplate,
    surveyConfig,
    isLoading,
    getStoreList,
  };

  useLayoutEffect(() => {
    const unsubStore = (userContext.CID && userContext.CSID && userContext.permissions)
      ? db.companies.stores.listen(
        userContext.CID,
        userContext.CSID,
        (newStore: StoreInterface) => {setStore(newStore); setIsLoading(false)}
      ) : undefined;
    return unsubStore;
  }, [userContext.CID, userContext.CSID, userContext.permissions]);

  // useLayoutEffect(() => fetchState(userContext.CID, userContext.CSID), [userContext.CID, userContext.CSID]);
  useEffect(() => {
    if (userContext.CID && store.PID) {
      db.companies.catalogs.read(
        userContext.CID,
        store.PID,
        (catalog) => {setProducts(catalog.products); setIsLoading(false)},
        (errorCode) => {setProducts([]); setIsLoading(false)},
      )
    }
  }, [userContext.CID, store.PID]);
  useEffect(() => {
    if (userContext.CID && store.questTID) {
      db.companies.questionnaires.read(
        userContext.CID,
        store.questTID,
        (template) => {setIsLoading(false); setQuestTemplate(template)},
        () => setIsLoading(false)
      );
    }
  }, [userContext.CID, store.questTID]);
  useEffect(() => {
    if (userContext.CID && store.questTID && store.questTCID) {
      db.companies.questionnaires.configs.read(
        userContext.CID,
        store.questTID,
        store.questTCID,
        (config) => {setIsLoading(false); setQuestConfig(config)},
        () => setIsLoading(false)
      );
    }
  }, [userContext.CID, store.questTID, store.questTCID]);
  useEffect(() => {
    if (userContext.CID && store.surveyTID) {
      db.companies.surveys.read(
        userContext.CID,
        store.surveyTID,
        (template) => {setIsLoading(false); setSurveyTemplate(template)},
        () => setIsLoading(false)
      );
    }
  }, [userContext.CID, store.surveyTID]);
  useEffect(() => {
    if (userContext.CID && store.surveyTID && store.surveyTCID) {
      db.companies.surveys.configs.read(
        userContext.CID,
        store.surveyTID,
        store.surveyTCID,
        (config) => {setIsLoading(false); setSurveyConfig(config)},
        () => setIsLoading(false)
      );
    }
  }, [userContext.CID, store.surveyTID, store.surveyTCID]);

// Do NOT expose state and setState directly in the provider value, ever
	return (
		<StoreContext.Provider value={accessors}>
      {children}
		</StoreContext.Provider>
	)
};

/**
 * Returns the actual value of the user context
 * @example
 * const store = useStoreContext() // subscribe to the context
 * const CSID = store.CSID // use methods provided to interact with the context
 * @see StoreProvider for the full users of methods
 */
const useStoreContext = () => {
	const context = React.useContext(StoreContext);
	if (context === undefined) {
		throw new Error('No store context!');
	}
	return context
};

// Do NOT export StoreContext directly, ever
export {
	StoreProvider,
	useStoreContext
};