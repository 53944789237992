// React
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// MUI
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// Interfaces
import { ViewInterface } from '../../../interfaces';
// Contexts
import { useI18nContext } from '../../../contexts/i18n';
import { useUserContext } from '../../../contexts/user';
import { useCompanyContext } from '../../../contexts/company';
import { useStoreContext } from '../../../contexts/store';
// AppConfig
import appConfig from '../appConfig';
// Views
import CreateCompanyView from './CreateCompany';
import JoinCompanyView from './JoinCompany';
// Components
import Widget from '../../../components/Widget';

interface BeforeInstallPromptEvent extends Event {
	prompt?: () => void,
	readonly platforms?: Array<string>;
	readonly userChoice?: Promise<{
    outcome: 'accepted' | 'dismissed',
    platform: string
  }>
}

const MyProfile = () => {
	const i18n = useI18nContext();
	const userContext = useUserContext();
	const companyContext = useCompanyContext();
	const storeContext = useStoreContext();
  const navigate = useNavigate();
	// const [message, setMessage] = useState("");
	// const [isDisabled, setIsDisabled] = useState(false);
	// const [deferredPrompt, setDeferredPrompt] = useState(null as BeforeInstallPromptEvent | null)

	// const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
	// 	e.preventDefault();
	// 	setIsDisabled(true);
	// 	userContext.logout((message) => {
	// 		setMessage(message);
	// 		setIsDisabled(false);
	// 	})
	// };

	// const onA2HS = () => {
	// 	if (deferredPrompt && deferredPrompt.prompt && deferredPrompt.userChoice) {
	// 		deferredPrompt.prompt();
	// 		deferredPrompt.userChoice.then((result) => {
	// 			if (result.outcome === 'accepted') {
  //         console.log('User accepted the A2HS prompt');
  //       } else {
  //         console.log('User dismissed the A2HS prompt');
  //       }
  //       setDeferredPrompt(null);
	// 		})
	// 	}
	// };

	// useEffect(() => {
	// 	const handleEvent = (e: BeforeInstallPromptEvent) => {
	// 		e.preventDefault();
	// 		console.log(e);
	// 		setDeferredPrompt(e);
	// 	};
	// 	window.addEventListener("beforeinstallprompt", (e: BeforeInstallPromptEvent) => handleEvent(e));
	// 	return () => window.removeEventListener("beforeinstallprompt", handleEvent);
	// }, [])

	return (<div className="view-user">
		<Widget
      title={i18n.t(appConfig.AID + ".views." + MyProfileView.AVID + ".name")}
			isFocused={true}
			width="100%"
		>
			<form
				// onSubmit={handleSubmit}
			>
        <label htmlFor="email">{i18n.t("shared.userDict.email", {case: "first"})}</label>
        <input
          id="email"
          type="email"
          value={userContext.email}
          disabled={true}
        />
        <label htmlFor="company">{i18n.t("shared.companyDict.company", {case: "first"})}</label>
        <input
          id="company"
          type="text"
          value={userContext.CID && companyContext.name ? companyContext.name : i18n.t("shared.generic.none")}
          disabled={true}
        />
        <label htmlFor="store">{i18n.t("shared.companyDict.store", {case: "first"})}</label>
        <input
          id="store"
          type="text"
          value={userContext.CSID && storeContext.name ? storeContext.name : i18n.t("shared.generic.none")}
          disabled={true}
        />
        <label htmlFor="autorisations">{i18n.t("shared.userDict.autorisations", {case: "first"})}</label>
        <input
          id="autorisations"
          type="text"
          value={userContext.permissions && userContext.permissions.length > 0 ? userContext.permissions : [i18n.t("shared.generic.none")]}
          disabled={true}
        />
			</form>
		</Widget>
		{/* <Widget
      title={"Options"}
			isFocused={true}
			width="100%"
		>
		<form
			// onSubmit={handleSubmit}
		>
			<label htmlFor="a2hs" style={{textAlign: "center"}}>{"Ajouter LiveCrew à l'écran d'accueil"}</label>
			<input
				id="a2hs"
				type="button"
				value={"Ajouter"}
				onClick={onA2HS}
				disabled={!deferredPrompt}
			/>
		</form>
		</Widget> */}
		{!userContext.CID && <div style={{
			width: "100%",
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "space-between",
			alignItems: "center",
			padding: "4px 0px",
		}}>
			<input
				type="button"
				aria-label={i18n.t(appConfig.AID + ".views." + CreateCompanyView.AVID + ".name", {case: "first"})}
				value={i18n.t(appConfig.AID + ".views." + CreateCompanyView.AVID + ".name", {case: "first"})}
				onClick={() => navigate(appConfig.url+CreateCompanyView.url)}
			/>
			<input
				type="button"
				aria-label={i18n.t(appConfig.AID + ".views." + JoinCompanyView.AVID + ".name", {case: "first"})}
				value={i18n.t(appConfig.AID + ".views." + JoinCompanyView.AVID + ".name", {case: "first"})}
				onClick={() => navigate(appConfig.url+JoinCompanyView.url)}
			/>
		</div>}
	</div>)
}

const MyProfileView : ViewInterface = {
  AVID: "myprofile",
  component: (props?: {dual?: boolean}) => <MyProfile />,
  url: "/myprofile",
  icon: <AccountCircleIcon />,
}

export default MyProfileView;