// React
import React from 'react';
// CSS
import "./index.css";

export default function ToogleSwitch(
  {id, checked, onChange}:
  {id: string, checked: boolean, onChange: (state: boolean) => void}
) {

	return (
    <label className="lc-toggleswitch">
      <input id={id} className="lc-toggleswitch-checkbox" type="checkbox" checked={checked} onChange={(e) => onChange(e.target.checked)} />
      <span className="lc-toggleswitch-slider"></span>
    </label>
	)
}