// React
import React, { useState, useEffect } from 'react';
// Interfaces
import { DeviceContextInterface } from "../interfaces";

const INITIAL_STATE = {
  innerHeight: window.innerHeight,
  innerWidth: window.innerWidth,
}

/** 
 * Ref to the device context, do NOT expose directly
 * @see DeviceProvider to provide the context
 * @see useDeviceContext to subscribe to the context
 */
const DeviceContext = React.createContext<DeviceContextInterface>(INITIAL_STATE as DeviceContextInterface);

/**
 * Provides the device context to its children
 * @param {JSX} children React children props
 */
const DeviceProvider = ({children} : {children: JSX.Element}) => {
	const [screenSize, setScreenSize] = useState(INITIAL_STATE);
  const avaliableThemes = ["light", "dark"];
  // const avaliableThemes = ["light", "dark", "xmas"];
  const [currentTheme, setCurrentTheme] = useState(avaliableThemes[0]);

  const isMobile = () => screenSize.innerWidth <= 480;
  const isTablet = () => screenSize.innerWidth > 480 && screenSize.innerWidth <= 768;
  const isLaptop = () => screenSize.innerWidth > 768 && screenSize.innerWidth <= 1024;
  const isDesktop = () => screenSize.innerWidth > 1024;
  const isTouch = () => navigator.maxTouchPoints > 1;
  const getAvailableThemes = () => [...avaliableThemes];
  const getTheme = () => currentTheme ? currentTheme : "";
  const setTheme = (theme: string) => avaliableThemes.includes(theme)
    ? setCurrentTheme(theme)
    : setCurrentTheme(avaliableThemes[0]);
  const is = () => {
    if (isMobile()) return "Mobile";
    if (isTablet()) return "Tablet";
    if (isLaptop()) return "Laptop";
    if (isDesktop()) return "Desktop";
    return ""
  }

	const accessors = {
    ...screenSize,
    isMobile,
    isTablet,
    isLaptop,
    isDesktop,
    isTouch,
    is,
    getAvailableThemes,
    getTheme,
    setTheme,
	};

  useEffect(() => {
    const handleResize = () => setScreenSize({
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
    });
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (window.location.hostname.includes("livecrew-solutions.web.app") || 
    window.location.hostname.includes("livecrew-solutions.firebaseapp.com")) {
      window.location.href = "https://app.live-crew.com/"; 
    }
  }, []);

  // Do NOT expose state and setState directly in the provider value, ever
	return (
		<DeviceContext.Provider value={accessors}>
      <link rel="stylesheet" href={"/themes/"+currentTheme+".css"} />
      {children}
		</DeviceContext.Provider>
	)
};

/**
 * Returns the actual value of the device context
 * @example
 * const device = useDeviceContext() // subscribe to the context
 * const touchscreen = device.isTouch() // use methods provided to interact with the context
 * @see DeviceProvider for the full list of methods
 */
const useDeviceContext = () => {
	const context = React.useContext(DeviceContext);
	if (context === undefined) {
		throw new Error('No device context!');
	}
	return context
};

// Do NOT export DeviceContext directly, ever
export {
	DeviceProvider,
	useDeviceContext
};