// React
import React from 'react';
// Interface
import { AppInterface } from "../../interfaces";
// Config
import appConfig from "./appConfig";
// Wrapper
import AppWrapper from "./AppWrapper";
// CSS
import "./index.css";
// Views
import HomeCrewappView from './views/Home';
import QuestionnaireView from './views/Questionnaire';
import SurveyView from './views/Surveys';

const crewapp : AppInterface = {
  ...appConfig,
  component: (view) => <AppWrapper view={view || HomeCrewappView} />,
  views: [
    HomeCrewappView,
    QuestionnaireView,
    SurveyView,
  ],
};

export default crewapp;