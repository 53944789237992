import { onDocUpdate, getColRef, getDocs, getDocRef, getDoc, setDoc, doc, query } from "../../../firestore";
import { QuestConfigInterface, questConfigsColPath, questConfigDocPath, CID, TID, TCID } from "livecrew-interfaces";

const listenAll = (CID: CID, TID: TID, onChange: (questionnaireConfigs: QuestConfigInterface[]) => void) => onDocUpdate(
  query(getColRef(questConfigsColPath(CID, TID))),
  (querySnap) => {
    const questionnaireConfigs = [] as QuestConfigInterface[];
    querySnap.forEach((doc) => questionnaireConfigs.push(doc.data() as QuestConfigInterface));
    onChange(questionnaireConfigs);
  },
  (error) => onChange([]),
);

const read = (CID: CID, TID: TID, TCID: TCID, onSuccess: (config: QuestConfigInterface) => void, onError: (errorCode: string) => void) => getDoc(getDocRef(questConfigDocPath(CID, TID, TCID)))
    .then((docSnap) => {
      if (docSnap.exists()) {
        onSuccess({...docSnap.data(), TCID: docSnap.id} as QuestConfigInterface);
      } else {
        onError("db.doc.01");
      }
    })
    .catch((error) => {
      console.log(error);
      onError(error.code);
    });

const create = (CID: CID, TID: TID, config: QuestConfigInterface, onSuccess: (TCID: TCID) => void, onError: (errorCode: string) => void) => {
  const newConfig = doc(getColRef(questConfigsColPath(CID, TID)));
  setDoc(newConfig, {...config, TCID: newConfig.id})
      .then(() => onSuccess(newConfig.id))
      .catch((error) => {
        console.log(error);
        onError(error.code);
      });
};
    
const configs = {
  listenAll,
  read,
  create,
};

export default configs;