// React
import React from 'react';
// MUI
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SlideshowTwoToneIcon from '@mui/icons-material/SlideshowTwoTone';
// Interfaces
import { ViewInterface } from '../../../interfaces';
// Contexts
import { useI18nContext } from '../../../contexts/i18n';
import { useAdminContext } from '../../../contexts/admin';
// AppConfig
import appConfig from '../appConfig';
// Components
import StoresList from '../components/StoresList';

const Stores = () => {
	const i18n = useI18nContext();
	const adminContext = useAdminContext();

	return (<>
    <section className="lc-section" style={{flexDirection: "column"}}>
			<div className="lc-section-toolbar">
				<h2 style={{width: "100%", textAlign: "center"}}>{i18n.t(appConfig.AID + ".views." + StoresView.AVID + ".name", {case: "first"})}</h2>
			</div>
			<div style={{display: "flex", flexDirection: "column"}}>
				<div style={{width: "100%", display: "flex", alignItems: "center", color: "var(--dark)"}}>
					<SlideshowTwoToneIcon />
					<small style={{textAlign: "left", padding: 8, color: "var(--dark)"}}>
						{"Démo : les feedbacks soumis par les utilisateurs de cette boutique ne seront enregistrés afin de leur permettre de tester la CrewApp"}
					</small>
				</div>
			</div>
			<StoresList
				stores={adminContext.getStores().sort((a, b) => a.name.localeCompare(b.name)) || []}
			/>
		</section>
	</>)
}

const StoresView : ViewInterface = {
  AVID: "stores",
  component: (props?: {dual?: boolean}) => <Stores />,
  url: "/stores",
  icon: <AccountCircleIcon />,
}

export default StoresView;