import firebaseApp from "../firebase";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";

const functions = getFunctions(firebaseApp, "europe-west3");
// if (window.location.hostname === "localhost") connectFunctionsEmulator(functions, "localhost", 7070);

const createCompany = httpsCallable(functions, "company-create");
const joinCompany = httpsCallable(functions, "company-join");
const setUser = httpsCallable(functions, "company-setuser");
const updateInvitation = httpsCallable(functions, "invitation-update");
const init = httpsCallable(functions, "init-init");
// const updateDb = httpsCallable(functions, "admin-update");

const firebaseFunctions = {
  createCompany,
  joinCompany,
  setUser,
  updateInvitation,
  init,
  // updateDb
};

export default firebaseFunctions;