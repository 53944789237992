// React
import React, { useState, useEffect } from 'react';
import * as dfd from "danfojs";
// MUI
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// Interfaces
import { QuestionnaireInterface, TDID, TDKID, TDKOID } from 'livecrew-interfaces';
import { ViewInterface, ReactDateRangeInterface, ReactSelectInterface, ItemInterface } from '../../../interfaces';
// AppConfig
// import appConfig from "../appConfig";
// Contexts
// import { useDeviceContext } from '../../../contexts/device';
import { useI18nContext } from '../../../contexts/i18n';
import { useCompanyContext } from '../../../contexts/company';
import { useStoreContext } from '../../../contexts/store';
import { useDashboardContext } from '../../../contexts/dashboard';
// Components
import PeriodToolBar from "../components/PeriodToolBar";
import MultiSelect from '../../../components/MultiSelect';

// const labelLight = "#F4F4F8";
const labelDark = "#222222";

const fullOrder = ["[lc]true", "[lc]false", "[lc]other", "[lc]idk", "[lc]null"];

const AllAnswers = (props: {dual?: boolean}) => {
  // const deviceContext = useDeviceContext();
  const i18n = useI18nContext();
  const companyContext = useCompanyContext();
  const storeContext = useStoreContext();
  const dashboardContext = useDashboardContext();
  
	const [period, setPeriod] = useState({} as ReactDateRangeInterface);
  const [periodDf, setPeriodDf] = useState(new dfd.DataFrame([]));
  const [filteredDf, setFilteredDf] = useState(new dfd.DataFrame([]));

  const optionsStore = companyContext.getStores().length > 0 ? companyContext.getStores().concat({label: "Toutes", value: ""}) : [{label: storeContext.name, value: storeContext.CSID}];
  const [filtersStore, setFiltersStore] = useState(props.dual ? [] as ItemInterface[] : dashboardContext.currentCSIDs);

  const [filters, setFilters] = useState([] as {TDID: TDID, TDKID: TDKID, answers: {TDKOID: TDKOID | number, label: string}[]}[]);
  const [showFilters, setShowFilters] = useState(false);

  const updateFilters = (filter: {TDID: TDID, TDKID: TDKID, TDKOID: TDKOID | number, label: string}) => {
    const newFilters = [...filters];
    const indexK = newFilters.findIndex((f) => f.TDID === filter.TDID && f.TDKID === filter.TDKID);
    if (indexK === -1) {
      newFilters.push({TDID: filter.TDID, TDKID: filter.TDKID, answers: [{TDKOID: filter.TDKOID, label: filter.label}]});
    } else {
      const indexO = newFilters[indexK].answers.findIndex((a) => a.TDKOID === filter.TDKOID);
      (indexO === -1)
        ? newFilters[indexK].answers.push({TDKOID: filter.TDKOID, label: filter.label})
        : newFilters[indexK].answers.length === 1
          ? newFilters.splice(indexK, 1)
          : newFilters[indexK].answers.splice(indexO, 1);
    };
    setFilters(newFilters);
    ((newFilters.length > 0 && !showFilters) || (newFilters.length === 0 && showFilters)) && setShowFilters((oldValue) => !oldValue);
  };

  const checkFilters = (filter: {TDID: TDID, TDKID: TDKID, TDKOID: TDKOID | number}) => {
    const indexK = filters.findIndex((f) => f.TDID === filter.TDID && f.TDKID === filter.TDKID);
    return (indexK === -1)
      ? false
      : !!filters[indexK].answers.find((a) => a.TDKOID === filter.TDKOID);
  };

  const displayProduct = (id: string) => {
    const ids = id.split("/lc/");
    return ids.length === 2
      ? (companyContext.getCategory(ids[0])?.label || "[Catégorie inconnue]") + " - " + (companyContext.getBrand(ids[1])?.label || "[Marque inconnue]")
      : id;
  };

  const displayTemplate = (template: QuestionnaireInterface, dataframe: dfd.DataFrame, fulldataframe: dfd.DataFrame) => template.order && template.order.length > 0
    ? template.order.map((TDID) => {
      const dict = template.dicts && template.dicts[TDID]?.TDID ? template.dicts[TDID] : null;
      return dict ? <div style={{boxSizing: "border-box", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "8px 0px"}}>
        {/* <hr style={{boxSizing: "border-box", height: "1px", width: "calc(100% - 8px)", maxWidth: "420px"}} /> */}
        <h4>{dict.label}</h4>
        {/* <hr style={{boxSizing: "border-box", height: "1px", width: "calc(100% - 8px)", maxWidth: "420px"}} /> */}
        <div style={{width: "100%", display: "flex", flexDirection: "column"}}>{dict.order.filter((TDKID) => dashboardContext.getNivoItems(fulldataframe, dict.TDID+"/"+TDKID).length > 0).map((TDKID) => {
          const key = dict.keys && dict.keys[TDKID]?.TDKID ? dict.keys[TDKID] : null;
          const filteredAnswers = key ? dashboardContext.getNivoItems(dataframe, dict.TDID+"/"+key.TDKID) : null;
          return key ? <div style={{width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
            <h6 style={{boxSizing: "border-box", flex: "1 1 30%", minWidth: "100px", maxWidth: "280px", padding: "4px", fontWeight: "600", textAlign: "center", borderTop: "1px solid var(--interaction)"}}>{key.label}</h6>
            <div style={{boxSizing: "border-box", flex: "1 1 70%", minWidth: "180px", display: "flex", flexWrap: "wrap", justifyContent: "center", borderTop: "1px solid var(--interaction)", borderLeft: "1px solid var(--interaction)", borderRadius: "0px 8px 8px 8px"}}>
              {dashboardContext.getNivoItems(fulldataframe, dict.TDID+"/"+key.TDKID).sort((a, b) => {
                const ia = fullOrder.indexOf(a.id);
                const ib = fullOrder.indexOf(b.id);
                return (ia !== -1 && ib !== -1)
                  ? ia - ib
                  : (ia === -1 && ib === -1)
                    ? a.id.localeCompare(b.id)
                    : ia;
              }).map((answer) => {
                const filteredAnswer = filteredAnswers?.find((a) => a.id === answer.id) || null;
                const label = answer.label === "n/a" || answer.label === "[lc]null"
                  ? "Non demandé"
                  : key.type === "category" || key.type === "category-full"
                    ? companyContext.getCategory(answer.id)?.label || "[Catégorie inconnue]"
                    : key.type === "brand" || key.type === "brand-full"
                      ? companyContext.getBrand(answer.id)?.label || "[Marque inconnue]"
                      : key.type === "product"
                        ? displayProduct(answer.id)
                        : key.type === "number"
                          ? parseInt(answer.label) < 0 ? "Plus de " + (-1*parseInt(answer.label)) : answer.label
                          : answer.label
                return <button
                  className="lc-buttonicon"
                  style={{
                    flex: "1 1 20%", height: "unset", minWidth: "180px", justifyContent: "space-between",
                    color: checkFilters({TDID: dict.TDID, TDKID: key.TDKID, TDKOID: answer.id}) ? "var(--light)" : undefined,
                    backgroundColor: checkFilters({TDID: dict.TDID, TDKID: key.TDKID, TDKOID: answer.id}) ? "var(--interactive)" : filteredAnswer && filters.length > 0 ? "var(--interaction)" : undefined,
                    boxShadow: checkFilters({TDID: dict.TDID, TDKID: key.TDKID, TDKOID: answer.id}) ? "unset" : undefined,
                    opacity: filteredAnswer ? 1 : 0.7,
                  }}
                  onClick={() => updateFilters({
                    TDID: dict.TDID,
                    TDKID: key.TDKID,
                    TDKOID: key.type === "number" ? parseInt(answer.id) : answer.id,
                    label: label,
                  })}
                >
                  <small style={{boxSizing: "border-box", minWidth: "100px", padding: "4px", textAlign: "left", color: "inherit", transition: "unset", fontStyle: answer.label === "n/a" || answer.label === "[lc]null" ? "italic" : "normal"}}>
                    {label}
                  </small>
                  <small style={{boxSizing: "border-box", minWidth: "76px", padding: "4px", textAlign: "right", color: "inherit", transition: "unset"}}>
                    {filteredAnswer ? filteredAnswer.value + " - " + filteredAnswer.percent + "%" : " "}
                  </small>
                </button>}
              )}
            </div>
            {/* <hr style={{boxSizing: "border-box", height: "1px", width: "calc(100% - 8px)", maxWidth: "420px"}} /> */}
          </div> : <div><h5>Aucune question</h5></div>
        })}</div>
      </div> : <div><h5>Aucune section</h5></div>
    })
    : <div><h5>Aucune donnée</h5></div>;
 
  useEffect(() => {
    if (dashboardContext.fullDF?.axis?.columns?.includes("start") && dashboardContext.fullDF?.axis?.columns?.includes("TFID")) {
      try {
        const viewDf = filtersStore.length > 0 ? dashboardContext.getFilteredDf(
          dashboardContext.fullDF.query({condition: dashboardContext.fullDF["start"].ge(period.startDate?.valueOf() || 1).and(dashboardContext.fullDF["start"].le(period.endDate?.valueOf() || Date.now()))}) || new dfd.DataFrame([]),
          [{column: "CSID", filters: filtersStore.map((i) => i.value)}]
        ) : dashboardContext.fullDF.query({condition: dashboardContext.fullDF["start"].ge(period.startDate?.valueOf() || 1).and(dashboardContext.fullDF["start"].le(period.endDate?.valueOf() || Date.now()))}) || new dfd.DataFrame([]);
        setPeriodDf(viewDf);
        setFilters([]);
      } catch {
        setPeriodDf(new dfd.DataFrame([]));
        setFilters([]);
      }
    }
  }, [dashboardContext.fullDF, period, filtersStore]);

  useEffect(() => {
    if (periodDf?.axis?.index?.length > 0 && filters.length > 0) {
      setFilteredDf(dashboardContext.getFilteredDf(periodDf, filters.map((f) => {
        return {column: f.TDID+"/"+f.TDKID, filters: f.answers.map((a) => a.TDKOID)}
      })));
    } else if (periodDf?.axis?.index?.length > 0) {
      setFilteredDf(periodDf);
    } else {
      setFilteredDf(new dfd.DataFrame([]));
    }
  }, [periodDf, filters]);

	return (<>
    <PeriodToolBar
			period={period}
			setPeriod={setPeriod}
		/>
    <section className="lc-section" style={{alignItems: "stretch"}}>
      {/* <small style={{width: "100%", textAlign: "center"}}>Boutique</small> */}
      <MultiSelect
        id="filterStore"
        title="Boutiques"
        placeholder="Toutes"
        maxGrow="100%"
        options={optionsStore}
        selected={filtersStore as ReactSelectInterface[]}
        // setSelected={(s) => setFiltersStore(s)}
        setSelected={(s) => {
          setFiltersStore(s);
          !props.dual && dashboardContext.setCurrentCSIDs(s);
        }}
        // selected={dashboardContext.currentCSIDs as ReactSelectInterface[]}
        // setSelected={(s) => dashboardContext.setCurrentCSIDs(s)}
      />
      {/* <SingleSelect
        id="filterStore"
        // title="Boutiques"
        placeholder="Toutes"
        maxGrow="100%"
        options={optionsStore}
        selected={filterStore.value}
        setSelected={(s) => setFilterStore(s)}
      /> */}
      <div style={{
        boxSizing: "border-box",
        height: "fit-content",
        width: "100%",
        position: "sticky",
        top: "-8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "var(--light)",
        borderRadius: "0px 0px 8px 8px",
        boxShadow: "0px 1px 1px var(--interaction)",
        marginBottom: "8px",
        // zIndex: "88",
      }}>
        <button className="lc-buttonicon"
          style={{width: "30%", maxWidth: "200px", padding: "8px"}}
          onClick={() => {setFilters([]); setShowFilters(false)}}
          disabled={filters.length === 0}
        >{filters.length > 0 ? "Supprimer tous les filtres" : "Aucun filtre sélectionné"}</button>
        <button className="lc-buttonicon"
          style={{width: "30%", maxWidth: "200px", padding: "8px"}}
          onClick={() => setShowFilters((oldValue) => !oldValue)}
        >{showFilters ? "Masquer les filtres" : "Afficher les filtres"}</button>
        <small style={{width: "30%", maxWidth: "200px", padding: "8px", textAlign: "center"}}>{"Total : " + (filteredDf?.axis?.index?.length || 0)}</small>
      </div>
      <div style={{
        boxSizing: "border-box",
        display: showFilters ? "unset" : "none",
        flex: "1 1 20%",
        minWidth: "180px",
        // width: "20%",
        borderRight: "1px solid var(--interaction)",
        borderBottom: "1px solid var(--interaction)",
        // height: showFilters ? "unset" : "0",
        // minWidth: showFilters ? "180px" : "0px",
        // width: showFilters ? "30%" : "0%",
        // border: showFilters ? "1px solid var(--interaction)" : "unset",
        borderRadius: "4px",
        // borderWidth: "1px 0px 0px 1px",
        // transition: "width 0.2s ease-in-out",
      }}>
        <div style={{
          position: "sticky",
          top: "40px",
          width: "100%",
          maxHeight: "calc(100vh - 100px)",
          display: "flex",
          flexDirection: "column",
          fontSize: "calc(0.5rem + 1vmin)",
          overflowY: "auto",
          direction: "rtl",
        }}>
          <h4 style={{width: "100%", textAlign: "center"}}>Filtres</h4>
          {filters.length > 0 ? filters.map((f) => <div style={{boxSizing: "border-box", width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "center", direction: "ltr", padding: "4px"}}>
            <small style={{width: "100%", fontSize: "inherit", textAlign: "center"}}>{storeContext?.questTemplate?.dicts[f.TDID]?.keys[f.TDKID]?.label || ""}</small>
            {f.answers.map(({TDKOID, label}) => <button className="lc-buttonicon" style={{fontSize: "inherit"}}
              onClick={() => updateFilters({TDID: f.TDID, TDKID: f.TDKID, TDKOID: TDKOID, label: label})}
            >{label}</button>)}
          </div>) : <small style={{boxSizing: "border-box", width: "100%", textAlign: "center", direction: "ltr", padding: "4px"}}>Cliquez sur une réponse pour l'ajouter aux filtres</small>}
        </div>
      </div>
      {/* <hr style={{minHeight: "1px", minWidth: "2px"}} onClick={() => setShowFilters(!showFilters)} /> */}
      <div style={{
        flex: "1 1 80%",
        // flex: filters.length > 0 ? "1 1 80%" : "1 1 100%",
        minWidth: "280px",
        // width: filters.length > 0 ? "80%" : "100%",
        display: "flex",
        flexDirection: "column",
        // transition: "all 0.5s ease-in-out"
      }}>{displayTemplate(storeContext.questTemplate, filteredDf, periodDf)}</div>
    </section>
	</>)
}

const AllAnswersView : ViewInterface = {
  AVID: "allanswers",
  component: (props?: {dual?: boolean}) => <AllAnswers dual={!!props?.dual} />,
  url: "/allanswers",
  icon: <AccountCircleIcon />
}

export default AllAnswersView;