// React
import React, { useLayoutEffect, useState } from 'react';
// Interfaces
import { AuthInterface } from 'livecrew-interfaces';
import { UserContextInterface } from "../interfaces";
// Backend
import { api, auth } from "../backend"

const NO_USER = {} as AuthInterface;

/** 
 * Ref to the user context, do NOT expose directly
 * @see UserProvider to provide the context
 * @see useUserContext to subscribe to the context
 */
const UserContext = React.createContext<UserContextInterface>({} as UserContextInterface);

/**
 * Provides the user context to its children
 * @param {JSX} children React children props
 */
const UserProvider = ({children}: {children: JSX.Element}) => {
  const [state, setState] = useState(NO_USER);
  const [isLoading, setIsLoading] = useState(true);
  const [isListening, setIsListening] = useState(true);

  const register = (email: string, newPassword: string, newPassword2: string, onError: (errorCode: string) => void) => {
    if (newPassword !== newPassword2) {
      onError("errors.auth.password.01")
    } else if (newPassword.length < 10) {
      onError("errors.auth.password.02");
    } else if (!/^.*[a-z].*$/.test(newPassword)) {
      onError("errors.auth.password.03");
    } else if (!/^.*[A-Z].*$/.test(newPassword)) {
      onError("errors.auth.password.04");
    } else if (!/^.*[0-9].*$/.test(newPassword)) {
      onError("errors.auth.password.05");
    } else {
      setIsLoading(true);
      auth.register(
        email,
        newPassword,
        () => {},
        (errorCode) => {setIsLoading(false); onError(errorCode)},
      );
    }
  };

  const login = (email: string, password: string, onSuccess: () => void, onError: (errorCode: string) => void) => {
    setIsLoading(true);
    auth.login(
      email,
      password,
      () => onSuccess(),
      (errorCode) => {setIsLoading(false); onError(errorCode)},
    );
  };

  const logout = () => {
    setIsLoading(true);
    setIsListening(false);
    auth.logout(
      () => setTimeout(() => setIsListening(true), 500),
      (errorCode) => {setIsLoading(false); console.log(errorCode)},
    );
  };

  const sendPassReset = (email: string, onSuccess: () => void, onError: (errorCode: string) => void) => auth.sendPassReset(email, onSuccess, onError);

  const confirmPassReset = (code: string, newPassword: string, newPassword2: string, onSuccess: () => void, onError: (errorCode: string) => void) => {
    if (newPassword !== newPassword2) {
      onError("errors.auth.password.01")
    } else if (newPassword.length < 10) {
      onError("errors.auth.password.02");
    } else if (!/^.*[a-z].*$/.test(newPassword)) {
      onError("errors.auth.password.03");
    } else if (!/^.*[A-Z].*$/.test(newPassword)) {
      onError("errors.auth.password.04");
    } else if (!/^.*[0-9].*$/.test(newPassword)) {
      onError("errors.auth.password.05");
    } else {
      auth.confirmPassReset(code, newPassword, onSuccess, onError);
    }
  };

  const createCompany = (name: string, creaCode: string, onSuccess: () => void, onError: (errorCode: string) => void) => {
    setIsLoading(true);
    setIsListening(false);
    api.createCompany({name: name, creaCode: creaCode})
        .then((res) => auth.refreshUser((user) => {
          setState(user || NO_USER);
          setIsLoading(false);
          setIsListening(true);
          onSuccess();
          // api.init()
          //   .then(() => console.log("Init done"))
          //   .catch((errors) => console.log(errors))
        }))
        .catch((err) => {setIsLoading(false); onError(err.code ? "errors.api." + err.code : "errors.api.00"); console.log(err)});
  };

  const joinCompany = (IID: string, onSuccess: () => void, onError: (errorCode: string) => void) => {
    setIsLoading(true);
    api.joinCompany({IID: IID})
        .then((res) => auth.refreshUser((user) => {
          setState(user || NO_USER);
          setIsLoading(false);
          onSuccess();
        }))
        .catch((err) => {setIsLoading(false); onError(err.code ? "errors.api." + err.code : "errors.api.00"); console.log(err)});
  };

  const accessors = {
    ...state,
    isLoading,
    register,
    login,
    logout,
    sendPassReset,
    confirmPassReset,
    createCompany,
    joinCompany,
  };

  useLayoutEffect(() => {
    const handleChange = (user: AuthInterface | null) => {
      setState(user || NO_USER);
      setIsLoading(false);
    };
    const unsubscribe = isListening ? auth.onAuthChange(handleChange) : null;
    if (unsubscribe) return unsubscribe;
  }, [isListening]);

  // const getInvitations = () => Array.isArray(state.invitations) ? state.invitations : [];

  // const acceptInvitation = (IID) => {
  //   // setState((oldstate) => {
  //   //   return {
  //   //     ...oldstate,
  //   //     isLoading: true
  //   //   }
  //   // })
  //   invitationsIO.acceptInvitation({IID: IID})
  //     .then(() => refreshToken())
  //     .catch((err) => {
  //       console.log(err);
  //       setState((oldstate) => {
  //         return {
  //           ...oldstate,
  //           isLoading: false
  //         }
  //       })
  //     });
  // }

	// Do NOT expose state and setState directly in the provider value, ever
  return (
    <UserContext.Provider value={accessors}>
      {children}
      {/* {state.UID ? children : <PublicRouter />} */}
    </UserContext.Provider>
  )
};

/**
 * Returns the actual value of the user context
 * @example
 * const userContext = useUserContext() // subscribe to the context
 * const loading = userContext.isLoading() // use methods provided to interact with the context
 * @see UserProvider for the full list of methods
 */
const useUserContext = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('No user context!');
  }
  return context
};

// Do NOT export UserContext directly, ever
export {
  UserProvider,
  useUserContext
};