import React, { useState } from "react";
import { ResponsivePie } from '@nivo/pie';
// Interfaces
import { ItemInterface, NivoDataInterface } from "../../../interfaces";
// AppConfig
import appConfig from "../appConfig";
// Contexts
import { useI18nContext } from "../../../contexts/i18n";

const labelDark = "#222222";

export default function SinglePie(
  {title, data, flex, colors, startAngle, endAngle, onClick}:
  {title: string, data: NivoDataInterface[], flex?: string, colors?: string[] | {scheme: "paired" | "set3"}, startAngle?: number, endAngle?: number, onClick?: (item: ItemInterface) => void}
) {
	// const i18n = useI18nContext();

  const [infoId, setInfoId] = useState("");

  return (<div className={"lc-graph" + (onClick ? " interactive" : "")} style={{flex: flex ? flex : "1 0 560px", height: "300px", minWidth: "300px", textAlign: "center"}}>
      <div style={{position: "relative", width: "100%", display: "flex", justifyContent: "center"}}>
        <div style={{position: "absolute", height: "300px", width: "fit-content", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          {(infoId === "")
            ? title.split(" ").map((s, i) => <small key={"single-pie-title-"+i} style={{zIndex: 1, fontWeight: "bold"}}>{s}</small>)
            : <>
              <small style={{zIndex: 1, backgroundColor: appConfig.color+"88", borderRadius: "4px"}}>{data.find((d) => d.id === infoId)?.label || ""}</small>
              <small style={{zIndex: 1}}>{(data.find((d) => d.id === infoId)?.value || 0) + " / " + (data.find((d) => d.id === infoId)?.total || 0)}</small>
            </>
          }
        </div>
      </div>
      <ResponsivePie
        data={data}
        id="id"
        value="percent"
        valueFormat={(value) => value <= 0 ? "" : value+"%"}
        arcLinkLabel={(item) => item.label + ""}
        onClick={onClick ? (item) => onClick({label: item.data.label, value: item.data.id}) : undefined}
        onMouseEnter={(item) => setInfoId(item.data.id)}
        onMouseLeave={(item) => setInfoId("")}
        innerRadius={0.5}
        borderWidth={1}
        padAngle={0}
        cornerRadius={4}
        enableArcLinkLabels={true}
        activeOuterRadiusOffset={8}
        arcLabelsSkipAngle={13}
        arcLinkLabelsSkipAngle={13}
        arcLinkLabelsDiagonalLength={6}
        arcLinkLabelsStraightLength={6}
        arcLinkLabelsTextOffset={4}
        arcLabelsTextColor={labelDark}
        arcLinkLabelsTextColor={"var(--dark)"}
        arcLinkLabelsColor={"var(--dark)"}
        margin={{top: 40, right: 90, bottom: 40, left: 90}}
        isInteractive={true}
        startAngle={startAngle}
        endAngle={endAngle}
        colors={colors ? colors :{"scheme":"paired"}}
        theme={{
          fontSize: 12,
          textColor: "var(--dark)",
          background: "#00000000",
        }}
      />
    </div>)
}