// React
import React, { useEffect, useState } from "react";
// Interfaces
import { QAInterface } from "livecrew-interfaces";

export default function FreeText(
  {key, answers, setAnswer, opt}:
  {key: string, answers: QAInterface["answers"], setAnswer: (answer: string) => void, opt?: boolean}
) {
  const [text, setText] = useState("");

  useEffect(() => setText(answers.find((a) => a.label === "freetext")?.value as string || ""), [answers]);

	return (<>
    <textarea
      className="lc-freetext-container"
      // placeholder="..."
      value={text}
      onChange={(e) => setText(e.target.value)}
    />
    <input
      key={key+"/freesubmit"}
      className="lc-questionnaire-answer"
      type="button"
      value={"Valider"}
      onClick={() => setAnswer(text)}
      disabled={!opt ? answers.find((a) => a.label === "freetext")?.value === text : false}
    />
  </>)
}