// React
import React, { useState, useLayoutEffect, useEffect } from "react";
// Interfaces
import { CID, PermissionType, TDID, TDKID, QuestionnaireInterface, QuestConfigInterface, QaViewInterface, QaItemInterface, QaDataInterface, DataValueType, QuestFeedbackInterface, functions, TID, TCID, SurveyFeedbackInterface, SurveyInterface, SurveyConfigInterface } from "livecrew-interfaces";
import { CrewappContextInterface, ttt } from "../interfaces";
// Backend
import { db } from "../backend"
// Contexts
// import { useI18nContext } from "./i18n";
import { useUserContext } from "./user";
import { useStoreContext } from "./store";
// import { useAppsContext } from "./apps";

/** 
 * Ref to the crewapp context, do NOT expose directly
 * @see CrewappProvider to provide the context
 * @see useCrewappContext to subscribe to the context
 */
const CrewappContext = React.createContext({} as CrewappContextInterface);

/**
 * Provides the crewapp context to its children
 * @param {JSX} children React children props
 */
const CrewappProvider = ({children} : {children: JSX.Element}) => {
  // const i18n = useI18nContext();
	const userContext = useUserContext();
	const storeContext = useStoreContext();
  // const appsContext = useAppsContext();
  const [isLoading, setIsLoading] = useState(false);
  // const [questTemplate, setQuestTemplate] = useState({} as QuestionnaireInterface);
  // const [questConfig, setQuestConfig] = useState({} as QuestConfigInterface);
  const [questViews, setQuestViews] = useState([] as QaViewInterface[]);
  const [questItems, setQuestItems] = useState([] as QaItemInterface[]);
  const [questData, setQuestData] = useState([] as QaDataInterface[]);
  const [currentQuest, setCurrentQuest] = useState("" as TDID);
  const [start, setStart] = useState(0);
  
  const [surveyViews, setSurveyViews] = useState([] as QaViewInterface[]);
  const [surveyItems, setSurveyItems] = useState([] as QaItemInterface[]);
  const [surveyData, setSurveyData] = useState([] as QaDataInterface[]);
  const [currentSurvey, setCurrentSurvey] = useState("" as TDID);
  const [surveyStart, setSurveyStart] = useState(0);

  const initQ = (t: QuestionnaireInterface, c: QuestConfigInterface) => {
    if (t.TID && c.TCID) {
      const {views, items, data, current} = functions.mapTemplate(t, c);
      setQuestViews(views);
      setQuestItems(items);
      setQuestData(data);
      setCurrentQuest(current);
      setStart(0);
    }
  };

  const initS = (t: SurveyInterface, c: SurveyConfigInterface) => {
    if (t.TID && c.TCID) {
      console.log("Init Survey");
      const {views, items, data, current} = functions.mapTemplate(t, c);
      setSurveyViews(views);
      setSurveyItems(items);
      setSurveyData(data);
      setCurrentSurvey(current);
      setStart(0);
    }
  };

  const switchView = (type: ttt, TDID: TDID) => {
    if (type === "quest") {
      questViews.find((v) => v.TDID === TDID) ? setCurrentQuest(TDID) : setCurrentQuest("");
    } else if (type === "survey") {
      surveyViews.find((v) => v.TDID === TDID) ? setCurrentSurvey(TDID) : setCurrentSurvey("");
    }
  };

  const setAnswer = (type: ttt, TDID: TDID, TDKID: TDKID, value: DataValueType) => {
    if (type === "quest") {
      const {data, current} = functions.updateData(questViews, questItems, questData, {TDID: TDID, TDKID: TDKID, value: value});
      data.length > 1 && start === 0 && setStart(Date.now());
      setQuestData(data);
      setCurrentQuest(current);
    } else if (type === "survey") {
      const {data, current} = functions.updateData(surveyViews, surveyItems, surveyData, {TDID: TDID, TDKID: TDKID, value: value});
      data.length > 1 && start === 0 && setSurveyStart(Date.now());
      setSurveyData(data);
      setCurrentSurvey(current);
    }
  };
  
  const submitFeedback = (type: ttt, onSuccess: () => void, onError: (errorCode: string) => void) => {
    if (type === "quest" && !storeContext.demo) {
      setIsLoading(true);
      const feedback : QuestFeedbackInterface = {
        TID: storeContext.questTemplate.TID,
        TCID: storeContext.questConfig.TCID,
        TFID: "X42generated",
        UID: userContext.UID,
        CID: userContext.CID,
        CSID: userContext.CSID,
        start: start,
        // end: Date.now(),
        duration: Date.now() - start,
        createdAt: new Date().toISOString().split('T')[0],
        data: questData.reduce((acc: QuestFeedbackInterface["data"], key) => {return {...acc, [key.TDID+"/"+key.TDKID]: key.value !== undefined ? key.value : "[lc]undefined"}}, {}),
      };
      db.companies.questionnaires.feedbacks.create(
        userContext.CID,
        storeContext.questTemplate.TID,
        feedback,
        () => {initQ(storeContext.questTemplate, storeContext.questConfig); setIsLoading(false); onSuccess()},
        (errorCode) => {setIsLoading(false); onError(errorCode)}
      );
    } else if (type === "survey") {
      setIsLoading(true);
      const feedback : SurveyFeedbackInterface = {
        TID: storeContext.surveyTemplate.TID,
        TCID: storeContext.surveyConfig.TCID,
        TFID: "X42generated",
        UID: userContext.UID,
        CID: userContext.CID,
        CSID: userContext.CSID,
        start: surveyStart,
        // end: Date.now(),
        duration: Date.now() - start,
        createdAt: new Date().toISOString().split('T')[0],
        data: surveyData.reduce((acc: SurveyFeedbackInterface["data"], key) => {return {...acc, [key.TDID+"/"+key.TDKID]: key.value !== undefined ? key.value : "[lc]undefined"}}, {}),
      };
      db.companies.surveys.feedbacks.set(
        userContext.UID,
        userContext.CID,
        storeContext.surveyTemplate.TID,
        feedback,
        () => {initS(storeContext.surveyTemplate, storeContext.surveyConfig); setIsLoading(false); onSuccess()},
        (errorCode) => {setIsLoading(false); onError(errorCode)}
      );
    } else {
      initS(storeContext.surveyTemplate, storeContext.surveyConfig);
      onSuccess();
    }
  };

  useEffect(() => initQ(storeContext.questTemplate, storeContext.questConfig), [storeContext.questTemplate, storeContext.questConfig]);

  useEffect(() => initS(storeContext.surveyTemplate, storeContext.surveyConfig), [storeContext.surveyTemplate, storeContext.surveyConfig]);

	const accessors = {
    isLoading,
    // questTemplate,
    // questConfig,
    questViews,
    questItems,
    questData,
    currentQuest,
    surveyViews,
    surveyItems,
    surveyData,
    currentSurvey,
    setAnswer,
    switchView,
    submitFeedback,
    // setQuestConfig,
	};

  // Do NOT expose state and setState directly in the provider value, ever
	return (
    <CrewappContext.Provider value={accessors}>
      {children}
    </CrewappContext.Provider>
  )
};

/**
 * Returns the actual value of the crewapp context
 * @example
 * const crewapp = useCrewappContext() // subscribe to the context
 * const cluster = crewapp.getCluster() // use methods provided to interact with the context
 * @see CrewappProvider for the full list of methods
 */
const useCrewappContext = () => {
	const context = React.useContext(CrewappContext);
	if (context === undefined) {
		throw new Error("No crewapp context!");
	}
	return context
};

// Do NOT export CrewappContext directly, ever
export {
	CrewappProvider,
	useCrewappContext
};