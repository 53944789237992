// React
import React from 'react';
// Interface
import { AppInterface } from "../../interfaces";
// Config
import appConfig from "./appConfig";
// Wrapper
import AppWrapper from "./AppWrapper";
// CSS
import "./index.css";
// Views
import HomeAdminView from './views/Home';
import UsersView from './views/Users';
import StoresView from './views/Stores';
import CatalogsView from './views/Catalogs';

const user : AppInterface = {
  ...appConfig,
  component: (view) => <AppWrapper view={view || HomeAdminView} />,
  views: [
    HomeAdminView,
    UsersView,
    StoresView,
    CatalogsView,
  ],
};

export default user;