// React
import React from 'react';
// MUI
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// Interfaces
import { ViewInterface } from '../../../interfaces';
// Contexts
import { useI18nContext } from '../../../contexts/i18n';
import { useCompanyContext } from '../../../contexts/company';
// AppConfig
import appConfig from '../appConfig';
// Components
import Widget from '../../../components/Widget';

const MyCompany = () => {
	const i18n = useI18nContext();
	const companyContext = useCompanyContext();
	// const [message, setMessage] = useState("");
	// const [isDisabled, setIsDisabled] = useState(false);

	// const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
	// 	e.preventDefault();
	// 	setIsDisabled(true);
	// 	userContext.logout((message) => {
	// 		setMessage(message);
	// 		setIsDisabled(false);
	// 	})
	// };

	return (<>
		<Widget
      title={i18n.t(appConfig.AID + ".views." + MyCompanyView.AVID + ".name")}
			isFocused={true}
			width="100%"
		>
			<form
				// onSubmit={handleSubmit}
			>
        <label htmlFor="name">{i18n.t("shared.companyDict.name", {case: "first"})}</label>
        <input
          id="name"
          type="name"
          value={companyContext.name}
          disabled={true}
        />
				{/* <label htmlFor="email">{i18n.t("shared.userDict.password", {case: "first"})}</label>
				<input
					type="submit"
					value={i18n.t("shared.crudDict.update", {case: "first"})}
					disabled={isDisabled}
				/>
				<small style={{textAlign: "center"}}>{message}</small> */}
			</form>
		</Widget>
	</>)
}

const MyCompanyView : ViewInterface = {
  AVID: "mycompany",
  component: (props?: {dual?: boolean}) => <MyCompany />,
  url: "/mycompany",
  icon: <AccountCircleIcon />,
}

export default MyCompanyView;