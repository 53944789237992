import React, { useEffect, useState } from "react";
// Interfaces
import { ReactDateRangeInterface } from "../../../interfaces";
// AppConfig
import appConfig from "../appConfig";
// Contexts
import { useI18nContext } from "../../../contexts/i18n";
import { useDashboardContext } from "../../../contexts/dashboard";
// Components
import ToolBar from "../../../components/ToolBar";
import Widget from "../../../components/Widget";
import PeriodSelect from "../../../components/PeriodSelect";

export default function PeriodToolBar(
  {period, setPeriod}:
  {period: ReactDateRangeInterface, setPeriod: (period: ReactDateRangeInterface) => void}
) {
	const i18n = useI18nContext();
  const dashboardContext = useDashboardContext();

  const [periodOptions, setPeriodOptions] = useState([{
		label: i18n.t(appConfig.AID+".shared.beginning"),
		startDate: new Date(dashboardContext.stats.first),
		endDate: new Date(),
	},{
		label: i18n.t(appConfig.AID+".shared.monthRolling"),
		startDate: new Date(new Date(new Date().valueOf() - 1000*60*60*24*31).setHours(0, 0, 0)),
		endDate: new Date(),
	},{
		label: i18n.t(appConfig.AID+".shared.weekRolling"),
		startDate: new Date(new Date(new Date().valueOf() - 1000*60*60*24*7).setHours(0, 0, 0)),
		endDate: new Date(),
	}]);

  useEffect(() => {
		const now = new Date();		
    setPeriodOptions([{
      label: i18n.t(appConfig.AID+".shared.beginning"),
      startDate: new Date(dashboardContext.stats.first),
      endDate: now,
    },{
      label: i18n.t(appConfig.AID+".shared.monthRolling"),
      startDate: new Date(new Date(now.valueOf() - 1000*60*60*24*31).setHours(0, 0, 0)),
      endDate: now,
    },{
      label: i18n.t(appConfig.AID+".shared.weekRolling"),
      startDate: new Date(new Date(now.valueOf() - 1000*60*60*24*7).setHours(0, 0, 0)),
      endDate: now,
    }])
  }, [i18n, dashboardContext.stats]);

  useEffect(() => {
    setPeriod(periodOptions[0]);
  }, [periodOptions, setPeriod]);

  return (
    <ToolBar
			title={i18n.t("shared.period")}
		><>
			<Widget
				// title={i18n.t(appConfig.AID+".shared.quickSelect")}
				flex="1 0 190px"
				minWidth="190px"
				maxWidth="200px"
			><>
				{periodOptions.map((p, i) => <Widget
					key={"period-button-"+i}
					onClick={() => setPeriod(p)}
					minHeight="40px"
					minWidth="180px"
				>
					<small>{p.label}</small>
				</Widget>)}
			</></Widget>
			<Widget
				flex="1 0 390px"
				minWidth="390px"
			>
				<PeriodSelect
					id="overview-periodselect"
					period={period}
					setPeriod={(p) => setPeriod(p)}
					minDate={new Date(dashboardContext.stats.first)}
					maxDate={new Date(dashboardContext.stats.last)}
				/>
			</Widget>
			<Widget
				// title={i18n.t(appConfig.AID+".shared.generalStats")}
				flex="1 0 190px"
				minWidth="190px"
				maxWidth="200px"
			><>
        <small style={{width: "100%", textAlign: "center"}}>
          {i18n.t(appConfig.AID + ".shared.firstFeedback")}
        </small>
        <small style={{width: "100%", textAlign: "center"}}>
          <b>{" " + new Date(dashboardContext.stats.first).toLocaleDateString(i18n.getLocale())}</b>
        </small>
        <hr style={{height: "2px", width: "50%"}}/>
        <small style={{width: "100%", textAlign: "center"}}>
          {i18n.t(appConfig.AID + ".shared.mostRecent")}
        </small>
        <small style={{width: "100%", textAlign: "center"}}>
          <b>{" " + new Date(dashboardContext.stats.last).toLocaleDateString(i18n.getLocale())}</b>
        </small>
        <hr style={{height: "2px", width: "50%"}}/>
        <small style={{width: "100%", textAlign: "center"}}>
          Total <b>{dashboardContext.stats.total}</b>
        </small>
			</></Widget>
		</></ToolBar>
  )
}