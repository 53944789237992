// React
import React from 'react';
// Interface
import { AppInterface } from "../../interfaces";
// Config
import appConfig from "./appConfig";
// Wrapper
import AppWrapper from "./AppWrapper";
// CSS
import "./index.css";
// Views
import LoginView from "./views/Login";
import RegisterView from "./views/Register";
import RecoverView from "./views/Recover";
import ValidationView from './views/Validation';

const guest : AppInterface = {
  ...appConfig,
  component: (view) => <AppWrapper view={view || LoginView} />,
  views: [
    LoginView,
    RegisterView,
    RecoverView,
    ValidationView,
  ],
};

export default guest;