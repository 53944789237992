// React
import React, { useState, useLayoutEffect } from 'react';
// Interfaces
import { AppInterface, AppsContextInterface, ViewInterface } from "../interfaces";
// Apps
import { websiteApp, guestApp, userApp, restrictedApps } from '../apps';
// Backend
// import database from '../backend/database';
// Contexts
import { useUserContext } from './user';

/** 
 * Ref to the apps context, do NOT expose directly
 * @see AppsProvider to provide the context
 * @see useAppsContext to subscribe to the context
 */
const AppsContext = React.createContext({} as AppsContextInterface);

/**
 * Provides the apps context to its children
 * @param {JSX} children React children props
 */
const AppsProvider = ({children} : {children: JSX.Element}) => {
	const userContext = useUserContext();

	// const [apps, setApps] = useState([websiteApp, guestApp]);
	const [apps, setApps] = useState([] as AppInterface[]);
  const [isLoading, setIsLoading] = useState(true);

	const init = (UID: string, permissions: string[]) => {
		if (!UID) {
			setApps([websiteApp, guestApp]);
		} else if (!permissions || !Array.isArray(permissions)) {
			setApps([websiteApp, userApp]);
		} else {
			setApps([...restrictedApps.filter((app) => permissions.includes(app.AID)), userApp]);
		}
		setIsLoading(false);
	}

	const getApps = () => Array.isArray(apps) ? [...apps] : [];

	const hasApp = (AID: string) => {
		const appIndex = apps.findIndex((app) => app.AID === AID);
		return !!(appIndex !== -1)
	};

	const getApp = (AID: string) => {
		const appIndex = apps.findIndex((app) => app.AID === AID);
		const app = (appIndex !== -1)
			? {...apps[appIndex]}
			: {} as AppInterface;
		return app;
	};

	const getView = (AID: string, AVID: string) => {
		const app = getApp(AID);
		const viewIndex = app.views?.length > 0
			? app.views.findIndex((view) => view.AVID === AVID)
			: -1;
		const view = (viewIndex !== -1)
			? {...app.views[viewIndex]}
			: {} as ViewInterface;
		return view;
	};

	const accessors = {
    isLoading,
		apps,
		hasApp,
		getApp,
		getView,
		getApps,
	}

	useLayoutEffect(() => {
		setIsLoading(true);
		init(userContext.UID, userContext.permissions);
	}, [userContext.UID, userContext.permissions]);

	// Do NOT expose state and setState directly in the provider value, ever
	return (
		<AppsContext.Provider value={accessors}>
			{children}
		</AppsContext.Provider>
	)
};

/**
 * Returns the actual value of the apps context
 * @example
 * const appsContext = useAppsContext() // subscribe to the context
 * const loading = appsContext.isLoading // use accessors provided to interact with the context
 * @see AppsProvider for the full list of methods
 */
const useAppsContext = () => {
	const context = React.useContext(AppsContext);
	if (context === undefined) {
		throw new Error('No apps context!');
	}
	return context
};

// Do NOT export AppsContext directly, ever
export {
	AppsProvider,
	useAppsContext
};