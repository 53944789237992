import React, { useState } from "react";
import { ResponsivePie,  } from '@nivo/pie';
// Interfaces
import { ItemInterface, NivoDataInterface } from "../../../interfaces";
// AppConfig
// import appConfig from "../appConfig";
// Contexts
import { useI18nContext } from "../../../contexts/i18n";

const continuousGreens = ['#68be71', '#74c478', '#8bcf89', '#96d492', '#abdda5', '#b5e1ae', '#bee5b7', '#c6e9c0', '#cfecc8', '#d6efd0', '#ddf2d8', '#e4f4df', '#e9f7e5', '#eef9eb', '#f3faf0'];
const continuousReds = ['#f75e44', '#f96a4c', '#fb7f5f', '#fc8969', '#fc9d7f', '#fca78b', '#fcb196', '#fcbba2', '#fdc5af', '#fdceba', '#fdd6c6', '#feded0', '#fee5d9', '#ffebe2', '#fff0e9'];
const labelDark = "#222222";

export default function DoublePie(
  {title, dataTop, dataBottom, flex, colors, labelTop, labelBottom, height, onClick}:
  {title: string, dataTop: NivoDataInterface[], dataBottom: NivoDataInterface[], flex?: string, colors?: string[], labelTop?: string, labelBottom?: string, height?: number, onClick?: (item: ItemInterface) => void}
) {
	const i18n = useI18nContext();

  const [infoId, setInfoId] = useState("");

  return (<div style={{display: "flex", flexDirection: "column", minWidth: "300px", width: "100%", maxWidth: height ? (height*1.5) + "px" : "435px", flex: flex}}>
    <h6 style={{boxSizing: "border-box", width: "100%", fontWeight: "600", textAlign: "center"}}>{title}</h6>
    <div className={onClick ? "lc-graph interactive" : "lc-graph"} style={{boxSizing: "border-box", height: height ? (height/2) + "px" : "125px", minWidth: "300px"}}>
      <ResponsivePie
        data={dataTop}
        id="label"
        value="percent"
        valueFormat={(value) => value <= 0 ? "" : value+"%"}
        // @ts-ignore
        // arcLabelsComponent={(item) => <small style={{
        //   ...item.style,
        //   padding: 2, color: "var(--dark)", backgroundColor: "var(--light88)", borderRadius: 8
        // }}>{item.datum.value + "%"}</small>}
        onClick={onClick ? (item) => onClick({label: item.data.label, value: item.data.id}) : undefined}
        onMouseEnter={(item) => setInfoId(item.data.id)}
        onMouseLeave={(item) => setInfoId("")}
        startAngle={-90}
        endAngle={90}
        innerRadius={0.6}
        borderWidth={1}
        padAngle={1}
        cornerRadius={4}
        enableArcLinkLabels={true}
        activeInnerRadiusOffset={-8}
        activeOuterRadiusOffset={8}
        arcLabelsSkipAngle={8}
        arcLinkLabelsSkipAngle={8}
        arcLabelsTextColor={labelDark}
        arcLinkLabelsDiagonalLength={6}
        arcLinkLabelsStraightLength={6}
        margin={{top: 20, right: 75, bottom: 0, left: 75}}
        isInteractive={true}
        colors={colors ? colors : [...continuousGreens]}
        theme={{
          fontSize: 12,
          textColor: "var(--dark)",
          background: "#00000000",
        }}
      />
    </div>
    <div style={{position: "relative", width: "100%", display: "flex", justifyContent: "center"}}>
      <div style={{position: "absolute", width: "fit-content", textAlign: "center", transform: "translateY(-50%)", display: "flex", flexDirection: "column", alignItems: "center"}}>
        <h6 style={{boxSizing: "border-box", width: "100%", color: infoId !== "" ? continuousGreens[0] : "var(--dark)"}}>
          {(infoId !== "")
            ? (dataTop.find((d) => d.id === infoId)?.value || 0)
            : (dataTop[0]?.total || 0)}
        </h6>
        {/* {(infoId !== "") ? <>
          <h6 style={{boxSizing: "border-box", width: "100%", fontWeight: "normal"}}>
            {dataTop.find((d) => d.id === infoId)?.label || dataBottom.find((d) => d.id === infoId)?.label || ""}
          </h6>
        </> : <> */}
        <h6 style={{boxSizing: "border-box", width: "100%", fontWeight: "normal"}}>
          {(infoId !== "")
            ? dataTop.find((d) => d.id === infoId)?.label || dataBottom.find((d) => d.id === infoId)?.label || ""
            : labelTop ? labelTop : i18n.t("shared.marketingDict.conversion", {case: "first"})}
        </h6>
        <hr style={{width: "88px", backgroundColor: "var(--interactive)", margin: "2px 0px"}} />
        <h6 style={{boxSizing: "border-box", width: "100%", fontWeight: "normal"}}>
          {(infoId !== "")
            ? dataBottom.find((d) => d.id === infoId)?.label || dataTop.find((d) => d.id === infoId)?.label || ""
            : labelBottom ? labelBottom : i18n.t("shared.marketingDict.nonPurchase", {case: "first"})}
        </h6>
        {/* </>} */}
        <h6 style={{boxSizing: "border-box", width: "100%", color: infoId !== "" ? continuousReds[0] : "var(--dark)"}}>
          {(infoId !== "")
            ? (dataBottom.find((d) => d.id === infoId)?.value || 0)
            : (dataBottom[0]?.total || 0)}
        </h6>
      </div>
    </div>
    <div className={onClick ? "lc-graph interactive" : "lc-graph"} style={{boxSizing: "border-box", height: height ? (height/2) + 20 + "px" : "145px", minWidth: "300px"}}>
      <ResponsivePie
        data={dataBottom}
        id="label"
        value="percent"
        valueFormat={(value) => value <= 0 ? "" : value+"%"}
        // @ts-ignore
        // arcLabelsComponent={(item) => <small style={{
        //   ...item.style,
        //   padding: 2, color: "var(--dark)", backgroundColor: "var(--light88)", borderRadius: 8
        // }}>{item.datum.value + "%"}</small>}
        onClick={onClick ? (item) => onClick({label: item.data.label, value: item.data.id}) : undefined}
        onMouseEnter={(item) => setInfoId(item.data.id)}
        onMouseLeave={(item) => setInfoId("")}
        startAngle={270}
        endAngle={90}
        innerRadius={0.6}
        borderWidth={1}
        padAngle={1}
        cornerRadius={4}
        enableArcLinkLabels={true}
        activeInnerRadiusOffset={-8}
        activeOuterRadiusOffset={8}
        arcLabelsSkipAngle={8}
        arcLinkLabelsSkipAngle={8}
        arcLabelsTextColor={labelDark}
        arcLinkLabelsDiagonalLength={6}
        arcLinkLabelsStraightLength={6}
        margin={{top: 0, right: 75, bottom: 40, left: 75}}
        isInteractive={true}
        colors={colors ? colors : [...continuousReds]}
        theme={{
          fontSize: 12,
          textColor: "var(--dark)",
          background: "#00000000",
        }}
      />
    </div>
  </div>)
}