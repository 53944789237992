// React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// MUI
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
// import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
// import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
// Interfaces
import { ViewInterface } from "../../../interfaces";
// Contexts
import { useI18nContext } from "../../../contexts/i18n";
import { useUserContext } from "../../../contexts/user";
// AppConfig
import appConfig from "../appConfig";
// Views
import RegisterView from "./Register";
import RecoverView from "./Recover";
// Components
import Widget from "../../../components/Widget";

const Login = () => {
	const i18n = useI18nContext();
	const userContext = useUserContext();
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [message, setMessage] = useState("");
	const [isDisabled, setIsDisabled] = useState(false);

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsDisabled(true);
		userContext.login(
			email,
			password,
			() => {
				setIsDisabled(false);
				navigate("/");
			},
			(errorCode) => {
			setMessage(i18n.t(errorCode));
			setIsDisabled(false);
		})
	};

	return (<div className="view-guest">
		<Widget
			title={i18n.t(appConfig.AID + ".views." + LoginView.AVID + ".name")}
			isFocused={true}
			width="100%"
		>
			<form
				onSubmit={handleSubmit}
			>
				<label htmlFor="email">{i18n.t("shared.userDict.email", {case: "first"})}</label>
				<input
					id="email"
					type="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					disabled={isDisabled}
				/>
				<label htmlFor="password">{i18n.t("shared.userDict.password", {case: "first"})}</label>
				<input
					id="password"
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					disabled={isDisabled}
				/>
				<input
					type="submit"
					aria-label="Submit button"
					value={i18n.t("shared.submit")}
					disabled={isDisabled}
				/>
				<small style={{textAlign: "center"}}>{message}</small>
			</form>
		</Widget>
		<div style={{
			width: "100%",
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "space-between",
			alignItems: "center",
			padding: "4px 0px",
		}}>
			<input
				type="button"
				aria-label={i18n.t(appConfig.AID + ".views." + RegisterView.AVID + ".name", {defaultValue: ""})}
				value={i18n.t(appConfig.AID + ".views." + RegisterView.AVID + ".name", {defaultValue: ""})}
				onClick={() => navigate(appConfig.url+RegisterView.url)}
			/>
			<input
				type="button"
				aria-label={i18n.t(appConfig.AID + ".views." + RecoverView.AVID + ".name", {defaultValue: ""})}
				value={i18n.t(appConfig.AID + ".views." + RecoverView.AVID + ".name", {defaultValue: ""})}
				onClick={() => navigate(appConfig.url+RecoverView.url)}
			/>
		</div>
	</div>)
}

const LoginView : ViewInterface = {
  AVID: "login",
  component: (props?: {dual?: boolean}) => <Login />,
  url: "/login",
  icon: <AccountCircleIcon />
}

export default LoginView;