// React
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
// Material UI
import CallSplitRoundedIcon from '@mui/icons-material/CallSplitRounded';
import CallMergeRoundedIcon from '@mui/icons-material/CallMergeRounded';
// Interfaces
import { ViewInterface } from '../../interfaces';
// Contexts
import { useI18nContext } from '../../contexts/i18n';
import { useTrackPageView } from '../../contexts/analytics';
// Components
import Widget from '../../components/Widget';
// AppConfig
import appConfig from './appConfig';

export default function AppWrapper({view} : {view: ViewInterface}) {
  const location = useLocation();
	const i18n = useI18nContext();
  const topOfDashboard = useRef<HTMLSpanElement>(null);

	const [dualMode, setDualMode] = useState(false);
	
	useTrackPageView(appConfig.AID, view.AVID);

  useEffect(() => topOfDashboard?.current?.scrollIntoView(),[location]);

	// useEffect(() => console.log("Dashboard wrapper update"));

	return (
		<div className="lc-app app-dashboard" id="lc-app-dashboard">
			<span id="top-app-dashboard" ref={topOfDashboard} style={{height: 0, width: "100%"}} />
    	<h2 style={{width: "100%", textAlign: "center"}}>{i18n.t(appConfig.AID + ".views." + view.AVID + ".name", {defaultValue: ""})}</h2>
			<div style={{width: dualMode ? "50%" : "100%"}}>
				{view.component()}
			</div>
			{dualMode && <div style={{width: "50%"}}>
				{view.component({dual: true})}
			</div>}
			<div style={{position: "absolute", bottom: "8px", right: 0, transform: "translate(-50%,0)"}}>
				<Widget
					minHeight="unset"
					minWidth="unset"
					flex="unset"
					onClick={() => setDualMode((oldValue) => !oldValue)}
				><div style={{display: "flex", alignItems: "center"}}>
					{/* <small style={{padding: "4px 8px 4px 4px"}}>Annotations</small> */}
					{!dualMode ? <CallSplitRoundedIcon /> : <CallMergeRoundedIcon />}
				</div></Widget>
			</div>
		</div>
	)
}