// React
import React, { useState, useEffect, useRef } from 'react';
import * as dfd from "danfojs";
import { ResponsiveNetwork } from '@nivo/network'
// MUI
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
// Interfaces
import { ViewInterface, ReactDateRangeInterface, ReactSelectInterface, NivoDataInterface, ItemInterface, NivoNetworkDataInterface } from '../../../interfaces';
// AppConfig
import appConfig from "../appConfig";
// Contexts
// import { useDeviceContext } from '../../../contexts/device';
import { useI18nContext } from '../../../contexts/i18n';
import { useCompanyContext } from '../../../contexts/company';
import { useStoreContext } from '../../../contexts/store';
import { useDashboardContext } from '../../../contexts/dashboard';
// Components
import PeriodToolBar from "../components/PeriodToolBar";
import MultiSelect from '../../../components/MultiSelect';
import ToolBar from '../../../components/ToolBar';
import SinglePie from '../components/SinglePie';
import SimpleBar from '../components/SimpleBar';
import Widget from '../../../components/Widget';

const continuousGreens = ['#68be71', '#74c478', '#8bcf89', '#96d492', '#abdda5', '#b5e1ae', '#bee5b7', '#c6e9c0', '#cfecc8', '#d6efd0', '#ddf2d8', '#e4f4df', '#e9f7e5', '#eef9eb', '#f3faf0'];
const continuousReds = ['#f75e44', '#f96a4c', '#fb7f5f', '#fc8969', '#fc9d7f', '#fca78b', '#fcb196', '#fcbba2', '#fdc5af', '#fdceba', '#fdd6c6', '#feded0', '#fee5d9', '#ffebe2', '#fff0e9'];

// const labelLight = "#F4F4F8";
const labelDark = "#222222";

const Reasons = (props: {dual?: boolean}) => {
  // const deviceContext = useDeviceContext();
  const i18n = useI18nContext();
  const companyContext = useCompanyContext();
  const storeContext = useStoreContext();
  const dashboardContext = useDashboardContext();
  
  const reasonRef = useRef<HTMLElement>(null);
  const profileRef = useRef<HTMLElement>(null);

  const categories = companyContext.getCategories().concat([{label: "Autre", value: "[lc]other"},{label: "Je ne sais pas", value: "[lc]idk"},{label: "Aucun produit", value: "[lc]null"}]);;
  const brands = companyContext.getBrands().concat([{label: "Autre", value: "[lc]other"},{label: "Je ne sais pas", value: "[lc]idk"},{label: "Aucun produit", value: "[lc]null"}]);;
  // const purchaseNotRef = useRef<HTMLElement>(null);

	const [period, setPeriod] = useState({} as ReactDateRangeInterface);
  const [periodDf, setPeriodDf] = useState(new dfd.DataFrame([]));
  const [filteredDf, setFilteredDf] = useState(new dfd.DataFrame([]));

  const optionsStore = companyContext.getStores().length > 0 ? companyContext.getStores() : [{label: storeContext.name, value: storeContext.CSID}];
  const [filtersStore, setFiltersStore] = useState(props.dual ? [] as ItemInterface[] : dashboardContext.currentCSIDs);
  const optionsSocio = dashboardContext.getOptions(periodDf, "profile/socio");
  const [filtersSocio, setFiltersSocio] = useState([] as ItemInterface[]);
  const optionsAge = dashboardContext.getOptions(periodDf, "profile/age");
  const [filtersAge, setFiltersAge] = useState([] as ItemInterface[]);
  const optionsFrom = dashboardContext.getOptions(periodDf, "profile/from");
  const [filtersFrom, setFiltersFrom] = useState([] as ItemInterface[]);
  const optionsFreq = dashboardContext.getOptions(periodDf, "profile/frequence");
  const [filtersFreq, setFiltersFreq] = useState([] as ItemInterface[]);
  const optionsVip = dashboardContext.getOptions(periodDf, "profile/vip");
  const [filtersVip, setFiltersVip] = useState([] as ItemInterface[]);
  const optionsRecc = dashboardContext.getOptions(periodDf, "journey/reccuring");
  const [filtersRecc, setFiltersRecc] = useState([] as ItemInterface[]);

  // const optionsCategory = companyContext.getCategories();
  // const [filtersCategory, setFiltersCategory] = useState([] as ItemInterface[]);
  // const [filtersConvCategory, setFiltersConvCategory] = useState([] as ItemInterface[]);
  // const optionsBrand = companyContext.getBrands();
  // const [filtersBrand, setFiltersBrand] = useState([] as ItemInterface[]);
  // const [filtersConvBrand, setFiltersConvBrand] = useState([] as ItemInterface[]);

  // const optionsWhy = dashboardContext.getOptions(periodDf, "purchase/why").filter((o) => o.value !== "[lc]null");
  // const [filtersWhy, setFiltersWhy] = useState([] as ItemInterface[]);

  const [purchaseDf, setPurchaseDf] = useState(new dfd.DataFrame([]));
  const [purchaseNotDf, setPurchaseNotDf] = useState(new dfd.DataFrame([]));

  const [purchaseData, setPurchaseData] = useState([] as NivoDataInterface[]);

  // const [showAnnotation, setShowAnnotation] = useState(true);
  // const [networkData, setNetworkData] = useState({nodes: [], links: [], annotations: []} as NivoNetworkDataInterface);

  const [current, setCurrent] = useState({} as {conv: string, why: string, label: string, total: number});

  const [dataWhy, setDataWhy] = useState([] as NivoDataInterface[]);
  const [dataWhyNot, setDataWhyNot] = useState([] as NivoDataInterface[]);

  const [detailsData, setDetailsData] = useState([] as NivoDataInterface[]);
  const [detailsFreeData, setDetailsFreeData] = useState([] as NivoDataInterface[]);

  const [dataSocio, setDataSocio] = useState([] as NivoDataInterface[]);
  const [dataAge, setDataAge] = useState([] as NivoDataInterface[]);
  const [dataFrom, setDataFrom] = useState([] as NivoDataInterface[]);
  const [dataFrequence, setDataFrequence] = useState([] as NivoDataInterface[]);
  const [dataVip, setDataVip] = useState([] as NivoDataInterface[]);
  const [dataReccuring, setDataReccuring] = useState([] as NivoDataInterface[]);

  // const [categoryData, setCategoryData] = useState([] as NivoDataInterface[]);
  // const [brandData, setBrandData] = useState([] as NivoDataInterface[]);
  // const [categoryOtherData, setCategoryOtherData] = useState([] as NivoDataInterface[]);
  // const [brandOtherData, setBrandOtherData] = useState([] as NivoDataInterface[]);

  // const [convCategoryData, setConvCategoryData] = useState([] as NivoDataInterface[]);
  // const [convBrandData, setConvBrandData] = useState([] as NivoDataInterface[]);
  // const [convCategory2Data, setConvCategory2Data] = useState([] as NivoDataInterface[]);
  // const [convBrand2Data, setConvBrand2Data] = useState([] as NivoDataInterface[]);
  // const [convWhyData, setConvWhyData] = useState([] as NivoDataInterface[]);
  // const [convWhyFreeData, setConvWhyFreeData] = useState([] as NivoDataInterface[]);

  const updateFilters = (item: ItemInterface, filters: ItemInterface[]) => {
    const newFilters = [...filters];
    const oldIndex = newFilters.findIndex((i) => i.value === item.value);
    if (oldIndex === -1) {
      newFilters.push(item);
    } else {
      newFilters.splice(oldIndex, 1);
    }
    return newFilters;
  };

  useEffect(() => {
    if (dashboardContext.fullDF?.axis?.columns?.includes("createdAt") && dashboardContext.fullDF?.axis?.columns?.includes("TFID") && dashboardContext.fullDF?.axis?.columns?.includes("purchase/nb")) {
      try {
        const viewDf = dashboardContext.getFilteredDf(
          (dashboardContext.fullDF.loc({columns: ["createdAt", "start", "TFID", "CSID", "purchase/nb", "purchase/product", "profile/socio", "profile/age", "profile/from", "profile/frequence", "profile/vip", "journey/reccuring", "purchase/why", "purchase/whyfree", "purchase/whynot", "purchase/whynotfree", "purchase/missingproduct", "purchase/missingcategory", "purchase/missingbrand", "purchase/missingcategoryfree", "purchase/missingbrandfree", "purchase/missingsize"]}) || new dfd.DataFrame([]))
            .query({condition: dashboardContext.fullDF["start"].ge(period.startDate?.valueOf() || 1).and(dashboardContext.fullDF["start"].le(period.endDate?.valueOf() || Date.now()))}) || new dfd.DataFrame([]),
          [{column: "CSID", filters: filtersStore.map((i) => i.value)}]
        );
        setFiltersSocio([]);
        setFiltersAge([]);
        setFiltersFrom([]);
        setFiltersFreq([]);
        setFiltersVip([]);
        setFiltersRecc([]);
        setPeriodDf(viewDf);
        setCurrent({conv: "", why: "", label: "Achats & non-achats", total: 0});
        // setFiltersCategory([]);
        // setFiltersBrand([]);
        // setFiltersConvCategory([]);
        // setFiltersConvBrand([]);
        // setFiltersWhy([]);
      } catch {
        setFiltersAge([]);
        setFiltersSocio([]);
        setFiltersFrom([]);
        setFiltersFreq([]);
        setFiltersVip([]);
        setFiltersRecc([]);
        setPeriodDf(new dfd.DataFrame([]));
        setCurrent({conv: "", why: "", label: "Achats & non-achats", total: 0});
        // setFiltersCategory([]);
        // setFiltersBrand([]);
        // setFiltersConvCategory([]);
        // setFiltersConvBrand([]);
        // setFiltersWhy([]);
      }
    }
  }, [dashboardContext.fullDF, period, filtersStore]);

  useEffect(() => {
    if (periodDf?.axis?.columns?.length > 0) {
      setFilteredDf(dashboardContext.getFilteredDf(periodDf, [
        {column: "profile/socio", filters: filtersSocio.map((i) => i.value)},
        {column: "profile/age", filters: filtersAge.map((i) => i.value)},
        {column: "profile/from", filters: filtersFrom.map((i) => i.value)},
        {column: "profile/frequence", filters: filtersFreq.map((i) => i.value)},
        {column: "profile/vip", filters: filtersVip.map((i) => i.value)},
        {column: "journey/reccuring", filters: filtersRecc.map((i) => i.value)},
      ]));
    } else {
      setFilteredDf(new dfd.DataFrame([]));
    }
  }, [periodDf, filtersSocio, filtersAge, filtersFrom, filtersFreq, filtersVip, filtersRecc]);

  useEffect(() => {
    if (filteredDf?.axis?.columns?.length > 0) {
      setPurchaseDf(dashboardContext.getFilteredDf(filteredDf, [
        {column: "purchase/nb", filters: [0], notEqual: true},
      ]));
      setPurchaseNotDf(dashboardContext.getFilteredDf(filteredDf, [
        {column: "purchase/nb", filters: [0]},
      ]));
      // setPurchaseData(dashboardContext.getNivoItems(filteredDf, "purchase/nb"));
      // console.log(dashboardContext.getNivoItems(filteredDf, "purchase/nb"));
    } else {
      setPurchaseDf(new dfd.DataFrame([]));
      setPurchaseNotDf(new dfd.DataFrame([]));
    }
  }, [filteredDf]);

  useEffect(() => {
    if (filteredDf?.axis?.columns?.length > 0 && purchaseDf?.axis?.columns?.length > 0 && purchaseNotDf?.axis?.columns?.length > 0) {
      setPurchaseData([
        {id: "purchaseNot", label: "Non-achats", value: purchaseNotDf?.axis?.index?.length || 0, total: filteredDf?.axis?.index?.length || 0,
          percent: Math.floor((purchaseNotDf?.axis?.index?.length || 0) / (filteredDf?.axis?.index?.length || 0) * 1000)/10
        },
        {id: "purchase", label: "Achats", value: purchaseDf?.axis?.index?.length || 0, total: filteredDf?.axis?.index?.length || 0,
          percent: Math.floor((purchaseDf?.axis?.index?.length || 0) / (filteredDf?.axis?.index?.length || 0) * 1000)/10
        },
      ]);
      setDataWhy(dashboardContext.getNivoItems(purchaseDf, "purchase/why"));
      setDataWhyNot(dashboardContext.getNivoItems(purchaseNotDf, "purchase/whynot"));
    }
  }, [filteredDf, purchaseDf, purchaseNotDf])
  
  // useEffect(() => {
  //   if (filteredDf?.axis?.columns?.length > 0) {
  //     const why = dashboardContext.getNivoItems(purchaseDf, "purchase/why");
  //     const whyNot = dashboardContext.getNivoItems(purchaseNotDf, "purchase/whynot");
  //     // console.log(why, whyNot);
  //     const fullFactor = 100;
  //     const min = 10;
  //     const total = filteredDf?.axis?.index?.length || 0;
  //     const purchaseNb = purchaseDf?.axis?.index?.length || 0;
  //     const purchaseNotNb = purchaseNotDf?.axis?.index?.length || 0;
  //     const factorPurchase = fullFactor / (total / purchaseDf?.axis?.index?.length) || 0;
  //     const factorPurchaseNot = fullFactor / (total / purchaseNotDf?.axis?.index?.length) || 0;
  //     const netData = {
  //       nodes: [
  //         {id: "visits", label: "Visites", value: total, total: total, height: 0, size: min+fullFactor, color: '#1f6db1'},
  //         {id: "purchase", label: "Achats", value: purchaseNb, total: total, height: 1, size: min+factorPurchase, color: '#68be71'},
  //         {id: "purchaseNot", label: "Non achats", value: purchaseNotNb, total: total, height: 1, size: min+factorPurchaseNot, color: '#f75e44'},
  //       ],
  //       links: [
  //         {source: "visits", target: "purchase", distance: 180},
  //         {source: "visits", target: "purchaseNot", distance: 180},
  //       ],
  //       annotations: [
  //         {match: {id: "visits"}, type: "circle", note: total + " visites totales", noteX: 0, noteY: -88, offset: 2},
  //         {match: {id: "purchase"}, type: "circle", note: purchaseNb + " achats et leurs raisons", noteX: 11, noteY: 135, noteTextOffset: -16, offset: 88},
  //         {match: {id: "purchaseNot"}, type: "circle", note: purchaseNotNb + " non achats et leurs raisons", noteX: -11, noteY: 135, noteTextOffset: -16, offset: 88},
  //       ]
  //     };
  //     why.forEach((w) => {
  //       netData.nodes.push({id: "purchase/"+w.id, label: w.label, value: w.value, total: w.total, height: 2, size: min+((fullFactor / (w.total/w.value)) || 0), color: '#abdda5'});
  //       netData.links.push({source: "purchase", target: "purchase/"+w.id, distance: 75});
  //       // netData.annotations.push({match: {id: "purchase/"+w.id}, type: "circle", note: w.label, noteX: 0, noteY: 0, offset: 0})
  //     });
  //     whyNot.forEach((w) => {
  //       netData.nodes.push({id: "purchaseNot/"+w.id, label: w.label, value: w.value, total: w.total, height: 2, size: min+((fullFactor / (w.total/w.value)) || 0), color: '#fc9d7f'});
  //       netData.links.push({source: "purchaseNot", target: "purchaseNot/"+w.id, distance: 75});
  //     });
  //     setNetworkData(netData as NivoNetworkDataInterface);
  //     // setCurrent({conv: "", why: "", label: "Visites", total: filteredDf?.axis?.index?.length || 0})
  //   } else {
  //     setNetworkData({nodes: [], links: [], annotations: []} as NivoNetworkDataInterface);
  //   }
  // }, [filteredDf, purchaseDf, purchaseNotDf])

  useEffect(() => {
    if (current.conv === "purchase" && purchaseDf?.axis?.columns?.length > 0) {
      const df = current.why !== "" ? dashboardContext.getFilteredDf(purchaseDf, [
        {column: "purchase/why", filters: [current.why]}
      ]) : purchaseDf;
      setDetailsData(current.why === "[lc]other" ? dashboardContext.getNivoItems(df, "purchase/whyfree") : []);
      setDetailsFreeData([]);
      setDataSocio(dashboardContext.getNivoItems(df, "profile/socio").sort((a, b) => a.label.localeCompare(b.label)));
      setDataAge(dashboardContext.getNivoItems(df, "profile/age").sort((a, b) => a.label.localeCompare(b.label)));
      setDataFrom(dashboardContext.getNivoItems(df, "profile/from"));
      setDataFrequence(dashboardContext.getNivoItems(df, "profile/frequence"));
      setDataVip(dashboardContext.getNivoItems(df, "profile/vip"));
      setDataReccuring(dashboardContext.getNivoItems(df, "journey/reccuring"));
    } else if (current.conv === "purchaseNot" && purchaseNotDf?.axis?.columns?.length > 0) {
      const df = current.why !== "" ? dashboardContext.getFilteredDf(purchaseNotDf, [
        {column: "purchase/whynot", filters: [current.why]}
      ]) : purchaseNotDf;
      switch(current.why) {
        case "[lc]other":
          setDetailsData(dashboardContext.getNivoItems(df, "purchase/whynotfree").reverse());
          setDetailsFreeData([]);
          break;
        case "category": 
          setDetailsData(dashboardContext.getNivoItems(df, "purchase/missingcategory").filter((i) => i.id !== "[lc]other").map((i) => {return {...i, label: categories.find((c) => c.value === i.id)?.label || ""}}).reverse());
          setDetailsFreeData(dashboardContext.getNivoItems(df, "purchase/missingcategoryfree").filter((i) => i.id !== "[lc]null").reverse());
          break;
        case "brand": 
          setDetailsData(dashboardContext.getNivoItems(df, "purchase/missingbrand").filter((i) => i.id !== "[lc]other").map((i) => {return {...i, label: brands.find((b) => b.value === i.id)?.label || ""}}).reverse());
          setDetailsFreeData(dashboardContext.getNivoItems(df, "purchase/missingbrandfree").filter((i) => i.id !== "[lc]null").reverse());
          break;
        case "product":
          setDetailsData(dashboardContext.getNivoItems(df, "purchase/missingproduct").map((i) => {
            const [category, brand] = i.id.toString().split("/lc/");
            return {...i, label: (categories.find((c) => c.value === category)?.label || "") + " : " + (brands.find((b) => b.value === brand)?.label || "")}
          }).reverse());
          setDetailsFreeData([]);
          break;
        case "size":
          setDetailsData(dashboardContext.getNivoItems(df, "purchase/missingsize").reverse());
          setDetailsFreeData([]);
          break;
        default:
          setDetailsData([]);
          setDetailsFreeData([]);
      }
      setDataSocio(dashboardContext.getNivoItems(df, "profile/socio").sort((a, b) => a.label.localeCompare(b.label)));
      setDataAge(dashboardContext.getNivoItems(df, "profile/age").sort((a, b) => a.label.localeCompare(b.label)));
      setDataFrom(dashboardContext.getNivoItems(df, "profile/from"));
      setDataFrequence(dashboardContext.getNivoItems(df, "profile/frequence"));
      setDataVip(dashboardContext.getNivoItems(df, "profile/vip"));
      setDataReccuring(dashboardContext.getNivoItems(df, "journey/reccuring"));
    } else if (filteredDf?.axis?.columns?.length > 0) {
      setDetailsData([]);
      setDetailsFreeData([]);
      setDataSocio(dashboardContext.getNivoItems(filteredDf, "profile/socio").sort((a, b) => a.label.localeCompare(b.label)));
      setDataAge(dashboardContext.getNivoItems(filteredDf, "profile/age").sort((a, b) => a.label.localeCompare(b.label)));
      setDataFrom(dashboardContext.getNivoItems(filteredDf, "profile/from"));
      setDataFrequence(dashboardContext.getNivoItems(filteredDf, "profile/frequence"));
      setDataVip(dashboardContext.getNivoItems(filteredDf, "profile/vip"));
      setDataReccuring(dashboardContext.getNivoItems(filteredDf, "journey/reccuring"));
    } else {
      setDetailsData([]);
      setDetailsFreeData([]);
      setDataSocio([]);
      setDataAge([]);
      setDataFrom([]);
      setDataFrequence([]);
      setDataVip([]);
      setDataReccuring([]);
    }
  }, [filteredDf, purchaseDf, purchaseNotDf, current]);

  useEffect(() => {
    if (current.conv && !current.why) {profileRef?.current?.scrollIntoView({behavior: "smooth", block: "start"})}
    else if (current.conv && current.why) {reasonRef?.current?.scrollIntoView({behavior: "smooth", block: "end"})}
  }, [current]);

	return (<>
		<PeriodToolBar
			period={period}
			setPeriod={setPeriod}
		/>
    <ToolBar
      title={i18n.t("shared.filters")}
    ><>
      <MultiSelect
        id="filterStore"
        title="Boutiques"
        placeholder="Toutes"
        maxGrow="100%"
        options={optionsStore}
        // selected={dashboardContext.currentCSIDs as ReactSelectInterface[]}
        // setSelected={(s) => dashboardContext.setCurrentCSIDs(s)}
        selected={filtersStore as ReactSelectInterface[]}
        // setSelected={(s) => setFiltersStore(s)}
        setSelected={(s) => {
          setFiltersStore(s);
          !props.dual && dashboardContext.setCurrentCSIDs(s);
        }}
      />
      <MultiSelect
        id="filterSocio"
        title="Sexes"
        placeholder="Tous"
        maxGrow="33%"
        options={optionsSocio}
        selected={filtersSocio as ReactSelectInterface[]}
        setSelected={(s) => setFiltersSocio(s)}
      />
      <MultiSelect
        id="filterAge"
        title="Ages"
        placeholder="Tous"
        maxGrow="33%"
        options={optionsAge}
        selected={filtersAge as ReactSelectInterface[]}
        setSelected={(s) => setFiltersAge(s)}
      />
      <MultiSelect
        id="filterFrom"
        title="Pays"
        placeholder="Tous"
        maxGrow="33%"
        options={optionsFrom}
        selected={filtersFrom as ReactSelectInterface[]}
        setSelected={(s) => setFiltersFrom(s)}
      />
      <MultiSelect
        id="filterFreq"
        title="Fréquentations"
        placeholder="Toutes"
        maxGrow="33%"
        options={optionsFreq}
        selected={filtersFreq as ReactSelectInterface[]}
        setSelected={(s) => setFiltersFreq(s)}
      />
      <MultiSelect
        id="filterVip"
        title="Membres Printania"
        placeholder="Tous"
        maxGrow="33%"
        options={optionsVip}
        selected={filtersVip as ReactSelectInterface[]}
        setSelected={(s) => setFiltersVip(s)}
      />
      <MultiSelect
        id="filterRecc"
        title="Clients récurrents"
        placeholder="Tous"
        maxGrow="33%"
        options={optionsRecc}
        selected={filtersRecc as ReactSelectInterface[]}
        setSelected={(s) => setFiltersRecc(s)}
      />
    </></ToolBar>
    <section className="lc-section" style={{minHeight: "unset"}}>
			<h4 style={{width: "100%", textAlign: "center"}}>
        {"Visites (" + (filteredDf?.axis?.index?.length || 0) + ")"}
			</h4>
      <SinglePie
        title={"Conversion"}
        data={purchaseData}
        flex="1 0 100%"
        colors={['#f75e44','#68be71']}
        onClick={(item) =>  setCurrent((item.value !== current.conv || !!current.why)
          ? {conv: item.value, why: "", label: item.value === "purchase" ? "Achats" : "Non-achats", total: item.value === "purchase" ? purchaseDf?.axis?.index?.length || 0 : purchaseNotDf?.axis?.index?.length || 0}
          : {conv: "", why: "", label: "Achats & non-achats", total: filteredDf?.axis?.index?.length || 0})}
      />
      <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
        <ArrowDropUpRoundedIcon />
        <small style={{minWidth: "200px", padding: "0px 8px", textAlign: "center"}}>Cliquez sur une population</small>
        <ArrowDropUpRoundedIcon />
      </div>
      <hr style={{minWidth: "280px", width: "88%"}} />
      <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
        <ArrowDropDownRoundedIcon />
        <small style={{minWidth: "200px", padding: "0px 8px", textAlign: "center"}}>pour l'inspecter</small>
        <ArrowDropDownRoundedIcon />
      </div>
      <SinglePie
        title={"Raisons d'achat"}
        data={dataWhy}
        flex="1 0 50%"
        colors={[...continuousGreens]}
        onClick={(item) => setCurrent((item.value !== current.why || current.conv !== "purchase")
          ? {conv: "purchase", why: item.value, label: "Achats X " + item.label, total: dataWhy.find((d) => d.id === item.value)?.value || 0}
          : {conv: "", why: "", label: "Achats & non-achats", total: filteredDf?.axis?.index?.length || 0}
        )}
        endAngle={-360}
      />
      <SinglePie
        title={"Raisons de non-achat"}
        data={dataWhyNot}
        flex="1 0 50%"
        colors={[...continuousReds]}
        onClick={(item) => setCurrent((item.value !== current.why || current.conv !== "purchaseNot")
          ? {conv: "purchaseNot", why: item.value, label: "Non-achats X " + item.label, total: dataWhyNot.find((d) => d.id === item.value)?.value || 0}
          : {conv: "", why: "", label: "Achats & non-achats", total: filteredDf?.axis?.index?.length || 0}
        )}
      />
      {/* <div style={{boxSizing: "border-box", width: "100%", minWidth: "280px", padding: "8px", display: "flex", flexDirection: "column", alignItems: "center"}}> 
        <div style={{position: "relative", width: "100%", height: 0, zIndex: 44, display: "flex", justifyContent: "space-between", alignItems: "start"}}>
          <small>Cliquez sur une node pour inspecter un groupe spécifique</small>
          <Widget
            minHeight="unset"
            minWidth="unset"
            flex="unset"
            onClick={() => setShowAnnotation((oldValue) => !oldValue)}
          ><div style={{display: "flex", alignItems: "center"}}>
            <small style={{padding: "4px 8px 4px 4px"}}>Annotations</small>
            {!showAnnotation ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}
          </div></Widget>
        </div>
        <div className="lc-graph-network interactive" style={{boxSizing: "border-box", minWidth: "280px", width: "100%", height: "440px"}}>
          <ResponsiveNetwork
            data={networkData}
            onClick={(node) => {
              const [conv, why] = node.id.split("/");
              const prefix = why
                ? conv === "purchase"
                  ? "Raison d'achat : "
                  : "Raison de non achat : "
                : "";
              // conv === "purchase" ? "Raison d'achat : " : conv === "purchaseNot" ? "Raison de non achat : " : "";
              setCurrent((current.conv !== conv || current.why !== why)
                ? {conv: conv || "", why: why || "", label: prefix+node.data.label, total: node.data.value}
                : {conv: "", why: "", label: "", total: filteredDf?.axis?.index?.length || 0});
            }}
            onMouseEnter={showAnnotation ? () => setShowAnnotation(false) : undefined}
            annotations={showAnnotation ? networkData.annotations : []}
            centeringStrength={1}
            repulsivity={100}
            nodeSize={(node) => node.size || 10}
            nodeColor={(node) => node.color || "#888888"}
            nodeBorderColor={labelDark}
            nodeBorderWidth={1}
            activeNodeSize={(node) => node.size || 10}
            inactiveNodeSize={(node) => node.size/2 || 5}
            linkColor={(e) => e.target.color || labelDark}
            linkDistance={(e) => e.distance || 150}
            linkThickness={(e) => e.target.size/5 || 1}
            distanceMin={10}
            nodeTooltip={(item) => <p style={{
              padding: 8, borderRadius: 8, backgroundColor: "var(--light)",
              boxShadow: "1px 1px 2px var(--dark88)"
            }}>{item?.node?.data?.label + " : " + (item?.node?.data?.value === item?.node?.data?.total
              ? item?.node?.data?.value
              : item?.node?.data?.value + "/" + item?.node?.data?.total
            )}</p>}
          />
        </div>
      </div> */}
    </section>
    <section ref={reasonRef} className="lc-section" style={{minHeight: "300px", maxHeight: "300px", overflowY: "auto"}}>
      {(detailsData.length > 0 || detailsFreeData.length > 0) ? <>
        <h4 style={{width: "100%", textAlign: "center"}}>
          {"Détail" + " (" + (current.total || filteredDf?.axis?.index?.length || 0) + " " + current.label + ")"}
        </h4>
        <SimpleBar
          data={detailsData}
          flex="1 0 90%"
        />
      </> : <h4 style={{width: "100%", textAlign: "center"}}>
        {"Aucun détail pour cette population"}
      </h4>}
      {(detailsFreeData.length > 0) && <>
        <h5 style={{width: "100%", textAlign: "center"}}>
          {"Autres"}
        </h5>
        <SimpleBar
          data={detailsFreeData}
          flex="1 0 90%"
        />
      </>}
    </section>
    <section ref={profileRef} className="lc-section" style={{minHeight: "unset"}}>
			<h4 style={{width: "100%", textAlign: "center"}}>
        {"Profils clients" + " (" + (current.total || filteredDf?.axis?.index?.length || 0) + " " + current.label + ")"}
			</h4>
      <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <button className="lc-buttonicon"
          style={{padding: 8}}
          disabled={!current.conv}
          onClick={() => setCurrent({conv: "", why: "", label: "Achats & non-achats", total: filteredDf?.axis?.index?.length || 0})}
        >Revenir à la population générale</button>
      </div>
      <SinglePie
        title={"Sexes"}
        data={dataSocio}
        flex="1 0 350px"
        onClick={(item) => setFiltersSocio(updateFilters(item, filtersSocio))}
      />
      <SinglePie
        title={"Ages"}
        data={dataAge}
        flex="1 0 350px"
        onClick={(item) => setFiltersAge(updateFilters(item, filtersAge))}
      />
      <SinglePie
        title={"Pays"}
        data={dataFrom}
        flex="1 0 350px"
        onClick={(item) => setFiltersFrom(updateFilters(item, filtersFrom))}
      />
      <SinglePie
        title={"Fréquentation"}
        data={dataFrequence}
        flex="1 0 350px"
        onClick={(item) => setFiltersFreq(updateFilters(item, filtersFreq))}
      />
      <SinglePie
        title={"Membres Printania"}
        data={dataVip}
        flex="1 0 350px"
        onClick={(item) => setFiltersVip(updateFilters(item, filtersVip))}
      />
      <SinglePie
        title={"Clients réccurents"}
        data={dataReccuring}
        flex="1 0 350px"
        onClick={(item) => setFiltersRecc(updateFilters(item, filtersRecc))}
      />
    </section>
	</>)
}

const ReasonsView : ViewInterface = {
  AVID: "reasons",
  component: (props?: {dual?: boolean}) => <Reasons dual={!!props?.dual} />,
  url: "/reasons",
  icon: <AccountCircleIcon />
}

export default ReasonsView;