// React
import React, { useState } from 'react';
// MUI
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// Interfaces
import { ViewInterface } from '../../../interfaces';
// Contexts
// import { useI18nContext } from '../../../contexts/i18n';
// import { useUserContext } from '../../../contexts/user';
// AppConfig
// import appConfig from '../appConfig';
// Components
// import Widget from '../../../components/Widget';

const Brands = () => {
	// const i18n = useI18nContext();
	// const userContext = useUserContext();
	// const [name, setName] = useState("");
	// const [creaCode, setCreaCode] = useState("");
	// const [message, setMessage] = useState("");
	// const [isDisabled, setIsDisabled] = useState(false);

	// const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
	// 	e.preventDefault();
	// 	setIsDisabled(true);
	// 	userContext.Brands(name, creaCode, (errorCode) => {
	// 		setMessage(i18n.t(errorCode));
	// 		setIsDisabled(false);
	// 	})
	// };

	return (<>
    Brands
		{/* <Widget
      title={i18n.t(appConfig.AID + ".views." + BrandsView.AVID + ".name")}
			isFocused={true}
		>
			<form
				onSubmit={handleSubmit}
			>
        <label htmlFor="name">{i18n.t("shared.companyDict.name", {case: "first"})}</label>
        <input
          id="name"
          type="text"
          value={name}
					onChange={(e) => setName(e.target.value)}
          disabled={isDisabled}
        />
				<label htmlFor="creaCode">{i18n.t("shared.companyDict.creaCode", {case: "first"})}</label>
        <input
          id="creaCode"
          type="text"
          value={creaCode}
					onChange={(e) => setCreaCode(e.target.value)}
          disabled={isDisabled}
        />
				<input
					type="submit"
					value={i18n.t("shared.crudDict.update", {case: "first"})}
					disabled={isDisabled}
				/>
				<small style={{textAlign: "center"}}>{message}</small>
			</form>
		</Widget> */}
	</>)
}

const BrandsView : ViewInterface = {
  AVID: "brands",
  component: (props?: {dual?: boolean}) => <Brands />,
  url: "/brands",
  icon: <AccountCircleIcon />,
}

export default BrandsView;