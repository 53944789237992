// React
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// MUI
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// Interfaces
import { ViewInterface } from '../../../interfaces';
// Contexts
import { useI18nContext } from '../../../contexts/i18n';
import { useUserContext } from '../../../contexts/user';
// AppConfig
import appConfig from '../appConfig';
// Components
import Widget from '../../../components/Widget';

const CreateCompany = () => {
	const i18n = useI18nContext();
	const userContext = useUserContext();
	const navigate = useNavigate();
	const [name, setName] = useState("");
	const [creaCode, setCreaCode] = useState("");
	const [message, setMessage] = useState("");
	const [isDisabled, setIsDisabled] = useState(false);

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsDisabled(true);
		userContext.createCompany(
			name,
			creaCode,
			() => navigate("/"),
			(errorCode) => {setMessage(i18n.t(errorCode)); setIsDisabled(false)}
		)
	};

	return (<>
		<Widget
      title={i18n.t(appConfig.AID + ".views." + CreateCompanyView.AVID + ".name")}
			isFocused={true}
			width="100%"
		>
			<form
				onSubmit={handleSubmit}
			>
        <label htmlFor="name">{i18n.t("shared.companyDict.name", {case: "first"})}</label>
        <input
          id="name"
          type="text"
          value={name}
					onChange={(e) => setName(e.target.value)}
          disabled={isDisabled}
        />
				<label htmlFor="creaCode">{i18n.t("shared.companyDict.creaCode", {case: "first"})}</label>
        <input
          id="creaCode"
          type="text"
          value={creaCode}
					onChange={(e) => setCreaCode(e.target.value)}
          disabled={isDisabled}
        />
				<input
					type="submit"
					aria-label={i18n.t("shared.crudDict.create", {case: "first"})}
					value={i18n.t("shared.crudDict.create", {case: "first"})}
					disabled={isDisabled}
				/>
				<small style={{textAlign: "center"}}>{message}</small>
			</form>
		</Widget>
	</>)
}

const CreateCompanyView : ViewInterface = {
  AVID: "createCompany",
  component: (props?: {dual?: boolean}) => <CreateCompany />,
  url: "/createCompany",
  icon: <AccountCircleIcon />,
  hidden: true,
}

export default CreateCompanyView;