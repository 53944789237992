// React
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
// Contexts
// import { useUserContext } from '../contexts/user';
import { useAppsContext } from '../../contexts/apps';
// import Loading from '../views/Loading';
import Lost from "../../views/Lost";
import CookiesView from '../../views/Cookies';
import TosView from '../../views/ToS';
import PPView from '../../views/PP';
import DPAView from '../../views/DPA';

export default function AppsRoutes() {
  // const userContext = useUserContext();
  const appsContext = useAppsContext();
  // const apps = appsContext.getApps();

  // useEffect(() => {
  //   console.log("Apps Routes update");
  // })

	return (
    <Routes>
      <Route key={"route-"+CookiesView.AVID} path={CookiesView.url} element={CookiesView.component()} />
      <Route key={"route-"+TosView.AVID} path={TosView.url} element={TosView.component()} />
      <Route key={"route-"+PPView.AVID} path={PPView.url} element={PPView.component()} />
      <Route key={"route-"+DPAView.AVID} path={DPAView.url} element={DPAView.component()} />
      {appsContext.getApps().map((app) => app.views.map((view) => 
        <Route key={"route-"+app.AID+"-"+view?.AVID} path={app.url + view.url} element={app.component(view)} />
      ))}
      {appsContext.getApps().map((app) =>
        <Route key={"route-"+app.AID} path={app.url} element={app.component()} />
      )}
      <Route key="landing" path="" element={(appsContext.apps.length > 0) ? appsContext.apps[0].component() : <Lost />} />
      <Route key="default" path="*" element={(appsContext.apps.length > 0) ? appsContext.apps[0].component() : <Lost />} />
    </Routes>
	)
	// return (
  //   <Routes>
  //     {appsContext.apps.map((app) => app.views.map((view) =>
  //       <Route key={"route-"+app.AID+"-"+view?.AVID} path={app.url + view?.url} element={app.component(view)} />
  //     ))}
  //     {appsContext.apps.map((app) =>
  //       <Route key={"route-"+app.AID} path={app.url} element={app.component()} />
  //     )}
  //     <Route key="landing" path="" element={(appsContext.apps.length > 0) ? appsContext.apps[0].component() : <Lost />} />
  //     <Route key="default" path="*" element={(appsContext.apps.length > 0) ? appsContext.apps[0].component() : <Lost />} />
  //   </Routes>
	// )
}