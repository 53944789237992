import { onDocUpdate, getColRef, getDocs, getDocRef, getDoc, setDoc, doc, query, newBatch } from "../../firestore";
import { StoreInterface, storesColPath, storeDocPath, CID, CSID } from "livecrew-interfaces";

const listen = (CID: CID, CSID: CSID, onChange: (store: StoreInterface) => void) => onDocUpdate(
  getDocRef(storeDocPath(CID, CSID)),
  (docSnap) => onChange({...docSnap.data(), CSID: docSnap.id} as StoreInterface),
  (error) => onChange({} as StoreInterface),
);

const listenAll = (CID: CID, onChange: (stores: StoreInterface[]) => void) => onDocUpdate(
  query(getColRef(storesColPath(CID))),
  (querySnap) => {
    const stores = [] as StoreInterface[];
    querySnap.forEach((doc) => stores.push({...doc.data(), CSID: doc.id} as StoreInterface));
    onChange(stores);
  },
  (error) => onChange([]),
);

const read = (CID: CID, CSID: CSID, onSuccess: (store: StoreInterface) => void, onError: (errorCode: string) => void) => getDoc(getDocRef(storeDocPath(CID, CSID)))
  .then((docSnap) => {
    if (docSnap.exists()) {
      onSuccess({...docSnap.data(), CSID: docSnap.id} as StoreInterface);
    } else {
      onError("db.doc.01");
    }
  })
  .catch((error) => {
    console.log(error);
    onError(error.code);
  });

const readAll = (CID: CID, onSuccess: (stores: StoreInterface[]) => void, onError: (errorCode: string) => void) => getDocs(getColRef(storesColPath(CID)))
  .then((docsSnap) => {
    const stores = [] as StoreInterface[];
    docsSnap.forEach((doc) => stores.push({...doc.data(), CSID: doc.id} as StoreInterface))
    onSuccess(stores);
  })
  .catch((error) => {
    console.log(error);
    onError(error.code);
  });

const create = (CID: CID, store: StoreInterface, onSuccess: (CSID: CSID) => void, onError: (errorCode: string) => void) => {
  const newStore = doc(getColRef(storesColPath(CID)));
  setDoc(newStore, {...store, CSID: newStore.id})
      .then(() => onSuccess(newStore.id))
      .catch((error) => {
        console.log(error);
        onError(error.code);
      });
};

const update = (CID: CID, stores: StoreInterface[], onSuccess: () => void, onError: (errorCode: string) => void) => {
  if (stores.length > 0) {
    const batch = newBatch();
    stores.forEach((store) => {
      const storeDoc = store.CSID.includes("[lc]") ? doc(getColRef(storesColPath(CID))) : getDocRef(storeDocPath(CID, store.CSID));
      batch.set(storeDoc, {...store, CSID: storeDoc.id});
    });
    batch.commit()
      .then(() => onSuccess())
      .catch((error) => onError(error.code));
  } else {
    onError("No store to update");
  }
};

const stores = {
  listen,
  listenAll,
  read,
  readAll,
  create,
  update,
};

export default stores;