import firebaseApp from "../firebase";
import { getFirestore, connectFirestoreEmulator, serverTimestamp, collection, getDocs, doc, getDoc, addDoc, setDoc, onSnapshot, query, where, writeBatch } from "firebase/firestore";
import { CollectionReference, DocumentReference } from "@firebase/firestore";

const firestore = getFirestore(firebaseApp);
// if (window.location.hostname === "localhost") connectFirestoreEmulator(firestore, "localhost", 8080);

const getColRef = (path: string[]) => {
  if (path.length === 0 || path.length % 2 === 0) {
    return {} as CollectionReference;
  } else {
    return collection(firestore, "/", ...path);
  }
};

const getDocRef = (path: string[]) => {
  if (path.length === 0 || path.length % 2 !== 0) {
    return {} as DocumentReference;
  } else {
    return doc(firestore, "/", ...path);
  }
};

const onDocUpdate = onSnapshot;

const newBatch = () => writeBatch(firestore);

// const readDocs = (query) => getDocs(query)

// const readDoc = (docRef: DocumentReference) => getDoc(docRef);

export {
  onDocUpdate,
  serverTimestamp,
  getColRef,
  getDocs,
  getDocRef,
  getDoc,
  addDoc,
  setDoc,
  doc,
  query,
  where,
  newBatch,
}