// MUI
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';

const appConfig = {
  AID: "user",
  version: "0.1.0",
  url: "/user",
  color: "#00000000",
  colorDark: "#00000000",
  icon: <AccountBoxRoundedIcon />,
};

export default appConfig;